export default {
    "de_DE": {
        " confirmed the performance for the artist": "Alle best\u00e4tigten Veranstaltungen dieses K\u00fcnstlers auflisten.",
        " confirmed the performance for the venue": "Alle best\u00e4tigten Veranstaltungen dieses Veranstaltungsortes auflisten.",
        "%(attr_name)s is not a valid attribute of %(obj)s": "%(attr_name)s ist kein g\u00fcltiges Attribut von %(obj)s",
        "%(enum_value)s is not an accepted value.": "%(enum_value)s ist kein akzeptierter Wert.",
        "%(profile_name)s Schedule": "%(profile_name)s Zeitplan",
        "A cappella / Barbershop": "Acapella",
        "A password was expected, but none was provided.": "Es wurde ein Passwort erwartet, aber keins angegeben.",
        "Accent Color": "Akzent-Farbe",
        "Accept": "Annehmen",
        "Accept Performance": "Performance annehmen",
        "Accept this event on behalf of a venue.": "Annehmen der Veranstaltung im Namen eines Veranstaltungsorts",
        "Accept this event on behalf of an artist.": "Annehmen der Veranstaltung im Namen des Musikacts",
        "Accept this event.": "Veranstaltung annehmen",
        "Accept, Decline or Change Time of this performance": "Performance annehmen, ablehnen oder die Zeit \u00e4ndern",
        "Accepting Requests": "Annehmen von Anfragen",
        "Accepting Requests for Performances": "Annehmen von Performance-Anfragen",
        "Accepting Requests from Performers": "Annehmen von Musikact-Anfragen",
        "Accepting Requests from Venues": "Annehmen von Musikact-Anfragen",
        "Account": "Account",
        "Account Deleted Successfully": "Account erfolgreich gel\u00f6scht",
        "Account Settings": "Account-Einstellungen",
        "Active as:": "Aktiv als",
        "Add": "Hinzuf\u00fcgen",
        "Add Another Performer": "Einen weiteren Musikact hinzuf\u00fcgen",
        "Add a SoundCloud Song URL to this artist.": "Eine SoundCloud Song-URL zu diesem Musicact hinzuf\u00fcgen.",
        "Add a YouTube Video URL to this artist.": "Hinzf\u00fcgen einer YouTube Video-URL zu diesem Musikact",
        "Add a genre to this city.": "Ein Genre zu dieser Stadt hinzuf\u00fcgen.",
        "Add a venue type to this city.": "Dieser Stadt einen Veranstaltungsort hinzuf\u00fcgen.",
        "Add an event in this city.": "Eine Veranstaltung in dieser Stadt hinzuf\u00fcgen.",
        "Add districts to this city.": "F\u00fcgen Sie Stadtteile zu dieser Stadt hinzu.",
        "Add neighbourhoods to this city.": "F\u00fcgen Sie dieser Stadt Stadtteile hinzu.",
        "Add neighbourhoods to this district.": "F\u00fcgen Sie Nachbarschaften zu diesem Stadtviertel hinzu.",
        "Add template to this city.": "Hinzuf\u00fcgen einer Vorlage zu dieser Stadt.",
        "Add to Event": "Zur Veranstaltung hinzuf\u00fcgen",
        "Adding your web profiles is strongly recommended! These links will be displayed on your public profile and performance listings.": "Das Hinzuf\u00fcgen von Web-Profilen wird dringend empfohlen! Diese Links werden im \u00f6ffentlichen Profil und bei der Listung der Performance angezeigt",
        "Address": "Adresse",
        "Afrobeat": "Afrobeat",
        "After going public, you will no longer be able to change your site's neighborhood names, venue types, genres, or time increments.<br/><br/>Are you sure you want to go public now?": "Nach dem Livegang k\u00f6nnen Sie die Stadtteilnamen, Veranstaltungsorttypen, Genres oder Zeitabstufungen Ihrer Website nicht mehr \u00e4ndern. <br/><br/> Sind Sie sicher, dass Sie jetzt live gehen wollen?",
        "Alert": "Warnung",
        "All": "Alle",
        "All Conversations": "Alle Unterhaltungen",
        "Almost there, now select the performance time": "Zieh an dem Schieberegler, um die Performancezeit auszuw\u00e4hlen",
        "Almost there, now select your performance time.": "Zieh den Schieberegler, um die Performancezeit auszuw\u00e4hlen.",
        "Almost there, now tell us about your performer(s)": "Erz\u00e4hle uns etwas \u00fcber dein(e) Musikact(s).",
        "Already have a performance planned and not interested in additional matches? This option is for you.": "Du ben\u00f6tigst keine Hilfe bei der Zusammenstellung deines Programms und willst einfach nur deine Veranstaltung f\u00fcr den 21. Juni listen? Dann hier entlang!",
        "Alternative Images": "Alternative Bilder",
        "Americana / Bluegrass": "Americana / Bluegrass",
        "Amphitheater or Bandstand": "Amphitheater oder Musikpavillon",
        "An API Exception occurred": "Eine API-Ausnahme ist aufgetreten",
        "An OAuth2 related error has occurred.": "Ein OAuth2-bezogener Fehler ist aufgetreten.",
        "An account with this email address already exists. Click <a href=\"/account/login\">here</a> to login": "Ein Account mit dieser E-Mail-Adresse existiert bereits. <a href=\"/account/login\">Hier</a> klicken f\u00fcr die Anmeldung. ",
        "An error has occurred!": "Es ist ein Fehler aufgetreten!",
        "An internal error prevented the request from being handled.": "Ein interner Fehler verhinderte die Bearbeitung der Anfrage.",
        "An unhandled exception has occurred!": "Eine unbehandelte Ausnahme ist aufgetreten!",
        "App config is missing \"BASE_HOSTNAME\"": "App-Konfiguration fehlt \"BASE_HOSTNAME\".",
        "Approve": "Genehmigen",
        "Approve Performance": "Performance genehmigen",
        "Approve this event.": "Event genehmigen",
        "Are you a venue or a performer?": "Bist du ein Veranstaltungsort oder ein Musicact?",
        "Are you flexible about timing? How long a set could the performer play?": "Bist du zeitlich flexibel? Wie lange k\u00f6nntest du ein Set spielen?",
        "Are you flexible about what time you can perform here? How long is your set?": "Bist du flexibel in Bezug auf die Zeit, in der du bei uns auftreten kannst? Wie lang ist das Set?",
        "Are you looking for matches, or just want to list your event?": "M\u00f6chtest du unser Matchmaking-Tool nutzen, um einen Veranstaltungsort bzw. Musiker*innen f\u00fcr den 21. Juni zu finden oder m\u00f6chtest du direkt eine Veranstaltung eintragen? ",
        "Are you sure you want to add this new venue type? This action cannot be undone.": "Bist du sicher, dass du diesen neuen Veranstaltungsorttyp hinzuf\u00fcgen m\u00f6chtest? Diese Aktion kann nicht r\u00fcckg\u00e4ngig gemacht werden.",
        "Are you sure?": "Bist du sicher?",
        "Artist": "Musikact",
        "Artist creation is currently disabled. Try again later.": "Die Erstellung eines Musikacts ist zur Zeit deaktiviert. Versuch es sp\u00e4ter noch einmal.",
        "As a performer, you can create an event at a venue you've secured on your own. Once approved by the City Admin, your event will be listed on our event website.": "Du kannst auch als Musicact eine Veranstaltung oder ein Veranstaltungsort erstellen, wenn du mit diesem eine Vereinbarung getroffen hast. Sobald es von deinem City Admin genehmigt wurde, wird die Veranstaltung auf unserer Website aufgelistet.",
        "As a venue, you can list events with performances you\u2019ve found on your own. Once approved by the City Admin, your event will be listed on our event website.": "Als ein Veranstaltungsort kannst du Veranstaltungen mit Performances auflisten, die du selbst organisiert hast. Nach der Genehmigung durch den City Admin wird deine Veranstaltung auf unserer Website aufgef\u00fchrt.",
        "Ascending": "Aufsteigend",
        "Availability": "Verf\u00fcgbarkeit",
        "Availability End Time": "Verf\u00fcgbarkeit Endzeit",
        "Availability Start Time": "Verf\u00fcgbarkeit Startzeit",
        "Available template variables": "Verf\u00fcgbare Template-Variablen",
        "Awaiting approval from City Admin": "Genehmigung durch den City Admin steht aus",
        "Back": "zur\u00fcck",
        "Bar, Caf\u00e9, or Restaurant": "Bar, Caf\u00e9 oder Restaurant",
        "Beach": "Strand",
        "Bio": "Bio",
        "Blues": "Blues",
        "Boat": "Boot",
        "Both in-person and online": "Pers\u00f6nlich und online",
        "Brass": "Brass",
        "Cabaret": "Cabaret",
        "Cancel": "Abbrechen",
        "Canceled in case of rain": "Im Falle von Regen",
        "Cancelled": "Die Veranstaltung wurde abgesagt.",
        "Cannot serialize; no default schema has been defined for %(classname)s": "Kann nicht serialisiert werden; es wurde kein Standardschema f\u00fcr %(classname)s definiert",
        "Celtic": "Celtic",
        "Change": "\u00c4ndern",
        "Change Time": "Zeit \u00e4ndern",
        "Choose whether your users can schedule events in 5 minute increments (such as 5:20pm to 5:55pm), or if they must use 15 minute increments (such as 5:15pm to 6:00pm). Most cities use 15 minute increments. <span accent>Once your site is open to the public, this setting cannot be changed.</span>": "Lege fest, ob deine User Veranstaltungen in 5-Minuten-Schritten (z. B. 17:20 bis 17:55 Uhr) planen k\u00f6nnen oder ob sie 15-Minuten-Schritte verwenden m\u00fcssen (z. B. 17:15 bis 18:00 Uhr). Die meisten St\u00e4dte verwenden 15-Minuten-Schritte. <span accent>Sobald deine Website \u00f6ffentlich zug\u00e4nglich ist, kann diese Einstellung nicht ge\u00e4ndert werden. </span>",
        "Choral": "Chormusik",
        "City": "Ort",
        "City Admin": "City Admin",
        "City Administrator": "City Administrator",
        "City Center": "Gemeindezentrum",
        "City Information": "Ausf\u00fchrliche Informationen zur Stadt",
        "City Name": "Vorname",
        "Classical": "Classic",
        "Close": "Nah",
        "Colors": "Farben",
        "Comma-separated values": "Komma-getrennte Werte",
        "Community Center": "Gemeindezentrum",
        "Complete Profile": "Dieses Profil l\u00f6schen.",
        "Confirm": "Best\u00e4tigen",
        "Confirm New Password": "Neues Passwort best\u00e4tigen",
        "Confirm Password": "Passwort best\u00e4tigen",
        "Confirmed": "best\u00e4tigt",
        "Confirmed Performances": "Best\u00e4tigte Performances",
        "Congratulations!": "Unterhaltungen!",
        "Congratulations! You are ready to open your site to the public.": "Herzlichen Gl\u00fcckwunsch! Du bist bereit, deine Website f\u00fcr die \u00d6ffentlichkeit freizuschalten.",
        "Contact": "Kontakt",
        "Contact Email": "Kontakt-Mailadresse",
        "Contact Info": "Kontaktinfo",
        "Contact Information": "Kontaktinformation",
        "Contact Name": "Kontaktname",
        "Contact Phone": "Kontakt-Telefon",
        "Contact Us": "Kontaktiere uns",
        "Content-type header must be one of %(mime_types)s": "Der Content-Type-Header muss einer der %(mime_types)s sein!",
        "Continue": "Fortfahren",
        "Continue Anyway": "Trotzdem fortfahren",
        "Conversations": "Unterhaltungen",
        "Could not create a OAuth token. Perhaps missing scopes?": "Es konnte kein OAuth-Token angelegt werden. Vielleicht fehlen die Scopes?",
        "Country": "Country",
        "Courtyard": "Innenhof",
        "Create Account": "Account erstellen",
        "Create An Event": "Ein Event erstellen",
        "Create Event": "Event erstellen",
        "Create New Festival": "Starte eine neue Unterhaltung.",
        "Create Your Own Event": "Ein eigenes Event erstellen",
        "Create a Matchmaking Profile": "Lege ein Matchmaking-Profil an",
        "Create a Performer Profile": "Erstelle ein Musikact Profil",
        "Create a Venue Profile": "Erstelle ein Profil f\u00fcr deinen Veranstaltungsort.",
        "Create a new conversation for this artist.": "Starte eine neue Unterhaltung f\u00fcr diesen Musikact.",
        "Create a new conversation for this venue.": "Starte eine neue Unterhaltung f\u00fcr diesen Veranstaltungsort.",
        "Create a new conversation.": "Starte eine neue Unterhaltung.",
        "Create a new event for this artist.": "Erstelle ein neues Event f\u00fcr diesen Musikact.",
        "Create a new event for this venue.": "Erstelle einen neuen Musikact f\u00fcr diesen Veranstaltungsort.",
        "Create a new performer profile for this user.": "Erstelle ein neues Musikact-Profil f\u00fcr diesen User.",
        "Create a new self-match event for this artist.": "Erstelle ein neues Self-Matching-Event f\u00fcr diesen Musikact:.",
        "Create a new self-match event for this user.": "Erstelle ein neues Self-Machtching-Event f\u00fcr diesen User:",
        "Create a new self-match event for this venue.": "Erstelle ein neues Self-Machtching-Event f\u00fcr diesen Veranstaltungsort an.",
        "Create a new venue profile for this user.": "Erstelle ein neues Veranstaltungsort-Profil f\u00fcr diesen User.",
        "Created at": "Erstellt bei",
        "Current City Locale": "Aktueller City Ort",
        "Current Font Family": "Aktuelle Schriftfamilie",
        "Custom CSS": "Benutzerdefiniertes CSS",
        "Customize by uploading your city\u2019s event logo. It will replace the Make Music logo in the upper left. <br/>File format should be jpg or png. A transparent png will look best. Area available for logo is 150px x 150px.": "Pass es an, indem du das Veranstaltungslogo deiner Stadt hochl\u00e4dst. Es wird das Make-Music-Logo oben links ersetzen. <br/>Das Dateiformat sollte jpg oder png sein. Ein transparentes png wird am besten aussehen. Die f\u00fcr das Logo verf\u00fcgbare Fl\u00e4che betr\u00e4gt 150px x 150px.",
        "Customize the platform colors, fonts and logo.": "Pass die Farben, Schriftarten und das Logo der Plattform an.",
        "Dashboard": "Dashboard",
        "Deactivate": "Ragtime",
        "Deadline To Register": "Deadline f\u00fcr die Registrierung",
        "Deadline to Create Profile": "Deadline f\u00fcr die Erstellung eines Profils",
        "Deadline to Register": "Deadline f\u00fcr die Registrierung",
        "Deadline to Request or Confirm a Performance": "Deadline zum Anfordern oder Best\u00e4tigen einer Performance",
        "Deadlines": "Deadlines",
        "Decline": "Ablehnen",
        "Default": "Standard-Bild",
        "Default Image": "Standard-Bild",
        "Define the musical genres performers and venues will be able to select. <span accent>Once the platform goes live for participants, you can not delete existing genres, but you can add new ones.</span>": "Definiere die Musikgenres, welche die Musikacts und Veranstaltungsorte ausw\u00e4hlen k\u00f6nnen. <span accent>Sobald die Plattform f\u00fcr Teilnehmer live geschaltet wird, k\u00f6nnen bestehende Genres nicht mehr gel\u00f6scht, aber neue hinzugef\u00fcgt werden.</span>",
        "Define the types of venues that users will be able to select. <span accent>Once the platform goes live for participants, you cannot delete existing venue types, but you can add new ones.</span>": "Definiere die Arten von Veranstaltungsorten, die von den Benutzern ausgew\u00e4hlt werden k\u00f6nnen. <span accent>Sobald die Plattform f\u00fcr Teilnehmer live geschaltet wird, k\u00f6nnen bestehende Veranstaltungsorttypen nicht nicht mehr gel\u00f6scht, aber neue hinzugef\u00fcgt werden..</span>",
        "Delete": "L\u00f6schen",
        "Delete Account": "Account l\u00f6schen",
        "Delete Performance": "Performance l\u00f6schen",
        "Delete Performance for:": "Performance f\u00fcr l\u00f6schen",
        "Delete this artist.": "Diesen Musikact l\u00f6schen.",
        "Delete this district.": "Diesen Stadtteil l\u00f6schen.",
        "Delete this event.": "Dieses Event l\u00f6schen.",
        "Delete this neighbourhood.": "Diesen Stadtteil l\u00f6schen.",
        "Delete this user account.": "Diesen User-Account l\u00f6schen.",
        "Delete this venue.": "Diesen Veranstaltungsort l\u00f6schen.",
        "Deletes districts from this city.": "L\u00f6scht Stadtteile dieser Stadt.",
        "Deletes neighbourhoods from this city.": "L\u00f6scht Stadtteile dieser Stadt.",
        "Descending": "Absteigend",
        "Description": "Beschreibung",
        "Disable Matchmaking": "Matchmaking erm\u00f6glichen",
        "Disabling Matchmaking": "Matchmaking deaktivieren",
        "Dismiss": "Ablehnen",
        "Displaying": "Anzeigen",
        "District is already existed": "Dieser Stadtteil existiert bereits",
        "Districts & Neighborhood": "Stadtteile und Nachbarschaft",
        "Do Not Use Districts": "Bearbeite diesen Stadtteil.",
        "Done": "Erledigt",
        "Download Event Data": "Veranstaltungsdaten herunterladen",
        "Drag the map and change the zoom levels to adjust how your concert listings appear to the public. This will not affect the way that maps display on this registration/matchmaking site.": "Beweg die Karte und \u00e4ndere die Zoomstufen, um einzustellen, wie die Konzerte der \u00d6ffentlichkeit angezeigt werden. Das hat keinen Einfluss auf die Art und Weise, wie die Karten auf dieser Registrierungs-/Matchmaking-Site angezeigt werden.",
        "Drag the slider to select the performance time.": "Zieh den Schieberegler, um die Performancezeit auszuw\u00e4hlen.",
        "Drag the sliders below to select the start and end times for performance match.": "Zieh an dem Schieberegler unten, um die Start- und Endzeit f\u00fcr die Performance auszuw\u00e4hlen.",
        "Drag the sliders below to select the start and end times for your requested performance.": "Zieh an dem Schieberegler unten, um die Start- und Endzeiten f\u00fcr deine gew\u00fcnschte Performance auszuw\u00e4hlen.",
        "Drag the sliders to select the performance time": "Zieh an dem Schieberegler, um die Performancezeit auszuw\u00e4hlen",
        "Drag the sliders to update the performance time": "Zieh an dem Schieberegler, um die Performancezeit zu aktualisieren",
        "Dragging the map will reposition the flag that shows the exact location for your venue. Zoom in to make fine adjustments.": "Wenn du die Karte ziehst, wird die Flagge, die den genauen Standort deines Veranstaltungsortes anzeigt, neu positioniert. Vergr\u00f6\u00dfer die Karte, um Feineinstellungen vorzunehmen.",
        "Due to social distancing concerns this year, our community is disabling the \"matchmaking\" features for Make Music Day. Please continue to sign up as a venue or a performer, and you can still register your June 21 events.": "Aufgrund von Bedenken wegen sozialer Distanzierung deaktivieren wir in diesem Jahr die \"Matchmaking\"-Funktionen f\u00fcr den Tag des Musizierens. Bitte meldet euch weiterhin als Veranstaltungsort oder Musikact an, und ihr k\u00f6nnt weiterhin eure Veranstaltungen f\u00fcr den 21. Juni anmelden.",
        "Early Music": "Alte Musik",
        "East Asian": "Ostasiatische Musik",
        "Eastern European": "Osteurop\u00e4ische Musik",
        "Edit": "Bearbeiten",
        "Edit Performer Profile": "Bearbeite Musikact-Profil",
        "Edit Profile to Find Performers": "Bearbeite Profil um Musikacts zu finden",
        "Edit Template": "Template bearbeiten",
        "Edit Times": "Bearbeite Zeiten",
        "Edit and preview email templates here. When you are satisfied with the preview, click save to keep your changes.": "Hier kannst du E-Mail-Vorlagen bearbeiten und in der Vorschau anzeigen. Wenn du mit der Vorschau zufrieden bist, klicke auf Speichern, um deine \u00c4nderungen beizubehalten.",
        "Edit basic information about your venue": "Bearbeite die Basis-Informationen deines Veranstaltungsorts",
        "Edit the Venue Profile": "Bearbeite Konzertslot-Profil",
        "Edit this artist.": "Bearbeite diesen Musikact.",
        "Edit this city.": "Bearbeite diese Stadt.",
        "Edit this district.": "Bearbeite diesen Stadtteil.",
        "Edit this email template.": "Bearbeite dieses E-Mail-Template.",
        "Edit this event.": "Bearbeite dieses Event.",
        "Edit this neighbourhood.": "Bearbeite diesen Stadtteil.",
        "Edit this user account.": "Bearbeite diesen User-Account.",
        "Edit this venue.": "Bearbeite diesen Veranstaltungsort.",
        "Electrical Requirement": "Strom Anforderung",
        "Electrical Requirements": "Strom Anforderungen",
        "Electricity / PA Needs": "Strom / PA-Bedarf",
        "Electricity Conflict": "Strom-Konflikt",
        "Electricity Needed": "Strombedarf",
        "Electricity Needs": "Strombedarf",
        "Electricity Provided": "Strombedarf",
        "Electricity and PA Needed": "Strom / PA-Bedarf",
        "Electricity and PA Provided": "Strom / PA-Bedarf",
        "Electronic": "Elektronische Musik",
        "Email": "E-Mail",
        "Email Address": "Ung\u00fcltige E-Mail Adresse.",
        "Email Templates": "E-Mail-Templates",
        "Email Templates & MailChimp": "E-Mail-Templates",
        "Enable Matchmaking": "Matchmaking erm\u00f6glichen",
        "End Time": "Endzeit",
        "End Time has to be after start time, please pick a proper value": "Die Endzeit muss nach der Startzeit liegen, bitte w\u00e4hlen Sie einen geeigneten Wert",
        "Enter 5 digit postal code": "Gib bitte eine g\u00fcltige Postleitzahl ein.",
        "Enter District Name": "Gib den Namen des Stadtteils ein",
        "Enter Genre Name": "Genre eingeben",
        "Enter Location": "Du musst einen Ort angeben.",
        "Enter Neighborhood Name": "Gib den Namen des Stadtteils ein",
        "Enter Venue Type": "Art des Veranstaltungsorts eingeben",
        "Enter address": "Stra\u00dfe und Hausnummer",
        "Enter email address": "Du musst eine Emailadresse angeben.",
        "Enter performer name": "Du musst den Namen des Musikacts eingeben",
        "Enter phone number": "Du musst eine Telefonnummer angeben.",
        "Enter the URLs for any social media accounts your festival uses. These will be displayed at the bottom of each page on your site.": "Gib die URLs f\u00fcr alle Social-Media-Accounts ein, die Ihr Festival nutzt. Diese werden auf jeder Seite der Website unten angezeigt.",
        "Enter venue name": "Genre eingeben",
        "Error": "Fehler",
        "Event": "Veranstaltung",
        "Event Accepted": "Die Veranstaltung wurde akzeptiert",
        "Event Approved": "Die Veranstaltung wurde genehmigt",
        "Event Cancelled": "Die Veranstaltung wurde abgesagt.",
        "Event Declined": "Die Veranstaltung wurde abgeleht.",
        "Event Pending": "Eine Veranstaltung steht noch aus",
        "Event created by you": "Von dir erstellte Veranstaltung",
        "Event creation is currently disabled. Try again later.": "Es ist gerade nicht m\u00f6glich ein Event zu kreieren. Versuche es bitte sp\u00e4ter noch einmal.",
        "Event has been accepted and is pending festival organizer approval": "Die Veranstaltung wurde vorerst akzeptiert, die Genehmigung durch die F\u00eate Organisation steht noch aus.",
        "Event has been aceepted and been approved by the festival organizers": "Die Veranstaltung wurde akzeptiert und von der F\u00eate Organisation genehmigt.",
        "Event has been requested": "Es wurde eine Veranstaltung angefragt.",
        "Experimental": "Experimental",
        "Export data for this city.": "Exportiere die Daten f\u00fcr diese Stadt.",
        "Facebook": "Facebook",
        "Farm": "Farm",
        "Festival End Time": "Start- und Endzeit des Festivals",
        "Festival Start & End Times": "Start- und Endzeit des Festivals",
        "Festival Start Time": "Start- und Endzeit des Festivals",
        "Fickle": "Unbest\u00e4ndig",
        "Fickle Users": "Unbest\u00e4ndige User",
        "Field": "Feld",
        "Find Performers": "Finde Musikacts",
        "Find Venues": "Finde Veranstaltungsorte",
        "Finish Setup": "Setup beenden",
        "First Name": "Vorname",
        "Folk": "Folk",
        "Fonts": "Schriften",
        "Forgot Password": "Passwort vergessen",
        "Full artist information.": "Ausf\u00fchrliche Informationen zum Musicact",
        "Full city information.": "Ausf\u00fchrliche Informationen zur Stadt",
        "Full conversation information.": "Ausf\u00fchrliche Information zur Unterhaltung",
        "Full event information.": "Ausf\u00fchrliche Information zur Veranstaltung",
        "Full venue information.": "Ausf\u00fchrliche Information zum Veranstaltungsort",
        "Funk": "Funk",
        "Gallery or Museum": "Gallerie oder Museum",
        "Garage": "Garage",
        "Garden": "Garten",
        "Genre": "Genre",
        "Genre already exists": "Genre existiert bereits",
        "Genre(s)": "Genre(s)",
        "Genres": "Genres",
        "Get all conversations for this user account.": "Alle Unterhaltungen f\u00fcr diesen Account anzeigen.",
        "Get all conversations that this city's admin has commented on.": "Alle Unterhaltungen anzeigen, die der Admin kommentiert hat.",
        "Get all custom email templates for this city.": "Alle benutzerdefinierten E-Mail-Vorlagen f\u00fcr diese Stadt anzeigen",
        "Get all genres for this city.": "Alle Genres f\u00fcr diese Stadt anzeigen",
        "Get all options relevant to artists of this city.": "Alle Optionen anzeigen, die f\u00fcr Musikacts dieser Stadt relevant sind",
        "Get all options relevant to events of this city.": "Alle Optionen anzeigen, die f\u00fcr Veranstaltungen dieser Stadt relevant sind",
        "Get all options relevant to venues of this city.": "Alle Optionen anzeigen, die f\u00fcr Veranstaltungsorte dieser Stadt relevant sind",
        "Get conversations that this artist is a part of.": "Unterhaltungen anzeigen, an denen dieser Musicact beteiligt ist.",
        "Get conversations that this venue is a part of.": "Unterhaltungen anzeigen, an denen dieser Veranstaltungsort beteiligt ist.",
        "Get event conversation.": "Event-Unterhaltungen anzeigen",
        "Go Back": "Zur\u00fcck",
        "Go Public": "Ver\u00f6fffentlichen",
        "Go to City": "Gehe zu \"Stadt\"",
        "Go to Find Performers": "Gehe zu \"Musikacts finden\"",
        "Gospel": "Gospel",
        "Grant city admin capabilities to a user.": "Gew\u00e4hre einem User die Funktionen des City-Admins",
        "Great": "Afrobeat",
        "Great, now we need a little information about your venue's location": "Bearbeite die Basis-Informationen deines Veranstaltungsorts",
        "Has Piano": "Klavier vorhanden",
        "Health check FAILED due to 'SELECT NOW()' raising an exception.": "Gesundheitspr\u00fcfung fehlgeschlagen, da 'SELECT NOW()' eine Ausnahme ausl\u00f6st.",
        "Hello City Admin!": "Hallo City Admin!",
        "Help Me Find Performers": "Hilf mir, Musikacts zu finden.",
        "Hi %(profile_name)s": "Hallo %(profile_name)s",
        "Highlife": "Highlife",
        "Hip-Hop": "Hip-Hop",
        "Home": "Home",
        "Home (Yard, Porch, Roof)": "Zuhause (Hof, Veranda, Dach)",
        "Hospital or Senior Center": "Krankenhaus oder Altersheim",
        "House of Worship": "Gotteshaus",
        "How to schedule a performance": "Wie man eine Performance festlegt",
        "I can provide both electricity and a PA": "Ich kann sowohl Elektrizit\u00e4t als auch eine Lautsprecheranlage bereitstellen.",
        "I can provide electricity, but not a PA": "Ich kann Strom liefern, aber keine Lautsprecheranlage.",
        "I can't provide electricity or a PA, but performers may bring their own": "Ich kann weder Strom noch eine PA zur Verf\u00fcgung stellen, aber die Musicact k\u00f6nnen ihre eigenen mitbringen",
        "I need electricity and a PA from the venue, or else I can't perform": "Ich brauche Strom und eine PA vom Veranstaltungsort, sonst kann ich nicht auftreten.",
        "I need electricity from the venue, or else I can't perform": "Ich brauche Strom vom Veranstaltungsort, sonst kann ich nicht auftreten.",
        "I need electricity, but I can provide my own batteries or generator if necessary": "Ich brauche Strom, kann aber bei Bedarf eigene Batterien oder einen Generator zur Verf\u00fcgung stellen.",
        "I will not use amplification (no amps, mics, or speakers) and I don't need electricity": "Ich werde keine Verst\u00e4rkung verwenden (keine Verst\u00e4rker, Mikrofone oder Lautsprecher) und ich brauche keinen Strom",
        "I'd love to have electricity and a PA provided, but I'm flexible and will make it work regardless": "Ich w\u00fcrde gerne Strom und eine PA zur Verf\u00fcgung gestellt bekommen, aber ich bin flexibel und werde es unabh\u00e4ngig von",
        "ID": "ID",
        "If matchmaking is not possible for your chapter (for example, due to social distancing requirements), you can turn it off here. Users will still be able to create profiles and events for themselves, but they won't be able to search for other users and make matches with them.": "Wenn Matchmaking f\u00fcr dein Kapitel nicht m\u00f6glich ist (z.B. aufgrund von Social-Distancing-Erfordernissen), kannst du es hier ausschalten. Die Userk\u00f6nnen zwar immer noch Profile und Veranstaltungen f\u00fcr sich selbst erstellen, aber sie k\u00f6nnen nicht nach anderen Usern suchen und mit ihnen matchen.",
        "If the requested performance time doesn't work for you but you're still interested in scheduling the performance, change the time here. The other party will receive a notification and be able to confirm, modify or decline your newly proposed time.": "Wenn die gew\u00fcnschte Performancezeit f\u00fcr dich nicht in Frage kommt, du aber trotzdem gerne ein Konzert verabreden m\u00f6chtest, dann \u00e4nder hier die Zeit. Die andere Partei erh\u00e4lt eine Benachrichtigung und kann deine neu vorgeschlagene Zeit best\u00e4tigen, \u00e4ndern oder ablehnen.",
        "If you are planning on livestreaming your event, enter the URL you will use (such as the address of your Facebook page or YouTube channel). If you're not sure, leave it blank and you can enter it later.": "Wenn du ein Livestreaming deiner Veranstaltung planst, gib die URL ein, die du verwenden wirst (z. B. die Adresse deiner Facebook-Seite oder deines YouTube-Kanals). Wenn du dir nicht sicher bist, lass sie leer und gib sie sp\u00e4ter ein.",
        "If you want to create an event for one of your performer or venue profiles, go back, switch to that profile and choose \"Create Your Own Event\".<br/><br/>If you want to list an event that does NOT involve any performers or venues you've already registered, click to continue. (This is uncommon.)": "Wenn du eine Veranstaltung f\u00fcr eines deiner Musikact- oder Veranstaltungsortprofile erstellen m\u00f6chtest, gehe zur\u00fcck, wechsle zu dem gew\u00fcnschten Profil und w\u00e4hle \"Erstelle deine eigene Veranstaltung\". Falls du eine Veranstaltung anmelden m\u00f6chtest, an welcher keine bereits registrierten Musikacts oder Veranstaltungsorte beteiligt sind, klicke auf \"weiter\".",
        "If you want to do both (you have an event to list but want to find matches as well) we recommend selecting the Performer or Venue Looking for a Match button. You'll have a profile that will allow you to seek matches, but you'll also be able to add your own performers from there.": "Wenn du beides machen m\u00f6chtest (sowohl eine Veranstaltung anmelden als auch passende Treffer finden) empfehlen wir dir auf \"Musikact/ Veranstaltungsort auf der Suche nach einem Match\" zu klicken. Das erm\u00f6glicht dir mit deinem Profil nach Treffern zu suchen als auch deine eigenen Musikacts hinzuzuf\u00fcgen.",
        "If your event is happening online, indicate where you will physically be performing (eg. your home)": "Wenn deine Veranstaltung online stattfindet, gib an, wo du physisch auftreten wirst (z.B. bei dir zu Hause)",
        "Image (optional, but strongly encouraged)": "Bild (optional, aber dringend empfohlen)",
        "Image URL": "Bild URL",
        "Images (optional, but strongly encouraged)": "Bilder (optional, aber dringend empfohlen)",
        "Import Past Profiles": "Fr\u00fchere Profile importieren",
        "Import Performer": "Anmerkungen f\u00fcr Musicacts",
        "In Case of Rain": "Im Falle von Regen",
        "In Person": "Pers\u00f6nlich / Analog",
        "In case of inclement weather, will the show go on?": "Wird die Show bei schlechtem Wetter fortgesetzt?",
        "In-Person Venues": "Analoge Veranstaltungsorte",
        "In-person": "Pers\u00f6nlich / Analog",
        "In-person, but will move online if necessary": "Analog, wird aber wenn n\u00f6tig zur Online-Veranstaltung",
        "Inbox": "Posteingang",
        "Include a message (optional)": "Eine Nachricht beif\u00fcgen (optional)",
        "Indian": "Indian",
        "Indie-Folk": "Indie-Folk",
        "Indie-Rock": "Indie-Rock",
        "Information for Performers": "Informationen f\u00fcr Musikacts",
        "Instagram": "Instagram",
        "Invalid Basic credentials in Authorization header. The OAuth client ID and secret must be provided to create a user.": "Ung\u00fcltige Basic Credentials im Authorization Header. Die OAuth-Client ID und das Geheimnis m\u00fcssen angegeben werden um einen Benutzer zu erstellen.",
        "Invalid Content-Type provided": "Ung\u00fcltiger Content-Typ bereitgestellt",
        "Invalid `Authorization: Basic` token.": "Ung\u00fcltiges `Autorisierung: Basic` Token.",
        "Invalid data type": "Ung\u00fcltiger Datentyp",
        "Invalid email address": "Ung\u00fcltige E-Mail Adresse",
        "Invalid email address.": "Ung\u00fcltige E-Mail Adresse.",
        "Invalid value": "Unzul\u00e4ssiger Wert",
        "Jazz": "Jazz",
        "Keep in mind that changes to color can affect usability. Test all pages to make sure color combinations still communicate the functionality of the site.": "Denk daran, dass Farb\u00e4nderungen die Benutzerfreundlichkeit beeintr\u00e4chtigen k\u00f6nnen. Teste alle Seiten, um sicherzustellen, dass Farbkombinationen weiterhin die Funktionalit\u00e4t der Website vermitteln.",
        "Kids": "Kinder",
        "Last Name": "Nachname",
        "Latin": "Latin",
        "Lesson": "Unterricht",
        "Lessons": "Unterricht",
        "Let me double check everything first": "Lass mich zuerst alles ganz genau pr\u00fcfen.",
        "Library": "Bibliothek",
        "List My Event": "Meine Veranstaltungen auflisten",
        "List New Event": "Alle neuen Veranstaltungen auflisten",
        "List all events in this city.": "Alle Veranstaltungen in dieser Stadt auflisten.",
        "List confirmed events for this artist.": "Alle best\u00e4tigten Veranstaltungen dieses K\u00fcnstlers auflisten.",
        "List confirmed events for this venue.": "Alle best\u00e4tigten Veranstaltungen dieses Veranstaltungsortes auflisten.",
        "List events for this artist.": "Alle Veranstaltungen dieses K\u00fcnstlers auflisten.",
        "List events for this venue.": "Alle Veranstaltungen dieses Veranstaltungsortes auflisten.",
        "Listed Event": "Aufgelistete Veranstaltungen",
        "Live Streaming": "Live Streaming",
        "Livestreaming website (optional)": "Livestreaming Webseite (optional)",
        "Livestreaming?": "Livestreaming?",
        "Lobby": "Lobby",
        "Locale": "\u00d6rtlichkeit",
        "Location": "Ort",
        "Login": "Login",
        "Logo": "Logo",
        "Logout": "Logout",
        "Looking to sign up for Make Music Day? Select your city here": "Du m\u00f6chtest dich f\u00fcr die F\u00eate de la Musique bewerben bzw. anmelden? W\u00e4hle hier deine Stadt aus.",
        "MPB": "MPB",
        "Make Changes": "\u00c4nderungen vornehmen",
        "Make Music Day": "Ende des Make Music Days",
        "Make Music Day End": "Ende des Make Music Days",
        "Make Music Day Start": "Beginn des des Make Music Days",
        "Make Music Day is a celebration of music on the summer solstice that is performed by anyone and enjoyed by everyone. You're invited to take part in the festivities and help show off our local talent and join the public celebration.\n\nThe festival is on June 21st and goes all day. It takes place in over 1,000 cities around the world.": "Jedes Jahr zum Som",
        "Map": "Karte",
        "Mark this conversation as read.": "Markiere diese Unterhaltung als gelesen.",
        "Mark this conversation as unread.": "Markiere diese Unterhaltung als ungelesen.",
        "Mark this message as read.": "Markiere diese Nachricht als gelesen.",
        "Mark this message as unread.": "Markiere diese Nachricht als ungelesen.",
        "Market": "Markt",
        "Match My Preferences": "Meine Pr\u00e4ferenzen anpassen",
        "Message": "Nachricht",
        "Message (optional)": "Nachricht (optional)",
        "Message from Admin": "Nachricht von Admin",
        "Metal": "Metal",
        "Microsoft Excel": "Microsoft Excel",
        "Middle Eastern": "Nah\u00f6stliche Musik",
        "Missing data for required field.": "Fehlende Daten f\u00fcr das Pflichtfeld.",
        "Missing required field": "Fehlendes Pflichtfeld",
        "Missing soundcloud_song_urls": "Fehlende soundscloud_song_urls",
        "Missing youtube_video_urls": "Fehlende yotube_video_urls",
        "Musical Theater": "Musical Theater",
        "Mute this conversation.": "Die Unterhaltung stumm schalten",
        "My Profiles": "Meine Profile",
        "Name": "Name",
        "Native American": "Native American",
        "Neighborhood": "Nachbarschaft",
        "Neighborhood is already existed": "Nachbarschaft existiert bereits",
        "New Message": "Neue Nachricht",
        "New Password": "Neues Passwort",
        "New Performance Requested": "Neue Performance angefordert",
        "New Performer Profile": "Neues Musicact-Profil",
        "New This Week": "Diese Woche neu",
        "New Venue Profile": "Neues Veranstaltungsort-Profil",
        "New users will not be able to create an account after this date.": "Nach diesem Datum k\u00f6nnen neue Benutzer kein Profil mehr erstelllen.",
        "Next": "n\u00e4chste",
        "Next Step": "n\u00e4chster Schritt",
        "Next: Availability": "Verf\u00fcgbarkeit",
        "Next: Location": "Location bei Regen",
        "Next: Performance Details": "Details zur Performance",
        "Next: Performance Time": "W\u00e4hle die Zeit der Performance:",
        "Next: Performer Details": "Details zur Performance",
        "No": "Nein",
        "No Electricity Allowed": "Strombedarf",
        "No Electricity Needed": "Strombedarf",
        "No Electricity Provided": "Strombedarf",
        "No Electricity Used": "Strombedarf",
        "No Profiles Found": "Keine Profile gefunden",
        "No amplification of any kind (amps, mics, or speakers) is permitted at this site": "An dieser Stelle ist keine Verst\u00e4rkung jeglicher Art (Verst\u00e4rker, Mikrofone oder Lautsprecher) erlaubt.",
        "No conversations yet - drop them a line!": "Noch keine Unterhaltung \u2013 hinterlass ein paar Zeilen!",
        "No request body was provided.": "Es wurde kein Request Body zur Verf\u00fcgung gestellt.",
        "No request body was provided. It should be JSON data.": "Es wurde kein Request Body zur Verf\u00fcgung gestellt. Es sollten JSON-Daten sein.",
        "No request body was provided. It should be form encoded data.": "Es wurde kein Request Body zur Verf\u00fcgung gestellt. Es sollten formularverschl\u00fcsselte Daten sein.",
        "No such schema %(classname)s in package %(path)s exists!": "Ein solches Schema %(classname)s im Paket %(path)s existiert nicht!",
        "Not Accepting Requests": "Akzeptiert keine Anfragen.",
        "Not Wheelchair Accessible": "Nicht f\u00fcr Rollstuhlfahrer zug\u00e4nglich",
        "Not a JSON payload": "Kein JSON Payload",
        "Not available for requests": "F\u00fcr Anfragen nicht offen",
        "Notes for Performers": "Anmerkungen f\u00fcr Musicacts",
        "OAuth client is not authorized for that grant type.": "Der OAuth-Client ist f\u00fcr diesen Grant Type nicht berechtigt.",
        "OK": "OK",
        "OOPS! It looks like registration hasn't opened yet for this city. Please contact your city organizer or check back later.": "Ups! Es sieht so aus, als w\u00e4re die Registrierung f\u00fcr diese Stadt noch nicht freigegeben. Kontaktiere bitte die Organisation dieser Stadt oder versuch es sp\u00e4ter noch einmal.",
        "Office Building": "B\u00fcrogeb\u00e4ude",
        "Ok": "OK",
        "Once both parties have confirmed that the performance is set, your city admin will give final confirmation that the performance is a go. This may take some time, but never fear, you will hear back!": "Sobald beide Parteien die Performance genehmigt haben, wird dein City Admin Bescheid geben, ob die Performance stattfinden kann. Dieser Prozess kann etwas dauern, aber keine Sorge, du wirst von uns eine Antwort erhalten.",
        "One last thing!": "Eine letzte Sache!",
        "Online Only": "Nur online verf\u00fcgbar",
        "Online only": "Nur online verf\u00fcgbar",
        "Open to Anything": "Offen f\u00fcr alles",
        "Opera": "Opera",
        "Other": "Other",
        "Parade": "Parade/Umzug",
        "Park": "Park",
        "Parking Lot": "Parkplatz",
        "Participatory": "Partizipativ",
        "Password": "Passwort",
        "Password (at least 8 characters)": "Passwort (mind. acht Zeichen)",
        "Password Reset": "Passwort zur\u00fccksetzen",
        "Password Reset Requested": "Das Zur\u00fccksetzen deines Passwortes wurde beantragt,",
        "Password Update Notification": "Passwort-Update-Benachrichtigung",
        "Password was reset!": "Dein Passwort wurde zur\u00fcckgesetzt",
        "Pending": "ausstehend",
        "Pending Your Approval": "Deine Zustimmung steht aus",
        "Performance Cancelled": "Die Performance wird abgesagt.",
        "Performance Confirmed": "Performance wurde best\u00e4tigt",
        "Performance Details": "Details zur Performance",
        "Performance Request Accepted": "Performanceanfrage wurde akzeptiert",
        "Performance Status": "Status der Performance",
        "Performance Time": "Spielraum der Performance",
        "Performance will be canceled": "Die Performance wird abgesagt.",
        "Performance will go on as scheduled": "Die Performance wird wie geplant weitergef\u00fchrt.",
        "Performance will move to a nearby sheltered place": "Die Performance wird an einen nahe gelegenen gesch\u00fctzten Ort verlegt.",
        "Performances": "Performances",
        "Performer": "Musicact",
        "Performer Availability": "Verf\u00fcgbarkeit des Musicacts",
        "Performer Bio": "Biografie des Musicacts",
        "Performer Info": "Infos \u00fcber den Musicact",
        "Performer Name": "Name des Musicacts",
        "Performer or Venue<br/>Looking for a Match": "Musikact oder Veranstaltungsort<br/>sucht nach einem Match",
        "Performers": "Musicacts",
        "Performers seeking<br/>Venue": "Musikact sucht<br/>Veranstaltungsort",
        "Phone": "Telefon",
        "Phone Number": "Telefonnummer",
        "Piano": "Klavier",
        "Pick Another Image": "W\u00e4hle ein anderes Bild",
        "Plaza or Patio": "Platz oder Innenhof",
        "Please check your email inbox, and follow the instructions to reset your password.": "\u00dcberpr\u00fcfe bitte dein Postfach und folge den Anweisungen um dein Passwort zur\u00fcckzusetzen.",
        "Please check your inbox to confirm your email address.": "\u00dcberpr\u00fcfe bitte dein Postfach und best\u00e4tige deine Emailadresse.",
        "Please describe your venue, including anything you plan to offer musicians, for example, what equipment you have, expected audiences, any hospitality or monetary compensation, etc (1000 character max)": "Hier kannst du deinen Veranstaltungsort beschreiben. Gib bitte alles an, was du den Musikern zur Verf\u00fcgung stellen kannst, zum Beispiel, welche Ger\u00e4te du hast, was f\u00fcr ein Publikum du erwartest, ob es eine Verg\u00fctung in irgendeiner Form gibt usw. (Maximal 1000 Zeichen)",
        "Please login with your new password": "Logge dich bitte mit deinem neuen Passwort ein.",
        "Pop": "Pop",
        "Postal Code": "Postleitzahl",
        "Poster Options": "Website (optional)",
        "Preferred Genre": "Bevorzugtes Genre",
        "Preferred Genre(s)": "Bevorzugte Genre(s)",
        "Preview": "Vorschau",
        "Previous": "Vorherige",
        "Primary Buttons & Highlight Color": "Prim\u00e4rtasten & Highlightfarbe",
        "Profile Created Date": "Erstellungsdatum des Profils",
        "Profile Status": "Profil-Status",
        "Profiles": "Profile",
        "Provide any introduction you feel is helpful.": "Gib eine Einf\u00fchrung, die du f\u00fcr hilfreich h\u00e4ltst",
        "Provides Electricity": "Stellt Strom bereit",
        "Punk": "Punk",
        "R&B": "R&B",
        "Ragtime": "Ragtime",
        "Rain Location": "Location bei Regen",
        "Rain or Shine": "Regen oder Sonnenschein",
        "Rain or shine": "Regen oder Sonnenschein",
        "Reactivate": "Ragtime",
        "Reggae": "Reggae",
        "Register Event": "Event eintragen",
        "Register Performer": "Musikact eintragen",
        "Register Venue": "Veranstaltungsort eintragen",
        "Registered Performers": "Registrierte Musikacts",
        "Registered Venues": "Registrierte Veranstaltungsorte",
        "Religious": "Religi\u00f6se",
        "Remember Me": "Remember Me",
        "Remove a genre from this city.": "Entferne ein Genre aus dieser Stadt.",
        "Remove a venue type from this city.": "Entferne einen Veranstaltungsorttyp aus dieser Stadt.",
        "Remove all custom email templates from this city.": "Entferne alle benutzerdefinierten E-Mail-Vorlagen aus dieser Stadt.",
        "Remove all custom genres from this city.": "Entferne alle benutzerdefinierten Genres aus dieser Stadt.",
        "Remove all custom venue types from this city.": "Entferne alle benutzerdefinierten Veranstaltungsorttypen aus dieser Stadt.",
        "Renew this artist for another festival.": "Erneuern Sie diesen Musikacr f\u00fcr ein weiteres Festival.",
        "Renew this city for the current festival year.": "Erneuere diese Stadt f\u00fcr das laufende Festivaljahr.",
        "Renew this venue for another festival.": "Erneuere diesen Veranstaltungsort f\u00fcr ein anderes Festival.",
        "Reply to this conversation.": "Antworte auf diese Unterhaltung.",
        "Reply to this message.": "Antworte auf diese Nachricht",
        "Request a Performance": "Eine Performance anfragen",
        "Request a new confirmation email.": "Fordere eine neue Best\u00e4tigungsemail an.",
        "Request a password reset email.": "Fordern Sie eine E-Mail zum Zur\u00fccksetzen des Passworts an.",
        "Request from performer": "Anfrage von Musikact",
        "Request from venue": "Anfrage von Veranstaltungsort",
        "Request origin blocked due to CORS configuration.": "Ursprung der Anfrage aufgrund der CORS-Konfiguration gesperrt.",
        "Requested by you": "Angefragt von dir",
        "Requested scopes do not match with scopes the OAuth client and user is authorized for.": "Angeforderte Scopes stimmen nicht mit den Scopes \u00fcberein, f\u00fcr die der OAuth-Client und Benutzer berechtigt ist.",
        "Requests over 1 week old": "Anfragen \u00e4lter als eine Woche",
        "Requests over 72 hours old": "Anfragen \u00e4lter als 72 Stunden",
        "Required field is missing": "Pflichtfeld fehlt",
        "Required field is missing or empty": "Pflichtfeld fehlt oder ist leer",
        "Resend Confirmation": "Starte eine neue Unterhaltung.",
        "Reset": "Zur\u00fccksetzen",
        "Reset Password": "Passwort zur\u00fccksetzen",
        "Resize & Crop": "Gr\u00f6\u00dfe \u00e4ndern & beschneiden",
        "Retail Shop": "Ladengesch\u00e4ft",
        "Revoke city admin capabilities of a user.": "Widerrufen der City-Admin-F\u00e4higkeiten eines Users.",
        "Rock": "Rock",
        "Rooftop": "Dach",
        "Samba": "Samba",
        "Save": "Speichern",
        "Save Changes": "\u00c4nderungen speichern",
        "Save Performance": "Performance speichern",
        "Save Performances": "Performances speichern",
        "Save Request": "Anfrage speichern",
        "Save Template": "Template speichern",
        "Save as CSV": "Als CSV speichern",
        "Save as Excel": "Als Excel speichern",
        "Save to Profile": "Im Profil speichern",
        "Schedule": "Zeitplan",
        "Schedule a Performance": "Plane eine Performance",
        "Scheduling deadline": "Plane eine Deadline",
        "School": "Schule",
        "Search": "Suche",
        "Search Performers": "Suche einen Musikact",
        "Search Venues": "Suche einen Veranstaltungsort",
        "Secondary Buttons": "Sekund\u00e4re Schaltfl\u00e4chen",
        "Select a performer.": "W\u00e4hle einen Musikact",
        "Select a venue.": "W\u00e4hle einen Veranstaltungsort.",
        "Select an Image": "W\u00e4hle eine aus",
        "Select neighborhood": "Nachbarschaft ausw\u00e4hlen.",
        "Select one": "W\u00e4hle eine aus",
        "Select performance time:": "W\u00e4hle die Zeit der Performance:",
        "Select the city": "W\u00e4hle eine Stadt aus.",
        "Select up to 3 genres": "W\u00e4hle bis zu drei Genres.",
        "Select venue type": "W\u00e4hle die Art des Veranstaltungsortes aus",
        "Self-Match Profiles": "Selbst-Matching Profile",
        "Send": "Abschicken",
        "Send Request": "Sende eine Anfrage",
        "Send a Message": "Sende eine Nachricht.",
        "Send a performance request to the performer.": "Sende dem Musicact eine Performance-Anfrage",
        "Set Default to NO": "Standardeinstellung auf NEIN setzen",
        "Set Default to YES": "Standardeinstellung auf JA setzen",
        "Set New Password": "W\u00e4hle ein neues Passwort.",
        "Set deadlines for users to sign up, create profiles, and create performances. All deadlines begin at midnight at the beginning of the day selected. (For instance, if you set a deadline for June 1, it will take effect on June 1st at 12:01am.) If you do not have any deadlines for participation, set all three dates to be after your festival date. These deadlines can be changed later, even after your site is open to the public.": "Lege Deadlines fest, bis zu denen sich User anmelden, Profile erstellen und Performances planen k\u00f6nnen. Die Fristen beginnen um Mitternacht des ausgewh\u00e4lten Tages. (wenn z.B.  die gew\u00e4hlte Frist der 1. Juni ist, sind keine Aktivit\u00e4ten ab dem 2. Juni um 00:01 Uhr mehr m\u00f6glich). Wenn es keine Fristen f\u00fcr die Teilnahme gibt, w\u00e4hle f\u00fcr alle drei Termine den 21. Juni aus. Die Fristen k\u00f6nnen nachtr\u00e4glich noch ge\u00e4ndert werden, nachdem die Seite f\u00fcr die \u00d6ffentlichkeit freigeschaltet wurde.",
        "Set up your city\u2019s districts and neighborhoods. <span accent>Once the platform goes live for participants, you can not edit existing districts or neighborhoods, but you can add new ones.</span>": "Richte die Stadtteile und Nachbarschaften deiner Stadt ein. </span accent>Sobald die Plattform f\u00fcr Bewerber live geschaltet wird, k\u00f6nnen bestehende Stadtteile und Nachbarschaften nicht mehr bearbeitet werden, aber es k\u00f6nnen neue hinzuf\u00fcgt werden.</span>",
        "Setup": "Setup",
        "Shopping Center": "Shopping Center",
        "Showing": "Anzeigen",
        "Shy": "Sch\u00fcchtern",
        "Shy Users": "Sch\u00fcchterne User",
        "Sidewalk": "B\u00fcrgersteig",
        "Sign up deadline": "Anmeldeschluss",
        "Singer / Songwriter": "Singer-Songwriter",
        "Ska": "Ska",
        "Social Media": "Social Media",
        "Sort by:": "Sortieren nach:",
        "Soul": "Soul",
        "SoundCloud Tracks (optional)": "SoundCloud Tracks (optional)",
        "SoundCloud URL": "SoundCloud URL",
        "Soundcloud": "SoundCloud",
        "Standards": "Standards",
        "Start Time": "Startzeit",
        "Start from the Beginning": "Von Anfang an beginnen",
        "Street Address": "Stra\u00dfe und Hausnummer",
        "Street or Block Party": "Stra\u00dfen- oder Blockparty",
        "Subject": "Betreff",
        "Success": "Erfolg",
        "Success!": "Erfolg!",
        "Swing": "Swing",
        "Switch to": "Wechseln zu",
        "Tell the audience a little about the venue. (300 character max)": "Erz\u00e4hle den Zuschauern ein wenig \u00fcber den Veranstaltungsort (Max. 300 Zeichen)",
        "Tell the performer and the venue why you think they are a good match.": "Gebe deine Meinung ab warum du denkst, dass der Musikact und der Veranstaltungsort gut zusammen passen.",
        "Tell us about your performer(s).": "Erz\u00e4hle uns etwas \u00fcber dein(e) Musikact(s).",
        "Terms & Conditions": "Bedingungen und Konditionen",
        "Terms & Conditions Can Not Be Empty": "Bedingungen und Konditionen k\u00f6nnen nicht leer sein",
        "Thank you": "Vielen Dank",
        "Thank you for confirming your email address.": "Vielen Dank f\u00fcr die Best\u00e4tigung deiner E-Mail-Adresse.",
        "Thank you for registering for Make Music Day! Please confirm your email": "Vielen Dank, dass du dich f\u00fcr die F\u00eate de la Musique angemeldet hast! Bitte best\u00e4tige deine E-Mail",
        "Thank you, the confirmation email has been re-sent. Please check your email inbox": "Vielen Dank, die Best\u00e4tigungsemail wurde versandt. Checke bitte deine E-Mails.",
        "That email is already in use.": "Diese E-Mail-Adresse wird bereits verwendet.",
        "That genre already exists.": "Dieses Genre existiert bereits.",
        "That venue type already exists.": "Dieser Veranstaltungsorttyp existiert bereits.",
        "The City Admin will be in touch to confirm performances or follow up if needed. This may take a little while, but don't worry, we will get back to you!": "Der City Admin wird sich mit dir in Verbindung setzen, um Performances zu best\u00e4tigen oder bei Bedarf nachzufassen. Dies kann eine Weile dauern, aber keine Sorge, wir werden uns bei dir melden!",
        "The SELECT query for the database succeeded, but did not return a valid value.": "Die SELECT Abfrage f\u00fcr die Datenbank war erfolgreich, gab aber keinen g\u00fcltigen Wert zur\u00fcck.",
        "The administrator has disabled this city's \"matchmaking\" features. Please continue to sign up as a venue or a performer, and you will be able to register your events.": "Aufgrund von Bedenken wegen sozialer Distanzierung deaktivieren wir in diesem Jahr die \"Matchmaking\"-Funktionen f\u00fcr den Tag des Musizierens. Bitte meldet euch weiterhin als Veranstaltungsort oder Musikact an, und ihr k\u00f6nnt weiterhin eure Veranstaltungen f\u00fcr den 21. Juni anmelden.",
        "The end time cannot be before the start time.": "Die Endzeit kann nicht vor der Startzeit liegen.",
        "The event has been confirmed by the artist or venue. You cannot change the time of it.": "Die Veranstaltung wurde durch den Musikact oder den Veranstaltungsort best\u00e4tigt. Du kannst den Zeitpunkt der Veranstaltung nicht \u00e4ndern.",
        "The event is in an unhandled status.": "Die Veranstaltung befindet sich in einem unbehandelten Status.",
        "The event request is not valid.": "Die Eventanfrage ist nicht g\u00fcltig.",
        "The genre selected is not valid for an artist.": "Das ausgew\u00e4hlte Genre ist f\u00fcr einen Musikact nicht g\u00fcltig.",
        "The performance has been approved!": "Die Performance wurde genehmigt!",
        "The performance has been canceled!": "Die Performance wurde abgesagt!",
        "The performance has been created!": "Die Performance wurde kreiiert!",
        "The performance has been updated!": "Die Performance wurde aktualisiert!",
        "The performer already has a confirmed event that overlaps with this performance time.": "Der Musikact hat bereits eine best\u00e4tigte Veranstaltung, die sich mit dieser Die Performancezeit \u00fcberschneidet.",
        "The performer already has a confirmed event that overlaps with this performance time. Are you sure you want to go ahead?": "Der Musikact hat bereits eine best\u00e4tigte Veranstaltung, die sich mit dieser Die Performancezeit \u00fcberschneidet.",
        "The performer is unavailable during the requested time.": "Der Musikact ist w\u00e4hrend der gew\u00fcnschten Zeit nicht verf\u00fcgbar.",
        "The performer will be notified.<br/><br/>The City Admin will be in touch to confirm or follow up if needed. This may take a little while, but don\u2019t worry, we will get back to you!": "Der Musikact wird benachrichtigt. <br/><br/>Der City Admin wird sich in Verbindung setzen, um gegebenenfalls zu best\u00e4tigen oder nachzufassen. Dies kann eine Weile dauern, aber keine Sorge, wir werden uns melden!",
        "The provided credentials were invalid.": "Die angegebenen Zugangsdaten waren ung\u00fcltig.",
        "The provided credentials were unauthorized to perform this action.": "Die angegebenen Zugangsdaten sind nicht dazu autorisiert, diese Aktion durchzuf\u00fchren.",
        "The provided district cannot be deleted until all neighbourhoods have been removed from it.": "Der angegebene Stadtteil kann erst dann gel\u00f6scht werden, wenn alle Nachbarschaften aus ihm entfernt worden sind.",
        "The provided genre is a system value and cannot be deleted.": "Das angegebene Genre ist ein Systemwert und kann nicht gel\u00f6scht werden.",
        "The provided neighbourhood cannot be added without specifying a district unless all districts have been removed first.": "Die angegebene Nachbarschaft kann nicht ohne Angabe eines Stadtteils hinzugef\u00fcgt werden, es sei denn, alle Stadtteile wurden zuvor entfernt.",
        "The provided object was not a City object": "Das bereitgestellte Objekt war kein Stadtobjekt",
        "The provided object was not a User object": "Das bereitgestellte Objekt war kein Userobjekt.",
        "The provided password was invalid.": "Das angegebene Passwort war ung\u00fcltig.",
        "The provided query arguments contain an invalid key and/or value.": "Die \u00fcbermittelten Abfrage-Argumente enthalten einen ung\u00fcltigen Schl\u00fcssel und/oder Wert.",
        "The provided timezone is invalid.": "Die angegebene Zeitzone ist ung\u00fcltig.",
        "The provided type was not a valid export format.": "Der angegebene Typ war kein g\u00fcltiges Exportformat.",
        "The provided venue type is a system value and cannot be deleted.": "Der angegebene Veranstaltungsorttyp ist ein Systemwert und kann nicht gel\u00f6scht werden.",
        "The request appears in your inbox and the other person\u2019s, where you can have a conversation about the performance, adjust the time as needed, and confirm.": "Die Anfrage erscheint in deinem Posteingang und dem Posteingang der anderen Person, wo du ein Gespr\u00e4ch \u00fcber die Performance f\u00fchren sowie bei Bedarf die Zeit anpassen und best\u00e4tigen kannst.",
        "The request cannot be fulfilled due to bad syntax.": "Die Anfrage kann aufgrund schlechter Syntax nicht erf\u00fcllt werden.",
        "The request export type is not valid.": "Die Exportart der Anfrage ist nicht g\u00fcltig.",
        "The request requires user authentication.": "Die Anfrage erfordert eine Benutzerauthentifizierung.",
        "The request signature is not valid.": "Die Anfragesignatur ist nicht g\u00fcltig.",
        "The requested city is not setup in this system.": "Die angeforderte Stadt ist in diesem System nicht eingerichtet.",
        "The requested resource could not be located.": "Die angeforderte Ressource konnte nicht gefunden werden.",
        "The requested resource owner could not be determined.": "Der angeforderte Ressourcenbesitzer konnte nicht ermittelt werden.",
        "The requested time is outside the festival start or end time.": "Die angeforderte Zeit liegt au\u00dferhalb der Anfangs- oder Endzeit des Festivals.",
        "The reset token has expired.": "Das Reset-Token ist abgelaufen.",
        "The server does not support the functionality required to fulfill the request.": "Der Server unterst\u00fctzt nicht die zur Erf\u00fcllung der Anforderung erforderliche Funktionalit\u00e4t.",
        "The server has not found anything matching the Request-URI.": "Der Server hat nichts gefunden, was der Anfrage-URI entspricht.",
        "The server is currently unavailable.": "Der Server ist derzeit nicht verf\u00fcgbar.",
        "The server understood the request, but is refusing to fulfill it.": "Der Server hat die Anfrage verstanden, weigert sich aber, sie zu erf\u00fcllen.",
        "The specified city already exists.": "Die angegebene Stadt existiert bereits.",
        "The specified template type already exists for this city": "Der angegebene Vorlagentyp existiert bereits f\u00fcr diese Stadt",
        "The specified user account already exists.": "Das angegebene Benutzerkonto existiert bereits.",
        "The system could not send the appropriate message via email. Please try again later.": "Das System konnte die entsprechende Nachricht nicht per E-Mail versenden. Bitte versuche es sp\u00e4ter noch einmal.",
        "The system could not send the email because the required template was not found.": "Das System konnte die E-Mail nicht versenden, weil die erforderliche Vorlage nicht gefunden wurde.",
        "The system has not been completely initialized.": "Das System wurde nicht vollst\u00e4ndig initialisiert.",
        "The token you are using is not attached to a user account.": "Das von Ihnen verwendete Token ist nicht an ein Benutzerkonto angeh\u00e4ngt.",
        "The translation catalog was not found.": "Der \u00dcbersetzungskatalog wurde nicht gefunden.",
        "The user with that email and password could not be found.": "Der Benutzer mit dieser E-Mail und diesem Passwort konnte nicht gefunden werden.",
        "The venue already has a confirmed event that overlaps with this performance time.": "Der Musikact hat bereits eine best\u00e4tigte Veranstaltung, die sich mit dieser Die Performancezeit \u00fcberschneidet.",
        "The venue already has a confirmed event that overlaps with this performance time. Are you sure you want to go ahead?": "Der Veranstaltungsort hat bereits eine best\u00e4tigte Veranstaltung, die sich mit dieser Performancezeit \u00fcberschneidet. Bist du sicher, dass du weitermachen willst?",
        "The venue already has an event confirmed starting or ending during the requested times.": "Der Veranstaltungsort hat bereits eine Veranstaltung best\u00e4tigt, die zu den gew\u00fcnschten Zeiten beginnt oder endet.",
        "The venue is unavailable during the requested time.": "Der Veranstaltungsort ist w\u00e4hrend der gew\u00fcnschten Zeit nicht verf\u00fcgbar.",
        "Theater or Arts Venue": "Veranstaltungsort f\u00fcr Theater oder Kunst.",
        "There are overlapped performance times. Are you sure you want to go ahead?": "Es gibt \u00fcberlappende Performancezeiten. Bist du sicher, dass du weitermachen willst?",
        "There are too many profile images for this user. Unable to add another.": "Es gibt zu viele Profilbilder f\u00fcr diesen Benutzer. Es ist nicht m\u00f6glich, ein weiteres hinzuzuf\u00fcgen.",
        "These can change later on as you and the performer discuss specifics.": "Diese k\u00f6nnen sich sp\u00e4ter \u00e4ndern, wenn du und der Musikact die Details besprechen.",
        "These can change later on as you and the venue discuss specifics.": "Diese k\u00f6nnen sich sp\u00e4ter \u00e4ndern, wenn du und der Veranstaltungsort die Details besprechen.",
        "This OAuth client is not allowed to create users.": "Dieser OAuth-Client darf keine Benutzer anlegen.",
        "This chapter is currently paused.": "Dieses Kapitel ist derzeit pausiert.",
        "This site is not yet ready to accept new user registrations, please try again later.": "Diese Website ist noch nicht bereit, neue User-Registrierungen anzunehmen, bitte versuch es sp\u00e4ter noch einmal.",
        "This will be included in the event listing. Tell the audience a little about the performer (1000 character max)": "Dies wird in die Veranstaltungsliste aufgenommen. Erz\u00e4hl dem Publikum ein wenig \u00fcber den Musikact (max. 1000 Zeichen)",
        "Time Conflict": "Zeitkonflikt",
        "Time Increments for Bookings": "Zeitabst\u00e4nde f\u00fcr Bookings",
        "Times & Deadlines": "Zeiten und Deadlines",
        "To use the matching platform to find performers, fill out the following:": "Um \u00fcber die Matching-Plattform Musikacts zu finden, f\u00fcll die folgenden Felder aus:",
        "Total Accounts": "Gesamte Accounts",
        "Total Confirmed": "Insgesamt best\u00e4tigt",
        "Transfer this artist profile to another user.": "\u00dcbertrage dieses Musikact-Profil auf einen anderen Benutzer.",
        "Transfer this venue profile to another user.": "\u00dcbertrage dieses Veranstaltungsort-Profil auf einen anderen Benutzer.",
        "Transit Station": "Durchgangsstation",
        "Twitter": "Twitter",
        "Type": "Typ",
        "Unable to connect to the server at the moment, please check your network connection": "Zur Zeit kann keine Verbindung mit dem Server hergestellt werden, bitte \u00fcberpr\u00fcfe deine Netzwerkverbindung",
        "Unaccept this event on behalf of a venue.": "Ablehnen dieser Veranstaltung im Namen eines Veranstaltungsortes.",
        "Unaccept this event on behalf of an artist.": "Ablehnen dieser Veranstaltung im Namen eines Musikacts.",
        "Unaccept this event.": "Ablehnen dieser Veranstaltung",
        "Unapprove this event.": "Genehmige diese Veranstaltung nicht.",
        "Unknown object class": "Unbekannte Objektklasse",
        "Unsupported object type": "Nicht unterst\u00fctzter Objekttyp",
        "Unsupported value": "Nicht unterst\u00fctzter Wert",
        "Update": "Aktualisieren",
        "Update Message": "Neue Nachricht",
        "Update Performer": "Aktualisiere Musikact",
        "Update Profile": "Dieses Profil l\u00f6schen.",
        "Update Venue": "Aktualisiere Verasnstaltungsort",
        "Updated at": "Aktualisiert unter",
        "Upload": "Upload",
        "Upload a new logo for this city.": "Lade ein neues Logo f\u00fcr diese Stadt hoch.",
        "Upload a new profile image for this artist.": "Lade ein neues Profilbild f\u00fcr diesen Musikact hoch.",
        "Upload a profile image in preparation for creating a performer profile for this user.": "Lade ein Profilbild hoch, um die Erstellung eines Musikact-Profils f\u00fcr diesen User vorzubereiten.",
        "Uploaded file type was invalid or not acceptable.": "Der hochgeladene Dateityp war ung\u00fcltig oder nicht akzeptabel.",
        "Use Districts": "Bearbeite diesen Stadtteil.",
        "Use mouse to click or drag to define the image crop area": "Verwende die Maus zum Klicken oder Ziehen, um den Bildbeschnittbereich zu definieren",
        "User data and profile information.": "Userdaten und Profilinformationen.",
        "Users will not be able to create a new venue or artist profile after this date.": "User k\u00f6nnen nach diesem Datum keinen neuen Veranstaltungsort oder ein neues Musikactprofil erstellen.",
        "Users will not be able to request or confirm a performance after this date, or to create their own event.": "User k\u00f6nnen nach diesem Datum keine Performance mehr anfragen oder best\u00e4tigen oder eine eigene Veranstaltung erstellen.",
        "Users will only be allowed to schedule performances within this time range.": "User d\u00fcrfen nur Performances innerhalb dieser Zeitspanne planen.",
        "Validation error occurred": "Validierungsfehler aufgetreten",
        "Venue": "Veranstaltungsort",
        "Venue Availability": "Verf\u00fcgbarkeit des Veranstaltungsorts",
        "Venue Defaults": "Standardeinstellungen f\u00fcr den Veranstaltungsort",
        "Venue Description (optional)": "Beschreibung des Veranstaltungsortes (optional)",
        "Venue Info": "Info \u00fcber den Veranstaltungsort",
        "Venue Name": "Name des Veranstaltungsorts",
        "Venue Type": "Art des Veranstaltungsorts",
        "Venue Types": "Arten von Veranstaltungsorten",
        "Venue creation is currently disabled. Try again later.": "Die Erstellung von Veranstaltungsorten ist derzeit deaktiviert. Versuch es sp\u00e4ter noch einmal.",
        "Venue does not have a piano": "Der Veranstaltungsort verf\u00fcgt \u00fcber ein Piano",
        "Venue has a piano": "Der Veranstaltungsort verf\u00fcgt \u00fcber ein Piano",
        "Venue seeking<br/>Performers": "Veranstaltungsort sucht<br/>Musikacts",
        "Venue type is already existed": "Diese Art von Veranstaltungsort existiert schon",
        "Venues": "Veranstaltungsorte",
        "View": "Ansehen",
        "View Full Profile": "Vollst\u00e4ndiges Profil anzeigen",
        "Visit Facebook": "Facebook",
        "Visit Instagram": "Instagram",
        "Visit Twitter": "Twitter",
        "WARNING": "Swing",
        "Warning": "Warnung",
        "Way to go!": "Gut gemacht!",
        "We're excited!": "Wir sind aufgeregt!",
        "Website": "Website",
        "Website (optional)": "Website (optional)",
        "Welcome Blurb": "Begr\u00fc\u00dfungstext",
        "Welcome back!": "Willkommen zur\u00fcck!",
        "Welcome to Make Music": "Willkommen bei der F\u00eate de la Musique Potsdam",
        "Welcome, let's get started!": "Willkommen, lass uns loslegen!",
        "What kind of music is this venue looking to host?": "F\u00fcr welche Musikrichtung w\u00fcrde der Veranstaltungsort gerne zur Verf\u00fcgung stehen?",
        "What kind of music will this performer play?": "Welche Musikrichtung wird der Musikact spielen?",
        "Wheelchair Accessible": "Rollstuhlzug\u00e4nglich",
        "When is this performer available? Include your full range, performances will be scheduled individually.": "Wann ist dieser Musikact verf\u00fcgbar? Gib dein gesamtes Zeitfenster an, die Performances werden individuell geplant.",
        "When is this venue available to host? Include your full range, performances will be scheduled individually.": "Wann kann dieser Veranstaltungsort bespielt werden? Gib deine gesamtes Zeitfenster an, die Performances werden individuell geplant.",
        "When users create an account for the first time, they must agree to the \"Terms and Conditions\" to use the website. Paste your city's Terms and Conditions here. If you are using language from a previous year, please check that the dates, city names, and URLs are all up to date. This form supports HTML tags.": "Wenn User zum ersten Mal ein Konto erstellen, m\u00fcssen sie den \"Allgemeinen Gesch\u00e4ftsbedingungen\" zustimmen, um die Website nutzen zu k\u00f6nnen. F\u00fcge die Allgemeinen Gesch\u00e4ftsbedingungen deiner Stadt hier ein. Wenn du eine Sprache aus einem fr\u00fcheren Jahr verwendest, \u00fcberpr\u00fcfe bitte, ob die Daten, St\u00e4dtenamen und URLs auf dem neuesten Stand sind. Dieses Formular unterst\u00fctzt HTML-Tags.",
        "When would you like this performance?": "Wann soll die Performance stattfinden?",
        "When would you like to perform?": "Wann m\u00f6chtest du gerne auftreten?",
        "Who is the contact person for this event?": "Wer ist die Kontaktperson f\u00fcr diese Veranstaltung?",
        "Who is the contact person for this performer?": "Wer ist die Kontaktperson f\u00fcr diesen Musicact?",
        "Who is the contact person for this venue?": "Wer ist die Kontaktperson dieses Veranstaltungsortes?",
        "Why do you think this performer would be a good fit?": "Warum glaubst, dass dieser Musikact gut zu der Location passen w\u00fcrde?",
        "Why is this location a good fit for you?": "Warum passt du gut zu dieser Location?",
        "Will electricity be used for performances at this venue?": "Wird f\u00fcr die Performances an diesem Ort Strom verwendet?",
        "World": "World",
        "Write a few words about your venue.": "Schreibe ein paar Worte \u00fcber deinen Veranstaltungsort.",
        "Write a few words about yourself and your music.": "Schreibe ein paar Worte \u00fcber dich und deine Musik.",
        "Yes": "Ja",
        "Yes, go ahead": "Ja, nur zu",
        "Yes, if necessary": "Ja, wenn n\u00f6tig",
        "Yes, if necessary, at": "Ja, falls erforderlich, unter",
        "Yes, let them play!": "Ja, lass sie spielen!",
        "You already have a confirmed event that overlaps with this performance time.": "Der Musikact hat bereits eine best\u00e4tigte Veranstaltung, die sich mit dieser Die Performancezeit \u00fcberschneidet.",
        "You are about to accept this performance request.": "Du bist dabei deine Performance zu best\u00e4tigen.",
        "You are required to indicate the user that is receiving ownership of this artist profile.": "Du musst den User angeben, der das Eigentum an diesem K\u00fcnstlerprofil erh\u00e4lt.",
        "You are required to indicate the user that is receiving ownership of this venue profile.": "Du musst den User angeben, der das Eigentum an diesem Veranstaltungsort-Profil erh\u00e4lt.",
        "You can find and book performers through our platform. Check out the <a href=\"/find\">Find Performers</a> page to get started.": "\u00dcber unsere Plattform kannst du Musikacts finden und buchen. Besuche die Seite <a href=\"/find\">Musikacts finden</a>, um mit der Suche zu beginnen.",
        "You created an event!": "Du hast ein Event erstellt!",
        "You have exceeded the rate limit allotted. Try again later.": "Du hast die zugeteilte Satzgrenze \u00fcberschritten. Versuch es sp\u00e4ter noch einmal.",
        "You have no performances.": "Du hast keine eine best\u00e4tigte Performances.",
        "You may choose an alternate font family.": "Du kannst eine alternative Schriftfamilie w\u00e4hlen.",
        "You may edit your site's welcome text here. This form supports HTML tags.": "Hier kannst du den Begr\u00fc\u00dfungstext deiner Website bearbeiten. Dieses Formular unterst\u00fctzt HTML-Tags.",
        "You must accept the terms and conditions in order to proceed": "Du musst die Bedingungen und Konditionen akzeptieren, um fortzufahren",
        "You must confirm your email in order to access this feature.": "Du musst deine E-Mail best\u00e4tigen, um auf diese Funktion zugreifen zu k\u00f6nnen.",
        "You must enter a email address": "Du musst eine Emailadresse angeben.",
        "You must enter a location": "Du musst einen Ort angeben.",
        "You must enter a name": "Du musst einen Namen angeben.",
        "You must enter a phone number": "Du musst eine Telefonnummer angeben.",
        "You must enter performer's bio": "Du musst die Biographie des Musikacts eingeben",
        "You must enter the performer's description": "Du musst die Beschreibung des Musikacts eingeben",
        "You must enter the performer's name": "Du musst den Namen des Musikacts eingeben",
        "You must enter the venue's description": "Du musst die Beschreibung des Veranstaltungsortes eingeben.",
        "You must enter the venue's name": "Du musst den Namen des Veranstaltungsortes eingeben",
        "You must enter the venue's notes for performers": "Du musst die Notizen des Veranstaltungsortes f\u00fcr die Musikacts eingeben",
        "You must pick 1 to 3 genres": "W\u00e4hle 1 bis 3 Musikrichtungen aus.",
        "You must provide address and zip_code when the venue allows in person events.": "Du musst Adresse und Postleitzahl angeben, wenn der Veranstaltungsort pers\u00f6nliche Veranstaltungen zul\u00e4sst.",
        "You must select a neighborhood": "Du musst eine Nachbarschaft ausw\u00e4hlen",
        "You must select a venue type": "Du musst einen Veranstaltungsorttyp ausw\u00e4hlen",
        "You must select an option": "Du musst eine Option ausw\u00e4hlen",
        "You must select one": "Du musst eine ausw\u00e4hlen",
        "You must specify the district to add.": "Du musst den hinzuzuf\u00fcgenden Stadtteil angeben.",
        "You must specify the district to delete.": "Du musst den zu l\u00f6schenden Stadtteil angeben.",
        "You must specify the neighbourhood to delete.": "Du musst die zu l\u00f6schende Nachbarschaft angeben.",
        "You must specify the neighbourhoods to add": "Du musst die hinzuzuf\u00fcgenden Nachbarschaften angeben",
        "You'll fill out a profile and be able to see who is available that might be a good fit to plan a performance with.": "Du legst ein Profil als Musikact oder Veranstaltungsort an und kannst im Anschluss \u00fcber unser Matchmaking-Tool mit verf\u00fcgbaren Veranstaltungsorten bzw. Musikacts in Kontakt treten.",
        "You're really doing it": "Du machst es wirklich.",
        "YouTube": "YouTube",
        "YouTube URL": "YouTube URL",
        "YouTube Video (optional)": "YouTube Video (optional)",
        "Your Availability": "Deine Verf\u00fcgbarkeit",
        "Your Event Profile has been created.<br/><br/>The City Admin will be in touch to confirm performances or follow up if needed. This may take a little while, but don't worry, we will get back to you!": "Dein Veranstaltungsprofil wurde erstellt. <br/><br/>Der City Admin wird sich mit dir in Verbindung setzen, um die Performances zu best\u00e4tigen oder bei Bedarf nachzufassen. Dies kann eine Weile dauern, aber keine Sorge, wir werden uns bei dir melden!",
        "Your Performance Time": "Die Zeit deiner Performance",
        "Your Performer Profile has been created.": "Dein Musikact-Profil wurde erstellt.",
        "Your Performer Profile has been updated.": "Dein Musikact-Profil wurde aktualisiert.",
        "Your Venue Profile has been updated.": "Dein Profil f\u00fcr einen Veranstaltungsort wurde aktualisiert.",
        "Your confirmation token has expired. Check your inbox for a new confirmation email.": "Dein Best\u00e4tigungstoken ist abgelaufen. \u00dcberpr\u00fcfe deinen Posteingang auf eine neue Best\u00e4tigungs-E-Mail.",
        "Your home neighborhood (optional)": "Ihre Wohngegend (optional)",
        "Your performance request has been sent to the performer's inbox.": "Deine Performance-Anfrage wurde an den Musikact geschickt.",
        "Your performance request has been sent to the venue's inbox.": "Deine Performance-Anfrage wurde an den Veranstaltungsort geschickt.",
        "Your profile will be deleted, along with all uploaded images and created events": "Dein Profil wird gel\u00f6scht, sowie alle hochgeladenen Bilder und erstellten Veranstaltungen.",
        "Your site is currently open to the public": "Deine Seite ist momentan f\u00fcr die \u00d6ffentlichkeit sichtbar.",
        "Youtube URL": "YouTube URL",
        "You\u2019re really doing it! Your venue profile has been created.": "Du machst es wirklich! Dein Veranstaltungsort-Profil wurde erstellt.",
        "ZIP": "Postfach",
        "Zip": "Postfach",
        "address field can not be empty": "Adressfeld kann nicht leer sein",
        "artist": "artist",
        "by %(profile_name)s": "von %(profile_name)s",
        "by City Admin": "vom City Admin",
        "by You": "von dir",
        "cancelled": "Die Veranstaltung wurde abgesagt.",
        "confirmed": "best\u00e4tigt",
        "delete this event": "Dieses Event l\u00f6schen",
        "delete this profile": "Dieses Profil l\u00f6schen.",
        "does not match the password": "Stimmt nicht mit dem Passwort \u00fcberein",
        "email address not found": "E-Mail-Adresse nicht gefunden",
        "have sent and received no requests": "keine Anfragen gesendet und erhalten",
        "haven't replied to requests": "haben nicht auf Anfragen geantwortet",
        "here": "hier",
        "in person": "Pers\u00f6nlich / Analog",
        "in person, but will move online if necessary": "Analog, wird aber wenn n\u00f6tig zur Online-Veranstaltung",
        "include up to 3 SoundCloud tracks on your profile": "F\u00fcge deinem Profil bis zu 3 SoundCloud-Tracks hinzu",
        "include up to 3 YouTube videos on your profile": "F\u00fcge deinem Profil bis zu 3 YouTube-Videos hinzu",
        "minutes": "Minuten",
        "new": "neu",
        "online only": "Nur online verf\u00fcgbar",
        "password must be at least 8 characters": "Passwort muss aus mindestens acht Zeichen bestehen",
        "pending": "ausstehend",
        "pending city approval": "Genehmigung durch die Stadt steht aus",
        "performers": "Musicacts",
        "playing at": "spielt bei",
        "please enter a street address": "Gib bitte einen Stra\u00dfennamen ein.",
        "please enter a valid URL": "Gib bitte einen g\u00fcltigen Namen ein.",
        "please enter a valid name": "Gib bitte einen g\u00fcltigen Namen ein.",
        "please enter a valid phone number": "Gib bitte eine g\u00fcltige Telefonnummer ein. (Format bitte wie folgt 176 ohne die 0 am Anfang)",
        "please enter a valid postal code": "Gib bitte eine g\u00fcltige Postleitzahl ein.",
        "please enter a valid url": "Gib bitte einen g\u00fcltigen Namen ein",
        "postal code can not be empty": "Postleitzahl kann nicht leer sein",
        "remove": "entfernen",
        "says that no amplification is permitted at their venue. Are you sure you want to continue?": "sagt, dass an dem Veranstaltungsort keine Verst\u00e4rkung erlaubt ist. Bist du sicher, dass du fortfahren willst?",
        "says that no amplification is permitted at their venue. But your performer profile says you require electricity and a PA. Are you sure you want to continue?": "sagt, dass an dem Veranstaltungsort keine Verst\u00e4rkung erlaubt ist. Aber dein Musikact-Profil besagt, dass du Strom und eine PA ben\u00f6tigst. Bist du sicher, dass du fortfahren willst?",
        "says that no amplification is permitted at their venue. But your performer profile says you require electricity. Are you sure you want to continue?": "sagt, dass an dem Veranstaltungsort keine Verst\u00e4rkung erlaubt ist. Aber dein Musikact-Profil besagt, dass du Strom ben\u00f6tigst. Bist du sicher, dass du fortfahren willst?",
        "says that they can supply electricity, but not a PA. Your performer profile says you require a PA from the venue. Are you sure you want to continue?": "sagt, dass sie Strom liefern k\u00f6nnen, aber keine PA. Du hast angegeben, dass du vom Veranstaltungsort eine PA ben\u00f6tigst. Bist du sicher, dass du fortfahren willst?",
        "says that they cannot supply electricity. But your performer profile says you require electricity and a PA from the venue. Are you sure you want to continue?": "sagt, dass sie keinen Strom liefern k\u00f6nnen. Du hast angegeben, dass du vom Veranstaltungsort Strom und eine PA ben\u00f6tigst. Bist du sicher, dass du fortfahren willst?",
        "says that they cannot supply electricity. But your performer profile says you require electricity from the venue. Are you sure you want to continue?": "sagt, dass sie keinen Strom liefern k\u00f6nnen. Du hast angegeben, dass du vom Veranstaltungsort Strom ben\u00f6tigst. Bist du sicher, dass du fortfahren willst?",
        "says they need electricity and a PA from the venue. But your venue profile says amplification is not allowed. Are you sure you want to continue?": "sagt, dass sie Strom und PA vom Veranstaltungsort ben\u00f6tigen. Du hast als Veranstalter angegeben, dass ein Verst\u00e4rker nicht erlaubt ist. Bist du sicher, dass du fortfahren willst?",
        "says they need electricity and a PA from the venue. But your venue profile says you can't supply a PA. Are you sure you want to continue?": "sagt, dass sie Strom und PA vom Veranstaltungsort ben\u00f6tigen. Du hast als Veranstalter angegeben, dass du keine PA zur Verf\u00fcgung hast. Bist du sicher, dass du fortfahren willst?",
        "says they need electricity and a PA from the venue. But your venue profile says you can't supply either one. Are you sure you want to continue?": "sagt, dass sie Strom und PA vom Veranstaltungsort ben\u00f6tigen. Du hast als Veranstalter angegeben, dass du beides nicht zur Verf\u00fcgung stellen kannst. Bist du sicher, dass du fortfahren willst?",
        "says they need electricity from the venue. But your venue profile says amplification is not allowed. Are you sure you want to continue?": "sagt, dass sie Strom vom Veranstaltungsort ben\u00f6tigen. Du hast als Veranstalter angegeben, dass ein Verst\u00e4rker nicht erlaubt ist. Bist du sicher, dass du fortfahren willst?",
        "says they need electricity from the venue. But your venue profile says you can't supply electricity. Are you sure you want to continue?": "sagt, dass sie Strom vom Veranstaltungsort ben\u00f6tigen. Du hast als Veranstalter angegeben, dass du kein Strom zur Verf\u00fcgung stellen kannst. Bist du sicher, dass du fortfahren willst?",
        "says they will use electricity for their performance. But your venue profile says amplification is not allowed. Are you sure you want to continue?": "sagt, dass sie f\u00fcr ihre Performance Strom verwenden. Du hast als Veranstalter angegeben, dass ein Verst\u00e4rker nicht erlaubt ist. Bist du sicher, dass du fortfahren willst?",
        "total": "gesamt",
        "venue": "Veranstaltungsort",
        "venues": "Veranstaltungsorte"
    },
    "el_GR": {
        "%(attr_name)s is not a valid attribute of %(obj)s": "%(attr_name)s is not a valid attribute of %(obj)s",
        "A password was expected, but none was provided.": "A password was expected, but none was provided.",
        "Active as:": "Active as:",
        "Afrobeat": "Afrobeat",
        "Already have a performance planned and not interested in additional matches? This option is for you.": "Already have a performance planned and not interested in additional matches? This option is for you.",
        "Americana / Bluegrass": "Americana / Bluegrass",
        "An OAuth2 related error has occurred.": "An OAuth2 related error has occurred.",
        "An error has occurred!": "An error has occurred!",
        "An internal error prevented the request from being handled.": "An internal error prevented the request from being handled.",
        "An unhandled exception has occurred!": "An unhandled exception has occurred!",
        "Artist": "Artist",
        "Blues": "Blues",
        "Brass": "Brass",
        "Cabaret": "Cabaret",
        "Cannot serialize; no default schema has been defined for %(classname)s": "Cannot serialize; no default schema has been defined for %(classname)s",
        "Celtic": "Celtic",
        "Choral": "Choral",
        "City": "City",
        "Classical": "Classical",
        "Content-type header must be one of %(mime_types)s": "Content-type header must be one of %(mime_types)s",
        "Could not create a OAuth token. Perhaps missing scopes?": "Could not create a OAuth token. Perhaps missing scopes?",
        "Country": "Country",
        "Delete this user account.": "Delete this user account.",
        "Early Music": "Early Music",
        "East Asian": "East Asian",
        "Eastern European": "Eastern European",
        "Electronic": "Electronic",
        "Email": "Email",
        "Experimental": "Experimental",
        "Find Venues": "Find Venues",
        "Folk": "Folk",
        "Funk": "Funk",
        "Gospel": "Gospel",
        "Health check FAILED due to 'SELECT NOW()' raising an exception.": "Health check FAILED due to 'SELECT NOW()' raising an exception.",
        "Highlife": "Highlife",
        "Hip-Hop": "Hip-Hop",
        "I can provide both electricity and a PA": "\u039c\u03c0\u03bf\u03c1\u03ce \u03bd\u03b1 \u03c0\u03c1\u03bf\u03bc\u03b7\u03b8\u03b5\u03cd\u03bf\u03c5\u03bd \u03b7\u03bb\u03b5\u03ba\u03c4\u03c1\u03b9\u03ba\u03ae \u03b5\u03bd\u03ad\u03c1\u03b3\u03b5\u03b9\u03b1 \u03ba\u03b1\u03b9 \u03a1\u0391",
        "I can provide electricity, but not a PA": "\u039c\u03c0\u03bf\u03c1\u03ce \u03bd\u03b1 \u03c0\u03c1\u03bf\u03bc\u03b7\u03b8\u03b5\u03cd\u03bf\u03c5\u03bd \u03b7\u03bb\u03b5\u03ba\u03c4\u03c1\u03b9\u03ba\u03ae \u03b5\u03bd\u03ad\u03c1\u03b3\u03b5\u03b9\u03b1",
        "I can't provide electricity or a PA, but performers may bring their own": "\u0394\u03b5\u03bd \u03bc\u03c0\u03bf\u03c1\u03ce \u03bd\u03b1 \u03c0\u03c1\u03bf\u03bc\u03b7\u03b8\u03b5\u03cd\u03bf\u03c5\u03bd \u03b7\u03bb\u03b5\u03ba\u03c4\u03c1\u03b9\u03ba\u03ae \u03b5\u03bd\u03ad\u03c1\u03b3\u03b5\u03b9\u03b1",
        "I need electricity and a PA from the venue, or else I can't perform": "\u03a7\u03c1\u03b5\u03b9\u03ac\u03b6\u03bf\u03bc\u03b1\u03b9 \u03b7\u03bb\u03b5\u03ba\u03c4\u03c1\u03b9\u03ba\u03ae\u03c2 \u03b5\u03bd\u03ad\u03c1\u03b3\u03b5\u03b9\u03b1\u03c2 \u03ba\u03b1\u03b9 \u03a1\u0391 \u03b1\u03c0\u03cc \u03c4\u03bf \u03c7\u03ce\u03c1\u03bf",
        "I need electricity from the venue, or else I can't perform": "\u03a7\u03c1\u03b5\u03b9\u03ac\u03b6\u03bf\u03bc\u03b1\u03b9 \u03b7\u03bb\u03b5\u03ba\u03c4\u03c1\u03b9\u03ba\u03ae \u03b5\u03bd\u03ad\u03c1\u03b3\u03b5\u03b9\u03b1 \u03b1\u03c0\u03cc \u03c4\u03bf \u03c7\u03ce\u03c1\u03bf",
        "I need electricity, but I can provide my own batteries or generator if necessary": "\u0398\u03b1 \u03c6\u03ad\u03c1\u03c9 \u03c4\u03b7 \u03b4\u03b9\u03ba\u03ae \u03bc\u03bf\u03c5 \u03c0\u03b7\u03b3\u03ae \u03b7\u03bb\u03b5\u03ba\u03c4\u03c1\u03b9\u03ba\u03ae\u03c2 \u03b5\u03bd\u03ad\u03c1\u03b3\u03b5\u03b9\u03b1\u03c2",
        "I will not use amplification (no amps, mics, or speakers) and I don't need electricity": "\u0395\u03b3\u03ce \u03b4\u03b5\u03bd \u03b8\u03b1 \u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03bf\u03cd\u03bd \u03b7\u03bb\u03b5\u03ba\u03c4\u03c1\u03b9\u03ba\u03ae \u03b5\u03bd\u03ad\u03c1\u03b3\u03b5\u03b9\u03b1",
        "Indian": "Indian",
        "Indie-Folk": "Indie-Folk",
        "Indie-Rock": "Indie-Rock",
        "Invalid Basic credentials in Authorization header. The OAuth client ID and secret must be provided to create a user.": "Invalid Basic credentials in Authorization header. The OAuth client ID and secret must be provided to create a user.",
        "Invalid data type": "Invalid data type",
        "Invalid email address.": "Invalid email address.",
        "Invalid value": "Invalid value",
        "Jazz": "Jazz",
        "Kids": "Kids",
        "Latin": "Latin",
        "MPB": "MPB",
        "Metal": "Metal",
        "Middle Eastern": "Middle Eastern",
        "Missing data for required field.": "Missing data for required field.",
        "Musical Theater": "Musical Theater",
        "Native American": "Native American",
        "No amplification of any kind (amps, mics, or speakers) is permitted at this site": "\u0391\u03c1\u03b9\u03b8 \u03b5\u03bd\u03af\u03c3\u03c7\u03c5\u03c3\u03b7 \u03b5\u03c0\u03b9\u03c4\u03c1\u03ad\u03c0\u03b5\u03c4\u03b1\u03b9 \u03c3\u03b5 \u03b1\u03c5\u03c4\u03ae\u03bd \u03c4\u03b7\u03bd \u03c4\u03bf\u03c0\u03bf\u03b8\u03b5\u03c3\u03af\u03b1",
        "No such schema %(classname)s in package %(path)s exists!": "No such schema %(classname)s in package %(path)s exists!",
        "Opera": "Opera",
        "Other": "Other",
        "Password Reset": "Password Reset",
        "Performance will be canceled": "\u0398\u03b1 \u03b1\u03ba\u03c5\u03c1\u03c9\u03b8\u03b5\u03af \u03c3\u03b5 \u03c0\u03b5\u03c1\u03af\u03c0\u03c4\u03c9\u03c3\u03b7 \u03b2\u03c1\u03bf\u03c7\u03ae\u03c2",
        "Performance will go on as scheduled": "\u0392\u03c1\u03bf\u03c7\u03ae \u03ae \u03b7\u03bb\u03b9\u03bf\u03c6\u03ac\u03bd\u03b5\u03b9\u03b1",
        "Performance will move to a nearby sheltered place": "\u0395\u03ac\u03bd \u03b2\u03c1\u03ad\u03be\u03b5\u03b9, \u03b8\u03b1 \u03bc\u03b5\u03c4\u03b1\u03ba\u03b9\u03bd\u03b7\u03b8\u03bf\u03cd\u03bc\u03b5 \u03c3\u03b5 \u03c3\u03ba\u03b5\u03c0\u03b1\u03c3\u03c4\u03cc \u03bc\u03ad\u03c1\u03bf\u03c2",
        "Performances": "Performances",
        "Pop": "Pop",
        "Punk": "Punk",
        "R&B": "R&B",
        "Ragtime": "Ragtime",
        "Reggae": "Reggae",
        "Religious": "Religious",
        "Request a password reset email.": "Request a password reset email.",
        "Requested scopes do not match with scopes the OAuth client and user is authorized for.": "Requested scopes do not match with scopes the OAuth client and user is authorized for.",
        "Rock": "Rock",
        "Samba": "Samba",
        "Singer / Songwriter": "Singer / Songwriter",
        "Ska": "Ska",
        "Soul": "Soul",
        "Standards": "Standards",
        "Swing": "Swing",
        "That email is already in use.": "That email is already in use.",
        "The SELECT query for the database succeeded, but did not return a valid value.": "The SELECT query for the database succeeded, but did not return a valid value.",
        "The provided password was invalid.": "The provided password was invalid.",
        "The provided query arguments contain an invalid key and/or value.": "The provided query arguments contain an invalid key and/or value.",
        "The requested resource could not be located.": "The requested resource could not be located.",
        "The requested resource owner could not be determined.": "The requested resource owner could not be determined.",
        "The reset token has expired.": "The reset token has expired.",
        "The specified user account already exists.": "The specified user account already exists.",
        "The token you are using is not attached to a user account.": "The token you are using is not attached to a user account.",
        "The user with that email and password could not be found.": "The user with that email and password could not be found.",
        "This OAuth client is not allowed to create users.": "This OAuth client is not allowed to create users.",
        "Unknown object class": "Unknown object class",
        "Unsupported object type": "Unsupported object type",
        "Unsupported value": "Unsupported value",
        "User data and profile information.": "User data and profile information.",
        "Venue": "Venue",
        "Welcome, let's get started!": "Welcome, let's get started!",
        "World": "World",
        "You'll fill out a profile and be able to see who is available that might be a good fit to plan a performance with.": "You'll fill out a profile and be able to see who is available that might be a good fit to plan a performance with."
    },
    "en_GB": {
        "%(attr_name)s is not a valid attribute of %(obj)s": "%(attr_name)s is not a valid attribute of %(obj)s",
        "A password was expected, but none was provided.": "A password was expected, but none was provided.",
        "Accent Color": "Accent Colour",
        "Active as:": "Active as:",
        "Add neighbourhoods to this city.": "Add neighbourhoods to this city.",
        "Add neighbourhoods to this district.": "Add neighbourhoods to this district.",
        "Afrobeat": "Afrobeat",
        "After going public, you will no longer be able to change your site's neighborhood names, venue types, genres, or time increments.<br/><br/>Are you sure you want to go public now?": "After going public, you will no longer be able to change your site's neighbourhood names, venue types, genres, or time increments.<br/><br/>Are you sure you want to go public now?",
        "Already have a performance planned and not interested in additional matches? This option is for you.": "Already have a performance planned and not interested in additional matches? This option is for you.",
        "Americana / Bluegrass": "Americana / Bluegrass",
        "An OAuth2 related error has occurred.": "An OAuth2 related error has occurred.",
        "An error has occurred!": "An error has occurred!",
        "An internal error prevented the request from being handled.": "An internal error prevented the request from being handled.",
        "An unhandled exception has occurred!": "An unhandled exception has occurred!",
        "Artist": "Artist",
        "Blues": "Blues",
        "Brass": "Brass",
        "Cabaret": "Cabaret",
        "Cannot serialize; no default schema has been defined for %(classname)s": "Cannot serialize; no default schema has been defined for %(classname)s",
        "Celtic": "Celtic",
        "Choral": "Choral",
        "City": "City",
        "Classical": "Classical",
        "Colors": "Colours",
        "Content-type header must be one of %(mime_types)s": "Content-type header must be one of %(mime_types)s",
        "Could not create a OAuth token. Perhaps missing scopes?": "Could not create a OAuth token. Perhaps missing scopes?",
        "Country": "Country",
        "Customize the platform colors, fonts and logo.": "Customize the platform colours, fonts and logo.",
        "Delete this neighbourhood.": "Delete this neighbourhood.",
        "Delete this user account.": "Delete this user account.",
        "Deletes neighbourhoods from this city.": "Deleted neighbourhoods from this city.",
        "District is already existed": "District already exists",
        "Districts & Neighborhood": "Districts & Neighbourhoods",
        "Early Music": "Early Music",
        "East Asian": "East Asian",
        "Eastern European": "Eastern European",
        "Edit this neighbourhood.": "Edit this neighbourhood.",
        "Electronic": "Electronic",
        "Email": "Email",
        "Enter Neighborhood Name": "Enter Neighbourhood Name",
        "Experimental": "Experimental",
        "Find Venues": "Find Venues",
        "Folk": "Folk",
        "Funk": "Funk",
        "Gospel": "Gospel",
        "Health check FAILED due to 'SELECT NOW()' raising an exception.": "Health check FAILED due to 'SELECT NOW()' raising an exception.",
        "Highlife": "Highlife",
        "Hip-Hop": "Hip-Hop",
        "I can provide both electricity and a PA": "I can provide both electricity and a PA",
        "I can provide electricity, but not a PA": "I can provide electricity, but not a PA",
        "I can't provide electricity or a PA, but performers may bring their own": "I can't provide electricity or a PA, but performers may bring their own",
        "I need electricity and a PA from the venue, or else I can't perform": "I need electricity and a PA from the venue, or else I can't perform",
        "I need electricity from the venue, or else I can't perform": "I need electricity from the venue, or else I can't perform",
        "I need electricity, but I can provide my own batteries or generator if necessary": "I need electricity, but I can provide my own batteries or generator if necessary",
        "I will not use amplification (no amps, mics, or speakers) and I don't need electricity": "I will not use amplification (no amps, mics, or speakers) and I don't need electricity",
        "I'd love to have electricity and a PA provided, but I'm flexible and will make it work regardless": "I'd love to have electricity and a PA provided, but I'm flexible and will make it work regardless",
        "Indian": "Indian",
        "Indie-Folk": "Indie-Folk",
        "Indie-Rock": "Indie-Rock",
        "Invalid Basic credentials in Authorization header. The OAuth client ID and secret must be provided to create a user.": "Invalid Basic credentials in Authorization header. The OAuth client ID and secret must be provided to create a user.",
        "Invalid data type": "Invalid data type",
        "Invalid email address": "Invalid email address",
        "Invalid email address.": "Invalid email address.",
        "Invalid value": "Invalid value",
        "Jazz": "Jazz",
        "Keep in mind that changes to color can affect usability. Test all pages to make sure color combinations still communicate the functionality of the site.": "Keep in mind that changes to colour can affect usability. Test all pages to make sure colour combinations still communicate the functionality of the site.",
        "Kids": "Kids",
        "Latin": "Latin",
        "MPB": "MPB",
        "Metal": "Metal",
        "Middle Eastern": "Middle Eastern",
        "Missing data for required field.": "Missing data for required field.",
        "Musical Theater": "Musical Theatre",
        "Native American": "Native American",
        "Neighborhood": "Neighbourhood",
        "Neighborhood is already existed": "Neighbourhood already exists",
        "No amplification of any kind (amps, mics, or speakers) is permitted at this site": "No amplification of any kind (amps, mics, or speakers) is permitted at this site",
        "No such schema %(classname)s in package %(path)s exists!": "No such schema %(classname)s in package %(path)s exists!",
        "Opera": "Opera",
        "Other": "Other",
        "Password Reset": "Password Reset",
        "Performance will be canceled": "Performance will be cancelled",
        "Performance will go on as scheduled": "Performance will go on as scheduled",
        "Performance will move to a nearby sheltered place": "Performance will move to a nearby sheltered place",
        "Performances": "Performances",
        "Pop": "Pop",
        "Primary Buttons & Highlight Color": "Primary Buttons & Highlight Colour",
        "Punk": "Punk",
        "R&B": "R&B",
        "Ragtime": "Ragtime",
        "Reggae": "Reggae",
        "Religious": "Religious",
        "Request a password reset email.": "Request a password reset email.",
        "Requested scopes do not match with scopes the OAuth client and user is authorized for.": "Requested scopes do not match with scopes the OAuth client and user is authorized for.",
        "Rock": "Rock",
        "Samba": "Samba",
        "Select neighborhood": "Select neighbourhood",
        "Set up your city\u2019s districts and neighborhoods. <span accent>Once the platform goes live for participants, you can not edit existing districts or neighborhoods, but you can add new ones.</span>": "Set up your city\u2019s districts and neighbourhoods. <span accent>Once the platform goes live for participants, you can not edit existing districts or neighbourhoods, but you can add new ones.</span>",
        "Singer / Songwriter": "Singer / Songwriter",
        "Ska": "Ska",
        "Soul": "Soul",
        "Standards": "Standards",
        "Swing": "Swing",
        "That email is already in use.": "That email is already in use.",
        "The SELECT query for the database succeeded, but did not return a valid value.": "The SELECT query for the database succeeded, but did not return a valid value.",
        "The performance has been canceled!": "The performance has been cancelled!",
        "The provided district cannot be deleted until all neighbourhoods have been removed from it.": "The provided district cannot be deleted until all neighbourhoods have been removed from it.",
        "The provided neighbourhood cannot be added without specifying a district unless all districts have been removed first.": "The provided neighbourhood cannot be added without specifying a district unless all districts have been removed first.",
        "The provided password was invalid.": "The provided password was invalid.",
        "The provided query arguments contain an invalid key and/or value.": "The provided query arguments contain an invalid key and/or value.",
        "The requested resource could not be located.": "The requested resource could not be located.",
        "The requested resource owner could not be determined.": "The requested resource owner could not be determined.",
        "The reset token has expired.": "The reset token has expired.",
        "The specified user account already exists.": "The specified user account already exists.",
        "The token you are using is not attached to a user account.": "The token you are using is not attached to a user account.",
        "The user with that email and password could not be found.": "The user with that email and password could not be found.",
        "This OAuth client is not allowed to create users.": "This OAuth client is not allowed to create users.",
        "Unknown object class": "Unknown object class",
        "Unsupported object type": "Unsupported object type",
        "Unsupported value": "Unsupported value",
        "User data and profile information.": "User data and profile information.",
        "Venue": "Venue",
        "Welcome, let's get started!": "Welcome, let's get started!",
        "World": "World",
        "You must select a neighborhood": "You must select a neighbourhood",
        "You must specify the neighbourhood to delete.": "You must specify the neighbourhood to delete.",
        "You must specify the neighbourhoods to add": "You must specify the neighbourhoods to add",
        "You'll fill out a profile and be able to see who is available that might be a good fit to plan a performance with.": "You'll fill out a profile and be able to see who is available that might be a good fit to plan a performance with.",
        "Your home neighborhood (optional)": "Your home neighbourhood (optional)"
    },
    "en_US": {
        "%(attr_name)s is not a valid attribute of %(obj)s": "%(attr_name)s is not a valid attribute of %(obj)s",
        "A password was expected, but none was provided.": "A password was expected, but none was provided.",
        "Add neighbourhoods to this city.": "Add neighborhoods to this city.",
        "Add neighbourhoods to this district.": "Add neighborhoods to this district.",
        "Afrobeat": "Afrobeat",
        "Americana / Bluegrass": "Americana / Bluegrass",
        "An OAuth2 related error has occurred.": "An OAuth2 related error has occurred.",
        "An error has occurred!": "An error has occurred!",
        "An internal error prevented the request from being handled.": "An internal error prevented the request from being handled.",
        "An unhandled exception has occurred!": "An unhandled exception has occurred!",
        "Artist": "Artist",
        "Blues": "Blues",
        "Brass": "Brass",
        "Cabaret": "Cabaret",
        "Cancelled": "Canceled",
        "Cannot serialize; no default schema has been defined for %(classname)s": "Cannot serialize; no default schema has been defined for %(classname)s",
        "Celtic": "Celtic",
        "Choral": "Choral",
        "City": "City",
        "Classical": "Classical",
        "Content-type header must be one of %(mime_types)s": "Content-type header must be one of %(mime_types)s",
        "Could not create a OAuth token. Perhaps missing scopes?": "Could not create a OAuth token. Perhaps missing scopes?",
        "Country": "Country",
        "Delete this neighbourhood.": "Delete this neighborhood.",
        "Delete this user account.": "Delete this user account.",
        "Deletes neighbourhoods from this city.": "Deletes neighborhoods from this city.",
        "District is already existed": "District already exists",
        "Early Music": "Early Music",
        "East Asian": "East Asian",
        "Eastern European": "Eastern European",
        "Edit this neighbourhood.": "Edit this neighborhood",
        "Electronic": "Electronic",
        "Event Cancelled": "Event Canceled",
        "Event has been cancelled": "Event has been canceled",
        "Experimental": "Experimental",
        "Folk": "Folk",
        "Funk": "Funk",
        "Gospel": "Gospel",
        "Health check FAILED due to 'SELECT NOW()' raising an exception.": "Health check FAILED due to 'SELECT NOW()' raising an exception.",
        "Highlife": "Highlife",
        "Hip-Hop": "Hip-Hop",
        "I can provide both electricity and a PA": "I can provide both electricity and a PA",
        "I can provide electricity, but not a PA": "I can provide electricity, but not a PA",
        "I can't provide electricity or a PA, but performers may bring their own": "I can't provide electricity or a PA, but performers may bring their own",
        "I need electricity and a PA from the venue, or else I can't perform": "I need electricity and a PA from the venue, or else I can't perform",
        "I need electricity from the venue, or else I can't perform": "I need electricity from the venue, or else I can't perform",
        "I need electricity, but I can provide my own batteries or generator if necessary": "I need electricity, but I can provide my own batteries or generator if necessary",
        "I will not use amplification (no amps, mics, or speakers) and I don't need electricity": "I will not use amplification (no amps, mics, or speakers) and I don't need electricity",
        "I'd love to have electricity and a PA provided, but I'm flexible and will make it work regardless": "I'd love to have electricity and a PA provided, but I'm flexible and will make it work regardless",
        "Indian": "Indian",
        "Indie-Folk": "Indie-Folk",
        "Indie-Rock": "Indie-Rock",
        "Invalid Basic credentials in Authorization header. The OAuth client ID and secret must be provided to create a user.": "Invalid Basic credentials in Authorization header. The OAuth client ID and secret must be provided to create a user.",
        "Invalid data type": "Invalid data type",
        "Invalid email address.": "Invalid email address.",
        "Invalid value": "Invalid value",
        "Jazz": "Jazz",
        "Kids": "Kids",
        "Latin": "Latin",
        "MPB": "MPB",
        "Metal": "Metal",
        "Middle Eastern": "Middle Eastern",
        "Missing data for required field.": "Missing data for required field.",
        "Musical Theater": "Musical Theater",
        "Native American": "Native American",
        "Neighborhood is already existed": "Neighborhood already exists",
        "No amplification of any kind (amps, mics, or speakers) is permitted at this site": "No amplification of any kind (amps, mics, or speakers) is permitted at this site",
        "No such schema %(classname)s in package %(path)s exists!": "No such schema %(classname)s in package %(path)s exists!",
        "Opera": "Opera",
        "Other": "Other",
        "Password Reset": "Password Reset",
        "Performance will be canceled": "Performance will be canceled",
        "Performance will go on as scheduled": "Performance will go on as scheduled",
        "Performance will move to a nearby sheltered place": "Performance will move to a nearby sheltered place",
        "Pop": "Pop",
        "Punk": "Punk",
        "R&B": "R&B",
        "Ragtime": "Ragtime",
        "Reggae": "Reggae",
        "Religious": "Religious",
        "Request a password reset email.": "Request a password reset email.",
        "Requested scopes do not match with scopes the OAuth client and user is authorized for.": "Requested scopes do not match with scopes the OAuth client and user is authorized for.",
        "Rock": "Rock",
        "Samba": "Samba",
        "Singer / Songwriter": "Singer / Songwriter",
        "Ska": "Ska",
        "Soul": "Soul",
        "Standards": "Standards",
        "Swing": "Swing",
        "That email is already in use.": "That email is already in use.",
        "The SELECT query for the database succeeded, but did not return a valid value.": "The SELECT query for the database succeeded, but did not return a valid value.",
        "The provided district cannot be deleted until all neighbourhoods have been removed from it.": "The provided district cannot be deleted until all neighborhoods have been removed from it.",
        "The provided neighbourhood cannot be added without specifying a district unless all districts have been removed first.": "The provided neighborhood cannot be added without specifying a district unless all districts have been removed first.",
        "The provided password was invalid.": "The provided password was invalid.",
        "The provided query arguments contain an invalid key and/or value.": "The provided query arguments contain an invalid key and/or value.",
        "The requested resource could not be located.": "The requested resource could not be located.",
        "The requested resource owner could not be determined.": "The requested resource owner could not be determined.",
        "The reset token has expired.": "The reset token has expired.",
        "The specified user account already exists.": "The specified user account already exists.",
        "The token you are using is not attached to a user account.": "The token you are using is not attached to a user account.",
        "The user with that email and password could not be found.": "The user with that email and password could not be found.",
        "This OAuth client is not allowed to create users.": "This OAuth client is not allowed to create users.",
        "Unknown object class": "Unknown object class",
        "Unsupported object type": "Unsupported object type",
        "Unsupported value": "Unsupported value",
        "User data and profile information.": "User data and profile information.",
        "Venue": "Venue",
        "World": "World",
        "You must specify the neighbourhood to delete.": "You must specify the neighborhood to delete.",
        "You must specify the neighbourhoods to add": "You must specify the neighborhoods to add",
        "cancelled": "canceled"
    },
    "es_ES": {
        " Performance for:": " Presentaci\u00f3n para:",
        " canceled the performance": " cancel\u00f3 la presentaci\u00f3n",
        " changed the performance end time from ": " cambi\u00f3 el tiempo de finalizaci\u00f3n de la presentaci\u00f3n de ",
        " changed the performance start time from ": " cambi\u00f3 el tiempo de inicio de la presentaci\u00f3n de ",
        " changed the performance time from ": " cambi\u00f3 el tiempo de la presentaci\u00f3n de ",
        " confirmed the performance": " confirm\u00f3 la presentaci\u00f3n",
        " confirmed the performance for the artist": " confirm\u00f3 la presentaci\u00f3n para el artista.",
        " confirmed the performance for the city": " confirm\u00f3 la presentaci\u00f3n para la ciudad/pa\u00eds",
        " confirmed the performance for the venue": " confirm\u00f3 la presentaci\u00f3n para el lugar",
        " has listed their availability from ": " ha listado su disponibilidad de ",
        " requested a performance": " solicit\u00f3 una presentaci\u00f3n",
        " to ": " a ",
        "%(attr_name)s is not a valid attribute of %(obj)s": "%(attr_name)s no es un atributo v\u00e1lido de %(obj)s",
        "%(enum_value)s is not an accepted value.": "%(enum_value)s no es un valor aceptado.",
        "%(profile_name)s Schedule": "Horario de %(profile_name)s",
        "%(profile_name)s has listed their availability from %(start_time)s to %(end_time)s": "%(profile_name)s ha listado su disponibilidad de %(start_time)s - %(end_time)s",
        "A cappella / Barbershop": "A cappella / Barber\u00eda",
        "A password was expected, but none was provided.": "Se esperaba una contrase\u00f1a, pero no se proporcion\u00f3 ninguna.",
        "Accent Color": "Acentuar el color",
        "Accept": "Aceptar",
        "Accept Performance": "Aceptar Presentaci\u00f3n",
        "Accept this event on behalf of a venue.": "Aceptar este evento en nombre de un lugar.",
        "Accept this event on behalf of an artist.": "Aceptar este evento en nombre de un artista.",
        "Accept this event.": "Aceptar este evento.",
        "Accept, Decline or Change Time of this performance": "Aceptar, Rechazar o Cambiar horario de esta presentaci\u00f3n",
        "Accepting Requests": "Aceptando solicitudes",
        "Accepting Requests for Performances": "Aceptando solicitudes de Presentaciones",
        "Accepting Requests from Performers": "Aceptando solicitudes de artistas",
        "Accepting Requests from Venues": "Aceptando solicitudes de lugares",
        "Account": "Cuenta",
        "Account Deleted Successfully": "Cuenta eliminada exitosamente",
        "Account Settings": "Configuraciones de la cuenta",
        "Active": "Activo",
        "Active Chapters": "Sitios Activos",
        "Active as:": "Activo como:",
        "Add": "Agregar",
        "Add Another Performer": "Agregar otro artista",
        "Add a SoundCloud Song URL to this artist.": "Agregar una URL de SoundCloud para la canci\u00f3n para este artista.",
        "Add a YouTube Video URL to this artist.": "Agregar una URL de YouTube para el video para este artista.",
        "Add a genre to this city.": "Agregar un g\u00e9nero a esta ciudad.",
        "Add a venue type to this city.": "Agregar un tipo de lugar para esta ciudad.",
        "Add an event in this city.": "Agregar un evento en esta ciudad.",
        "Add districts to this city.": "Agregar distritos a esta ciudad.",
        "Add neighbourhoods to this city.": "Agregar barrios a esta ciudad",
        "Add neighbourhoods to this district.": "Agregar barrios a este distrito.",
        "Add template to this city.": "Agregar una plantilla a esta ciudad.",
        "Add to Event": "Agregar al Evento",
        "Adding your web profiles is strongly recommended! These links will be displayed on your public profile and performance listings.": "\u00a1Se recomienda agregar sus perfiles web! Estos enlaces se mostrar\u00e1n en su perfil p\u00fablico y listas de presentaciones.",
        "Additional details: ": "Detalles adicionales: ",
        "Address": "Direcci\u00f3n",
        "Afrobeat": "Afrobeat",
        "After going public, you will no longer be able to change your site's neighborhood names, venue types, genres, or time increments.<br/><br/>Are you sure you want to go public now?": "Despu\u00e9s de hacerlo p\u00fablico, ya no podr\u00e1 cambiar los nombres de los barrios, los tipos de lugares, los g\u00e9neros o los incrementos de tiempo de su sitio.<br/><br/>\u00bfEst\u00e1 seguro de que quiere hacerlo p\u00fablico ahora?",
        "Alert": "Alerta",
        "All": "Todo",
        "All Conversations": "Todas las Conversaciones",
        "All performances take place on <b>%(festival_date)s</b>, between <b>%(festival_start_time)s</b> and <b>%(festival_end_time)s</b> throughout the city, and are free and open to all.": "Todas las presentaciones son el <b>%(festival_date)s</b>, entre <b>%(festival_start_time)s</b> y <b>%(festival_end_time)s</b> en todo el Pa\u00eds/Ciudad, y son gratis y para todo p\u00fablico.",
        "Almost there!": "\u00a1Ya casi!",
        "Almost there, now select the performance time": "Ya casi, ahora selecciona el tiempo de la presentaci\u00f3n",
        "Almost there, now select your performance time.": "Ya casi, ahora selecciona el tiempo de tu presentaci\u00f3n.",
        "Almost there, now tell us about your performer(s)": "Cu\u00e9ntenos acerca de su(s) artista(s).",
        "Alphabetical (A to Z)": "Alfab\u00e9tico (A a Z)",
        "Alphabetical (Z to A)": "Alfab\u00e9tico (Z a A)",
        "Already have a performance planned and not interested in additional matches? This option is for you.": "\u00bfYa tienes una presentaci\u00f3n planeada y no est\u00e1s interesado en emparejamientos adicionales? Esta opci\u00f3n es para ti.",
        "Alternative Images": "Im\u00e1genes alternativas",
        "Americana / Bluegrass": "Americana / Bluegrass",
        "Amphitheater or Bandstand": "Anfiteatro o puesto de m\u00fasica",
        "An API Exception occurred": "Se produjo una excepci\u00f3n de API",
        "An OAuth2 related error has occurred.": "Se ha producido un error relacionado con OAuth2.",
        "An account with this email address already exists. Click <a href=\"/account/login\">here</a> to login": "Ya existe una cuenta con esta direcci\u00f3n de correo electr\u00f3nico. Hacer clic <a href=\"/account/login\">aqu\u00ed</a> para iniciar sesi\u00f3n.",
        "An error has occurred!": "\u00a1Se ha producido un error!",
        "An internal error prevented the request from being handled.": "Un error interno impidi\u00f3 que se gestione la solicitud.",
        "An unhandled exception has occurred!": "\u00a1Se ha producido una excepci\u00f3n no controlada!",
        "App config is missing \"BASE_HOSTNAME\"": "Falta la configuraci\u00f3n de la aplicaci\u00f3n \"BASE_HOSTNAME\"",
        "Approve": "Aprobar",
        "Approve Performance": "Aprobar Presentaci\u00f3n",
        "Approve this event.": "Aprobar este evento",
        "Are you a venue or a performer?": "\u00bfEres un lugar o un artista?",
        "Are you flexible about timing? How long a set could the performer play?": "\u00bfEres flexible con el horario? \u00bfCu\u00e1nto tiempo podr\u00eda tocar el artista?",
        "Are you flexible about what time you can perform here? How long is your set?": "\u00bfEs flexible con el horario en el que puede presentarse aqu\u00ed? \u00bfCu\u00e1nto dura su set?",
        "Are you looking for matches, or just want to list your event?": "\u00bfEst\u00e1 buscando emparejarse o simplemente desea publicar su evento?",
        "Are you sure you want to add this new venue type? This action cannot be undone.": "\u00bfEst\u00e1 seguro de que desea agregar este nuevo tipo de lugar? Esta acci\u00f3n no se puede deshacer.",
        "Are you sure you want to delete this event?": "\u00bfEst\u00e1 seguro de que desea eliminar este evento?",
        "Are you sure you want to pause this chapter? Pausing will take %(city_domain)s offline": "\u00bfEst\u00e1s seguro de que deseas pausar este sitio? Pausarlo har\u00e1 que %(city_domain)s est\u00e9 fuera de l\u00ednea",
        "Are you sure you want to pause this chapter? Pausing will take .makemusicday.org": "\u00bfEst\u00e1s seguro de que deseas pausar este sitio? Pausarlo remover\u00e1 .makemusicday.org",
        "Are you sure?": "\u00bfEst\u00e1 seguro?",
        "Artist": "Artista",
        "Artist creation is currently disabled. Try again later.": "La creaci\u00f3n de artistas est\u00e1 actualmente deshabilitada. Int\u00e9ntelo de nuevo m\u00e1s tarde.",
        "As a performer, you can create an event at a venue you've secured on your own. Once approved by the City Admin, your event will be listed on our event website.": "Como artista, puede crear un evento en un lugar que haya asegurado por su cuenta. Una vez aprobado por el administrador de la ciudad/pa\u00eds, su evento se incluir\u00e1 en nuestro sitio web de eventos.",
        "As a venue, you can list events with performances you\u2019ve found on your own. Once approved by the City Admin, your event will be listed on our event website.": "Como lugar, puede enumerar eventos con presentaciones que haya encontrado por su cuenta. Una vez aprobado por el administrador de la ciudad, su evento se incluir\u00e1 en nuestro sitio web de eventos.",
        "Ascending": "Ascendente",
        "Availability": "Disponibilidad",
        "Availability End Time": "Horario de finalizaci\u00f3n de la disponibilidad",
        "Availability Start Time": "Horario de inicio de la disponibilidad",
        "Available template variables": "Variables de plantilla disponibles",
        "Awaiting approval from City Admin": "En espera de la aprobaci\u00f3n del administrador de la ciudad",
        "Awaiting confirmation from ": "Esperando confirmaci\u00f3n de ",
        "Away message is successfully updated!": "\u00a1Mensaje de distancia ha sido actualizado con \u00e9xito!",
        "Back": "Atr\u00e1s",
        "Bar, Caf\u00e9, or Restaurant": "Bar, Caf\u00e9 o Restaurante",
        "Beach": "Playa",
        "Bio": "Bio",
        "Blues": "Blues",
        "Boat": "Barco",
        "Both in-person and online": "Tanto en persona como en l\u00ednea",
        "Brass": "Brass",
        "But, if you want to use the matching system to find more performers to play at your space, please create a separate Venue Profile. You can do that ": "Sin embargo, si desea utilizar el sistema de emparejamiento para encontrar m\u00e1s artistas que se presenten en su espacio, por favor cree un perfil de lugar separado. Puede hacer eso ",
        "Cabaret": "Cabaret",
        "Cancel": "Cancelar",
        "Canceled in case of rain": "Se cancelar\u00e1 en caso de lluvia",
        "Cancelled": "Evento cancelado",
        "Cannot serialize; no default schema has been defined for %(classname)s": "No se puede serializar; no se ha definido ning\u00fan esquema predeterminado para %(classname)s",
        "Celtic": "C\u00e9ltico",
        "Change": "Cambiar",
        "Change Time": "Cambiar Horario",
        "Change Time of Performance for: ": "Cambiar el tiempo de presentaci\u00f3n para: ",
        "Chapter Status: ": "Estado del sitio: ",
        "Choose whether your users can schedule events in 5 minute increments (such as 5:20pm to 5:55pm), or if they must use 15 minute increments (such as 5:15pm to 6:00pm). Most cities use 15 minute increments. <span accent>Once your site is open to the public, this setting cannot be changed.</span>": "Elija si sus usuarios pueden programar eventos en incrementos de 5 minutos (como de 5:20 pm a 5:55 pm.) O si deben utilizar incrementos de 15 minutos (como de 5:15 pm a 6:00 pm). La mayor\u00eda de las ciudades utilizan incrementos de 15 minutos. <span accent> Una vez que su sitio est\u00e1 abierto al p\u00fablico, esta configuraci\u00f3n no se puede cambiar. </span>",
        "Choral": "Coral",
        "City": "Ciudad",
        "City Admin": "Admin. de la ciudad/pa\u00eds",
        "City Administrator": "Administrador de la ciudad",
        "City Center": "Centro Comunitario",
        "City Contact Us URL": "URL para Contactar a Ciudad/Pa\u00eds",
        "City Information": "Informaci\u00f3n de ciudad/pa\u00eds",
        "City Match URL": "URL de Emparejamiento de Ciudad/Pa\u00eds",
        "City Name": "Nombre de ciudad/pa\u00eds",
        "City URL": "URL de Ciudad/Pa\u00eds",
        "City Website URL": "URL de la p\u00e1gina de la Ciudad/Pa\u00eds",
        "City admin account is successfully created!": "\u00a1La cuenta de administrador de ciudad ha sido creada con \u00e9xito!",
        "City admin confirmed the performance": "El administrador de la ciudad/pa\u00eds confirm\u00f3 la presentaci\u00f3n",
        "City information successfully updated!": "\u00a1La informaci\u00f3n de la ciudad ha sido actualizada con \u00e9xito!",
        "Classical": "Cl\u00e1sico",
        "Click here to resend the confirmation message.": "Haga clic aqu\u00ed para reenviar correo de confirmaci\u00f3n.",
        "Close": "Cerca",
        "Colors": "Colores",
        "Comma-separated values": "Valores separados por comas",
        "Community Center": "Centro Comunitario",
        "Complete Profile": "Completar Perfil",
        "Confirm": "Confirmar",
        "Confirm New Password": "Confirmar Nueva Contrase\u00f1a",
        "Confirm Password": "Confirmar Contrase\u00f1a",
        "Confirm this performance on behalf of: ": "Confirmar esta presentaci\u00f3n en representaci\u00f3n de: ",
        "Confirmed": "confirmado",
        "Confirmed Performances": "Presentaciones confirmadas",
        "Congratulations!": "\u00a1Felicidades!",
        "Congratulations! You are ready to open your site to the public.": "\u00a1Felicidades! Est\u00e1 listo para abrir su sitio al p\u00fablico.",
        "Contact": "Contacto",
        "Contact Email": "Correo electr\u00f3nico de contacto",
        "Contact Info": "Informaci\u00f3n de contacto",
        "Contact Information": "Informaci\u00f3n del contacto",
        "Contact Name": "Nombre de contacto",
        "Contact Phone": "Tel\u00e9fono de contacto",
        "Contact Us": "Cont\u00e1ctenos",
        "Content-type header must be one of %(mime_types)s": "El encabezado del tipo de contenido debe ser %(mime_types)s",
        "Continue": "Continuar",
        "Continue Anyway": "Continuar de todas maneras",
        "Conversations": "Conversaciones",
        "Could not create a OAuth token. Perhaps missing scopes?": "No se pudo crear un token de OAuth. \u00bfQuiz\u00e1s faltan extensiones?",
        "Country": "Country",
        "Courtyard": "Patio",
        "Create Account": "Crear una cuenta",
        "Create Admin Account": "Crear cuenta de administrador",
        "Create An Event": "Crear un evento",
        "Create Event": "Crear evento",
        "Create New Chapter": "Crear nueva ciudad/pa\u00eds",
        "Create New Festival": "Crear un nuevo festival",
        "Create Your Own Event": "Crea tu propio evento",
        "Create a Matchmaking Profile": "Crear un perfil de Emparejamiento",
        "Create a Performance for: ": "Crear  una presentaci\u00f3n para: ",
        "Create a Performer Profile": "Crear un perfil de artista",
        "Create a Venue Profile": "Crear un perfil de lugar",
        "Create a new conversation for this artist.": "Crear una nueva conversaci\u00f3n para este artista.",
        "Create a new conversation for this venue.": "Crear una nueva conversaci\u00f3n para este lugar.",
        "Create a new conversation.": "Crear una nueva conversaci\u00f3n.",
        "Create a new event for this artist.": "Crear un nuevo evento para este artista.",
        "Create a new event for this venue.": "Crear un nuevo evento para este lugar.",
        "Create a new performer profile for this user.": "Crear un nuevo perfil de artista para este usuario.",
        "Create a new self-match event for this artist.": "Crear un nuevo evento de auto-emparejamiento para este artista.",
        "Create a new self-match event for this user.": "Crear un nuevo evento de auto-emparejamiento para este usuario.",
        "Create a new self-match event for this venue.": "Crear un nuevo evento de auto-emparejamiento para este lugar.",
        "Create a new venue profile for this user.": "Crear un nuevo perfil de lugar para este usuario.",
        "Created at": "Creado en",
        "Current City Locale": "Localidad actual de la ciudad",
        "Current Font Family": "Familia actual de fuentes",
        "Custom CSS": "CSS personalizado",
        "Customize by uploading your city\u2019s event logo. It will replace the Make Music logo in the upper left. <br/>File format should be jpg or png. A transparent png will look best. Area available for logo is 150px x 150px.": "Para personalizar cargue el logotipo del evento de su ciudad. Reemplazar\u00e1 el logotipo de Make Music en la esquina superior izquierda. <br/> El formato de archivo debe ser jpg o png. Un png transparente se ver\u00e1 mejor. El \u00e1rea disponible para el logotipo es de 150 px x 150 px.",
        "Customize the platform colors, fonts and logo.": "Personalizar los colores, las fuentes y el logotipo de la plataforma.",
        "Dashboard": "Tablero",
        "Deactivate": "Desactivar",
        "Deadline To Register": "Fecha l\u00edmite para Registrarse",
        "Deadline to Create Profile": "Fecha l\u00edmite para Crear perfil",
        "Deadline to Register": "Fecha l\u00edmite para Registrarse",
        "Deadline to Request or Confirm a Performance": "Fecha l\u00edmite para Solicitar o Confirmar una Presentaci\u00f3n",
        "Deadlines": "Plazos",
        "Decline": "Rechazar",
        "Default": "Defecto",
        "Default Image": "Imagen por defecto",
        "Default Language": "Idioma por defecto",
        "Define the musical genres performers and venues will be able to select. <span accent>Once the platform goes live for participants, you can not delete existing genres, but you can add new ones.</span>": "Definir los g\u00e9neros musicales que los artistas y los lugares podr\u00e1n seleccionar. <span accent> Una vez que la plataforma se activa para los participantes, no puede eliminar g\u00e9neros existentes, pero puede agregar nuevos. </span>",
        "Define the types of venues that users will be able to select. <span accent>Once the platform goes live for participants, you cannot delete existing venue types, but you can add new ones.</span>": "Definir los tipos de lugares que los usuarios podr\u00e1n seleccionar. <span accent> Una vez que la plataforma se activa para los participantes, no puede eliminar los tipos de lugares existentes, pero puede agregar otros nuevos. </span>",
        "Delete": "Eliminar",
        "Delete Account": "Borrar cuenta",
        "Delete Performance": "Eliminar Presentaci\u00f3n",
        "Delete Performance for:": "Eliminar Presentaci\u00f3n para:",
        "Delete this artist.": "Eliminar este artista.",
        "Delete this district.": "Eliminar este distrito.",
        "Delete this event.": "Eliminar este evento.",
        "Delete this neighbourhood.": "Eliminar este barrio.",
        "Delete this user account.": "Eliminar esta cuenta de usuario.",
        "Delete this venue.": "Eliminar este lugar.",
        "Deletes districts from this city.": "Eliminar distritos de esta ciudad.",
        "Deletes neighbourhoods from this city.": "Eliminar barrios de esta ciudad.",
        "Descending": "Descendiendo",
        "Description": "Descripci\u00f3n",
        "Disable Matchmaking": "Desactivaci\u00f3n del Emparejamiento",
        "Disabling Matchmaking": "Desactivaci\u00f3n del Emparejamiento",
        "Dismiss": "Descartar",
        "Displaying": "Visualizaci\u00f3n",
        "District": "Distrito",
        "District is already existed": "El distrito ya existe",
        "Districts": "Distritos",
        "Districts & Neighborhood": "Distritos y Barrios",
        "Do Not Use Districts": "No usar Distritos",
        "Done": "Hecho",
        "Download": "Descargar",
        "Download Event Data": "Descargar datos de eventos",
        "Drag the map and change the zoom levels to adjust how your concert listings appear to the public. This will not affect the way that maps display on this registration/matchmaking site.": "Arrastrar el mapa y cambiar los niveles de zoom para ajustar c\u00f3mo se muestran al p\u00fablico sus listas de conciertos. Esto no afectar\u00e1 la forma en que se muestran los mapas en este sitio de registro / emparejamiento.",
        "Drag the slider to select the performance time.": "Arrastrar el control deslizante para seleccionar el tiempo de la presentaci\u00f3n.",
        "Drag the sliders below to select the start and end times for performance match.": "Arrastrar los controles deslizantes a continuaci\u00f3n para seleccionar la hora de inicio y de finalizaci\u00f3n para emparejar con la Presentaci\u00f3n.",
        "Drag the sliders below to select the start and end times for your requested performance.": "Arrastrar los controles deslizantes a continuaci\u00f3n para seleccionar la hora de inicio y de finalizaci\u00f3n de la Presentaci\u00f3n solicitada.",
        "Drag the sliders to select the performance time": "Arrastrar los controles deslizantes para seleccionar el tiempo de Presentaci\u00f3n",
        "Drag the sliders to update the performance time": "Arrastrar los controles deslizantes para actualizar el tiempo de Presentaci\u00f3n.",
        "Dragging the map will reposition the flag that shows the exact location for your venue. Zoom in to make fine adjustments.": "Al arrastrar el mapa, se reposicionar\u00e1 la bandera que muestra la ubicaci\u00f3n exacta de su lugar. Hacer zoom para confirmar la ubicaci\u00f3n.",
        "Due to social distancing concerns this year, our community is disabling the \"matchmaking\" features for Make Music Day. Please continue to sign up as a venue or a performer, and you can still register your June 21 events.": "Debido a preocupaciones sobre el distanciamiento social este a\u00f1o, nuestra comunidad est\u00e1 desactivando las funciones de \"emparejamiento\" para Make Music Day. Contin\u00fae registr\u00e1ndose como lugar o como artista, y a\u00fan puede registrar sus eventos del 21 de junio.",
        "Early Music": "Early Music",
        "East Asian": "Asi\u00e1tica del este",
        "Eastern European": "De Europa del Este",
        "Edit": "Editar",
        "Edit Performance for: ": "Editar Presentaci\u00f3n para: ",
        "Edit Performer Profile": "Editar perfil del artista",
        "Edit Profile to Find Performers": "Editar perfil para encontrar artistas",
        "Edit Template": "Editar plantilla",
        "Edit Times": "Editar horarios",
        "Edit and preview email templates here. When you are satisfied with the preview, click save to keep your changes.": "Editar y obtener una vista previa de las plantillas de correo electr\u00f3nico aqu\u00ed. Cuando est\u00e9 satisfecho con la vista previa, haga clic en guardar para conservar los cambios.",
        "Edit basic information about your venue": "Editar informaci\u00f3n b\u00e1sica sobre su lugar",
        "Edit the Venue Profile": "Editar el perfil del lugar",
        "Edit this artist.": "Editar este artista.",
        "Edit this city.": "Editar esta ciudad.",
        "Edit this district.": "Editar este distrito.",
        "Edit this email template.": "Editar esta plantilla de correo electr\u00f3nico.",
        "Edit this event.": "Editar este evento.",
        "Edit this neighbourhood.": "Editar este barrio.",
        "Edit this user account.": "Editar esta cuenta de usuario.",
        "Edit this venue.": "Editar este lugar.",
        "Electrical Requirement": "Necesidades de electricidad",
        "Electrical Requirements": "Requisitos de electricidad",
        "Electricity / PA Needs": "Necesidades de Electricidad / Equipo de Sonido",
        "Electricity Conflict": "Conflicto de electricidad",
        "Electricity Needed": "Es necesario Electricidad",
        "Electricity Needs": "Necesidades de Electricidad",
        "Electricity Provided": "Se brindar\u00e1 Electricidad",
        "Electricity and PA Needed": "Es necesario Electricidad / Equipo de Sonido",
        "Electricity and PA Provided": "Se brindar\u00e1 Electricidad y Equipo de Sonido",
        "Electronic": "Electr\u00f3nico",
        "Email": "Correo electr\u00f3nico",
        "Email Address": "Direcci\u00f3n de correo electr\u00f3nico",
        "Email Templates": "Plantillas de correo electr\u00f3nico",
        "Email Templates & MailChimp": "Plantillas de correo electr\u00f3nico & MailChimp",
        "Enable Matchmaking": "Habilitar Emparejamiento",
        "End Time": "Hora de finalizaci\u00f3n",
        "End Time has to be after start time, please pick a proper value": "La hora de finalizaci\u00f3n debe ser posterior a la hora de inicio, elija un valor adecuado",
        "End time (earliest to latest)": "Tiempo de finalizaci\u00f3n (de temprano a tarde)",
        "End time (latest to earliest)": "Tiempo de finalizaci\u00f3n (de tarde a temprano)",
        "Enter 5 digit postal code": "Ingresar un c\u00f3digo postal de 5 d\u00edgitos",
        "Enter District Name": "Ingresar el nombre del distrito",
        "Enter Genre Name": "Ingresar el nombre del g\u00e9nero",
        "Enter Location": "Ingresar Ubicaci\u00f3n",
        "Enter Neighborhood Name": "Ingresar el nombre del barrio",
        "Enter Venue Type": "Ingresar el tipo de lugar",
        "Enter address": "Ingresar direcci\u00f3n",
        "Enter contact first and last name": "Ingresar nombre y apellido de contacto",
        "Enter email address": "Ingresar correo electr\u00f3nico",
        "Enter performer name": "Ingresar el nombre del artista",
        "Enter performer name or venue name": "Ingresar nombre de artista o lugar",
        "Enter phone number": "Ingresar n\u00famero de tel\u00e9fono",
        "Enter the URLs for any social media accounts your festival uses. These will be displayed at the bottom of each page on your site.": "Ingresar las URL de las cuentas de redes sociales que utilice su festival. Estos se mostrar\u00e1n en la parte inferior de cada p\u00e1gina de su sitio.",
        "Enter venue name": "Ingresar nombre del lugar",
        "Error": "Error",
        "Event": "Evento",
        "Event Accepted": "Evento aceptado",
        "Event Approved": "Evento aprobado",
        "Event Cancelled": "Evento cancelado",
        "Event Declined": "Evento rechazado",
        "Event Pending": "Evento pendiente",
        "Event created by you": "Evento creado por ti",
        "Event creation is currently disabled. Try again later.": "La creaci\u00f3n de eventos est\u00e1 actualmente deshabilitada. Int\u00e9ntelo de nuevo m\u00e1s tarde.",
        "Event has been accepted and is pending festival organizer approval": "El evento ha sido aceptado y est\u00e1 pendiente de aprobaci\u00f3n por el organizador del festival.",
        "Event has been aceepted and been approved by the festival organizers": "El evento ha sido aceptado y aprobado por los organizadores del festival.",
        "Event has been cancelled": "El evento ha sido cancelado",
        "Event has been requested": "Se ha solicitado el evento",
        "Experimental": "Experimental",
        "Export data for this city.": "Exportar datos para esta ciudad.",
        "Facebook": "Facebook",
        "Facebook URL": "URL de Facebook",
        "Farm": "Farm",
        "Festival Date": "Fecha de Festival",
        "Festival Details": "Detalles del festival",
        "Festival End Time": "Hora de Finalizaci\u00f3n del Festival",
        "Festival Name": "Nombre de Festival",
        "Festival Start & End Times": "Horas de inicio y finalizaci\u00f3n del festival",
        "Festival Start Time": "Hora de Inicio del Festival",
        "Fickle": "Cambiante",
        "Fickle Users": "Usuarios cambiantes",
        "Field": "Campo",
        "Find Performers": "Encontrar artistas",
        "Find Venues": "Encontrar lugares",
        "Finish Setup": "Finalizar configuraci\u00f3n",
        "First Name": "Nombre",
        "Folk": "Folk",
        "Fonts": "Fuentes",
        "Forgot Password": "\u00bfHas olvidado la contrase\u00f1a?",
        "Full artist information.": "Informaci\u00f3n completa del artista.",
        "Full city information.": "Informaci\u00f3n completa de la ciudad.",
        "Full conversation information.": "Informaci\u00f3n completa de la conversaci\u00f3n.",
        "Full event information.": "Informaci\u00f3n completa del evento.",
        "Full venue information.": "Informaci\u00f3n completa del lugar.",
        "Funk": "Funk",
        "Gallery or Museum": "Galer\u00eda o Museo",
        "Garage": "Garaje",
        "Garden": "Jard\u00edn",
        "Genre": "G\u00e9nero",
        "Genre already exists": "El g\u00e9nero ya existe",
        "Genre(s)": "G\u00e9nero(s)",
        "Genres": "G\u00e9neros",
        "Get all conversations for this user account.": "Obtener todas las conversaciones de esta cuenta de usuario.",
        "Get all conversations that this city's admin has commented on.": "Obtener todas las conversaciones que el administrador de esta ciudad ha comentado.",
        "Get all custom email templates for this city.": "Obtener todas las plantillas de correo electr\u00f3nico personalizadas para esta ciudad.",
        "Get all genres for this city.": "Obtener todos los g\u00e9neros para esta ciudad.",
        "Get all options relevant to artists of this city.": "Obtener todas las opciones relevantes para los artistas de esta ciudad.",
        "Get all options relevant to events of this city.": "Obtener todas las opciones relevantes para los eventos de esta ciudad.",
        "Get all options relevant to venues of this city.": "Obtener todas las opciones relevantes para los lugares de esta ciudad.",
        "Get conversations that this artist is a part of.": "Recibir conversaciones de las que este artista forma parte.",
        "Get conversations that this venue is a part of.": "Recibir conversaciones de las que forma parte este lugar.",
        "Get event conversation.": "Obtener conversaci\u00f3n sobre el evento.",
        "Go Back": "Regresar",
        "Go Public": "Publicar",
        "Go to City": "Ir a la ciudad/pa\u00eds",
        "Go to Find Performers": "Ir a Buscar artistas",
        "Gospel": "Gospel",
        "Grant city admin capabilities to a user.": "Otorgar capacidades de administraci\u00f3n de la ciudad a un usuario.",
        "Great": "Genial",
        "Great, now we need a little information about your performer's availability": "Genial, ahora necesitaremos un poco de informaci\u00f3n sobre la disponibilidad de tu artista",
        "Great, now we need a little information about your venue's location": "Genial, ahora necesitaremos un poco de informaci\u00f3n sobre la ubicaci\u00f3n de su lugar",
        "Has Artist Confirmed?": "\u00bfEl artista ha confirmado?",
        "Has City Confirmed?": "\u00bfEl organizador ha confirmado?",
        "Has Piano": "Tiene piano",
        "Has Venue Confirmed?": "\u00bfEl lugar del evento ha confirmado?",
        "Has confirmed email?": "\u00bfHas confirmado correo electr\u00f3nico?",
        "Health check FAILED due to 'SELECT NOW()' raising an exception.": "La verificaci\u00f3n de estado FALL\u00d3 debido a que 'SELECT NOW ()' (SELECCIONAR AHORA) gener\u00f3 una excepci\u00f3n.",
        "Hello City Admin!": "\u00a1Hola, administrador de la ciudad!",
        "Hello Super Admin!": "\u00a1Hola, Super Admin!",
        "Help Me Find Performers": "Ay\u00fademe a encontrar artistas",
        "Hi ": "Hola ",
        "Hi %(profile_name)s": "Hola %(profile_name)s",
        "Highlife": "Highlife",
        "Hip-Hop": "Hip hop",
        "Home": "Casa",
        "Home (Yard, Porch, Roof)": "Casa (patio, porche, terraza)",
        "Hospital or Senior Center": "Hospital o centro para personas mayores",
        "House of Worship": "Casa de culto",
        "How to schedule a performance": "C\u00f3mo programar una Presentaci\u00f3n",
        "I can provide both electricity and a PA": "Puedo proporcionar electricidad y Equipo de Sonido",
        "I can provide electricity, but not a PA": "Puedo proporcionar electricidad, pero no un Equipo de Sonido",
        "I can't provide electricity or a PA, but performers may bring their own": "No puedo proporcionar electricidad ni equipo de sonido, pero los artistas pueden traer los suyos.",
        "I have read and agree to the terms and conditions": "He le\u00eddo y acepto los t\u00e9rminos y condiciones",
        "I need electricity and a PA from the venue, or else I can't perform": "Necesito electricidad y equipo de sonido del lugar, o de lo contrario no me podr\u00e9 presentar",
        "I need electricity from the venue, or else I can't perform": "Necesito electricidad del lugar, o de lo contrario no me podr\u00e9 presentar",
        "I need electricity, but I can provide my own batteries or generator if necessary": "Necesito electricidad, pero puedo proporcionar mis propias bater\u00edas o generador si es necesario",
        "I will not use amplification (no amps, mics, or speakers) and I don't need electricity": "No utilizar\u00e9 amplificaci\u00f3n (sin amplificadores, micr\u00f3fonos ni altavoces) y no necesito electricidad",
        "I'd love to have electricity and a PA provided, but I'm flexible and will make it work regardless": "Me encantar\u00eda tener electricidad y un equipo de sonido, pero soy flexible y me puedo adaptar.",
        "ID": "IDENTIDAD",
        "If matchmaking is not possible for your chapter (for example, due to social distancing requirements), you can turn it off here. Users will still be able to create profiles and events for themselves, but they won't be able to search for other users and make matches with them.": "Si el emparejamiento no es posible para su sitio (por ejemplo, debido a requisitos de distanciamiento social), puede desactivarlo aqu\u00ed. Los usuarios a\u00fan podr\u00e1n crear perfiles y eventos por s\u00ed mismos, pero no podr\u00e1n buscar a otros usuarios y hacer coincidencias con ellos.",
        "If the requested performance time doesn't work for you but you're still interested in scheduling the performance, change the time here. The other party will receive a notification and be able to confirm, modify or decline your newly proposed time.": "Si no est\u00e1 de acuerdo con el horario de Presentaci\u00f3n solicitado, pero a\u00fan est\u00e1 interesado en programar la Presentaci\u00f3n, cambie el horario aqu\u00ed. La otra parte recibir\u00e1 una notificaci\u00f3n y podr\u00e1 confirmar, modificar o rechazar su nuevo horario propuesto.",
        "If you are planning on livestreaming your event, enter the URL you will use (such as the address of your Facebook page or YouTube channel). If you're not sure, leave it blank and you can enter it later.": "Si planea transmitir en vivo su evento, ingrese la URL que utilizar\u00e1 (como la direcci\u00f3n de su p\u00e1gina de Facebook o canal de YouTube). Si no est\u00e1 seguro, d\u00e9jelo en blanco y podr\u00e1 ingresarlo luego.",
        "If you want to create an event for one of your performer or venue profiles, go back, switch to that profile and choose \"Create Your Own Event\".<br/><br/>If you want to list an event that does NOT involve any performers or venues you've already registered, click to continue. (This is uncommon.)": "Si desea crear un evento para uno de sus perfiles de artista o lugar, regrese, cambie a ese perfil y elija \"Crear su propio evento\". <br/> <br/> Si desea incluir un evento que NO involucra a ning\u00fan artista o lugar que ya haya registrado, haga clic para continuar. (Esto es poco com\u00fan).",
        "If you want to do both (you have an event to list but want to find matches as well) we recommend selecting the Performer or Venue Looking for a Match button. You'll have a profile that will allow you to seek matches, but you'll also be able to add your own performers from there.": "Si desea hacer ambas cosas (tiene un evento para enumerar pero tambi\u00e9n desea emparjear), le recomendamos seleccionar el bot\u00f3n Artista o Lugar buscando emparejamiento. Tendr\u00e1s un perfil que te permitir\u00e1 buscar emparejamientos, pero tambi\u00e9n podr\u00e1s agregar tus propios artistas desde all\u00ed.",
        "If your event is happening online, indicate where you will physically be performing (eg. your home)": "Si su evento se lleva a cabo en l\u00ednea, indique d\u00f3nde se presentar\u00e1 f\u00edsicamente (por ejemplo, su casa)",
        "Image (optional, but strongly encouraged)": "Imagen (opcional, pero muy recomendable)",
        "Image URL": "URL de Imagen",
        "Images (optional, but strongly encouraged)": "Im\u00e1genes (opcionales, pero muy recomendables)",
        "Import": "Importar",
        "Import Past Profiles": "Importar perfiles anteriores",
        "Import Performer": "Importar artista",
        "Import Performer Profile": "Importar perfil de artista",
        "Import Venue": "Importar lugar",
        "Import the Venue Profile": "Importar el perfil de lugar",
        "In Case of Rain": "En caso de lluvia",
        "In Person": "Presencial",
        "In case of inclement weather, will the show go on?": "En caso de clima intempestivo, \u00bfcontinuar\u00e1 el espect\u00e1culo?",
        "In-Person Venues": "Lugares presenciales",
        "In-person": "En persona",
        "In-person, but will move online if necessary": "En persona, pero se puede cambiar a en l\u00ednea si es necesario",
        "Inactive Chapters": "Sitios Inactivos",
        "Inbox": "Bandeja de entrada",
        "Include a message (optional)": "Incluir un mensaje (opcional)",
        "Indian": "Indian",
        "Indie-Folk": "Indie-Folk",
        "Indie-Rock": "Indie-Rock",
        "Information for Performers": "Informaci\u00f3n para artistas",
        "Instagram": "Instagram",
        "Instagram URL": "URL de Instagram",
        "Invalid Basic credentials in Authorization header. The OAuth client ID and secret must be provided to create a user.": "Credenciales b\u00e1sicas no v\u00e1lidas en el encabezado de autorizaci\u00f3n. Secreto y el ID de cliente de OAuth se deben proporcionar para crear un usuario.",
        "Invalid Content-Type provided": "Se proporcion\u00f3 un tipo de contenido no v\u00e1lido",
        "Invalid `Authorization: Basic` token.": "Token de \u02bbautorizaci\u00f3n: b\u00e1sico` no v\u00e1lido.",
        "Invalid data type": "Tipo de datos no v\u00e1lido",
        "Invalid email address": "Direcci\u00f3n de correo electr\u00f3nico no v\u00e1lida",
        "Invalid email address.": "Direcci\u00f3n de correo electr\u00f3nico no v\u00e1lida.",
        "Invalid value": "valor no v\u00e1lido",
        "Jazz": "Jazz",
        "Keep in mind that changes to color can affect usability. Test all pages to make sure color combinations still communicate the functionality of the site.": "Tenga en cuenta que los cambios de color pueden afectar la usabilidad. Probar todas las p\u00e1ginas para asegurarse de que las combinaciones de colores sigan comunicando la funcionalidad del sitio.",
        "Kids": "Ni\u00f1os",
        "Last Name": "Apellido",
        "Latin": "Latino",
        "Lesson": "Lecci\u00f3n",
        "Lessons": "Lecciones",
        "Let me double check everything first": "D\u00e9jame revisar todo primero",
        "Library": "Biblioteca",
        "List My Event": "Listar mi evento",
        "List New Event": "Listar nuevo evento",
        "List all events in this city.": "Lista de todos los eventos en esta ciudad.",
        "List confirmed events for this artist.": "Lista de los eventos confirmados para este artista.",
        "List confirmed events for this venue.": "Lista de los eventos confirmados para este lugar.",
        "List events for this artist.": "Lista de los eventos de este artista.",
        "List events for this venue.": "Lista de los eventos para este lugar.",
        "Listed Event": "Evento en lista",
        "Live Streaming": "Transmisi\u00f3n en vivo",
        "Livestreaming website (optional)": "Sitio web de transmisi\u00f3n en vivo (opcional)",
        "Livestreaming?": "\u00bfTransmisi\u00f3n en vivo?",
        "Lobby": "Lobby",
        "Locale": "Lugar",
        "Location": "Ubicaci\u00f3n",
        "Login": "Iniciar sesi\u00f3n",
        "Logo": "Logo",
        "Logout": "Cerrar sesi\u00f3n",
        "Looking to sign up for Make Music Day? Select your city here": "\u00bfQuiere registrarse en Make Music Day? Seleccione su ciudad/pa\u00eds aqu\u00ed",
        "MPB": "MPB",
        "Make Changes": "Hacer cambios",
        "Make Music Day": "Make Music Day",
        "Make Music Day End": "Fin del Make Music Day",
        "Make Music Day Start": "Comienzo del Make Music Day",
        "Make Music Day is a celebration of music on the summer solstice that is performed by anyone and enjoyed by everyone. You're invited to take part in the festivities and help show off our local talent and join the public celebration.\n\nThe festival is on June 21st and goes all day. It takes place in over 1,000 cities around the world.": "Make Music Day es la celebraci\u00f3n de la m\u00fasica en el solsticio de verano en el cual todos pueden presentarse y disfrutar. Est\u00e1 invitado a participar en las festividades y ayudar a mostrar el talento local y unirse a la celebraci\u00f3n.\n\nEl festival es el 21 de junio y dura todo el d\u00eda. Se lleva a cabo en m\u00e1s de 1.000 ciudades de todo el mundo.",
        "Map": "Mapa",
        "Maps": "Mapas",
        "Mark this conversation as read.": "Marcar esta conversaci\u00f3n como le\u00edda.",
        "Mark this conversation as unread.": "Marcar esta conversaci\u00f3n como no le\u00edda.",
        "Mark this message as read.": "Marcar este mensaje como le\u00eddo.",
        "Mark this message as unread.": "Marcar este mensaje como no le\u00eddo.",
        "Market": "Mercado",
        "Match My Preferences": "Emparejar con mis preferencias",
        "Message": "Mensaje",
        "Message (optional)": "Mensaje (opcional)",
        "Message from Admin": "Mensaje del administrador",
        "Metal": "Metal",
        "Microsoft Excel": "Microsoft Excel",
        "Middle Eastern": "Medio este",
        "Missing Pieces: ": "Piezas perdidas: ",
        "Missing data for required field.": "Faltan datos para el campo obligatorio.",
        "Missing required field": "Faltan campos requeridos",
        "Missing soundcloud_song_urls": "Falta soundcloud_song_urls",
        "Missing youtube_video_urls": "Falta youtube_video_urls",
        "Moving to shelter in case of rain": "La presentaci\u00f3n ser\u00e1 trasladada a un lugar resguardado",
        "Musical Theater": "Teatro musical",
        "Mute this conversation.": "Silenciar esta conversaci\u00f3n.",
        "My Profiles": "Mis Perfiles",
        "Name": "Nombre",
        "Native American": "Nativo americano",
        "Neighborhood": "Barrio",
        "Neighborhood is already existed": "El barrio ya existe",
        "New Admin Account": "Cuenta de nuevo administrador",
        "New Chapter": "Nuevo Sitio",
        "New Festival is successfully created!": "\u00a1Nuevo Festival ha sido creado con \u00e9xito!",
        "New Message": "Nuevo mensaje",
        "New Password": "Nueva contrase\u00f1a",
        "New Password (at least 8 characters)": "Nueva contrase\u00f1a (al menos 8 caracteres)",
        "New Performance Requested": "Nueva Presentaci\u00f3n solicitada",
        "New Performer Profile": "Nuevo perfil de artista",
        "New This Week": "Nuevo esta semana",
        "New Venue Profile": "Nuevo perfil de lugar",
        "New users will not be able to create an account after this date.": "Los nuevos usuarios no podr\u00e1n crear una cuenta despu\u00e9s de esta fecha.",
        "Newest First": "Nuevo primero",
        "Next": "pr\u00f3ximo",
        "Next Step": "Pr\u00f3ximo paso",
        "Next: Availability": "Siguiente: Disponibilidad",
        "Next: Contacts": "Siguiente: Contactos",
        "Next: Location": "Siguiente: Ubicaci\u00f3n",
        "Next: Performance Details": "Siguiente: Detalles de la presentaci\u00f3n",
        "Next: Performance Time": "Siguiente: Horario de Presentaci\u00f3n",
        "Next: Performer Details": "Siguiente: Detalles del artista",
        "No": "No",
        "No Electricity Allowed": "No se permite Electricidad",
        "No Electricity Needed": "No es necesario Electricidad",
        "No Electricity Provided": "No se brindar\u00e1 Electricidad",
        "No Electricity Used": "No se usar\u00e1 Electricidad",
        "No Performances Found": "No se encontraron presentaciones",
        "No Profiles Found": "No se encontraron perfiles",
        "No amplification of any kind (amps, mics, or speakers) is permitted at this site": "No se permite amplificaci\u00f3n de ning\u00fan tipo (amplificadores, micr\u00f3fonos o altavoces) en este sitio.",
        "No conversations yet - drop them a line!": "A\u00fan no hay conversaciones, \u00a1escr\u00edbeles!",
        "No request body was provided.": "No se proporcion\u00f3 ning\u00fan texto de solicitud.",
        "No request body was provided. It should be JSON data.": "No se proporcion\u00f3 ning\u00fan texto de solicitud. Deben ser datos JSON.",
        "No request body was provided. It should be form encoded data.": "No se proporcion\u00f3 ning\u00fan texto de solicitud. Deben ser datos codificados en forma.",
        "No such schema %(classname)s in package %(path)s exists!": "\u00a1No existe tal esquema %(classname)s en el paquete %(path)s!",
        "Not Accepting Requests": "No aceptar solicitudes",
        "Not Wheelchair Accessible": "Sin acceso para sillas de ruedas",
        "Not a JSON payload": "No es una carga \u00fatil JSON",
        "Not available for requests": "No disponible para solicitudes",
        "Notes for Performers": "Notas para los artistas",
        "OAuth client is not authorized for that grant type.": "El cliente de OAuth no est\u00e1 autorizado para ese tipo de concesi\u00f3n.",
        "OK": "OK",
        "OOPS! It looks like registration hasn't opened yet for this city. Please contact your city organizer or check back later.": "\u00a1UPS! Parece que a\u00fan no se ha abierto el registro para esta ciudad. P\u00f3ngase en contacto con el organizador de su ciudad o vuelva a consultar m\u00e1s tarde.",
        "Office Building": "Edificio de oficinas",
        "Ok": "OK",
        "Oldest First": "Antiguo primero",
        "Once both parties have confirmed that the performance is set, your city admin will give final confirmation that the performance is a go. This may take some time, but never fear, you will hear back!": "Una vez que ambas partes hayan confirmado que la Presentaci\u00f3n est\u00e1 configurada, el administrador de su ciudad dar\u00e1 la confirmaci\u00f3n final de que la Presentaci\u00f3n est\u00e1 en marcha. Esto puede llevar algo de tiempo, pero no tema, \u00a1recibir\u00e1 una respuesta!",
        "One last thing!": "\u00a1Una \u00faltima cosa!",
        "Online If Needed": "Virtual de ser necesario",
        "Online Only": "Solo Virtual",
        "Online only": "Solo Virtual",
        "Open to Anything": "Abierto a todo",
        "Opera": "\u00d3pera",
        "Other": "Otro",
        "Parade": "Desfile",
        "Park": "Parque",
        "Parking Lot": "Estacionamiento",
        "Participatory": "Participativo",
        "Password": "Contrase\u00f1a",
        "Password (at least 8 characters)": "Contrase\u00f1a (al menos 8 caracteres)",
        "Password Reset": "Restablecimiento de contrase\u00f1a",
        "Password Reset Requested": "Solicitud de restablecimiento de contrase\u00f1a",
        "Password Update Notification": "Notificaci\u00f3n de actualizaci\u00f3n de contrase\u00f1a",
        "Password was reset!": "\u00a1Se restableci\u00f3 la contrase\u00f1a!",
        "Pause Chapter": "Pausar sitio",
        "Paused": "Pausado",
        "Pending": "pendiente",
        "Pending Your Approval": "Su aprobaci\u00f3n est\u00e1 pendiente",
        "Performance Cancelled": "La Presentaci\u00f3n ser\u00e1 cancelada",
        "Performance Confirmed": "Presentaci\u00f3n confirmada",
        "Performance Details": "Detalles de la Presentaci\u00f3n",
        "Performance Request Accepted": "Solicitud de Presentaci\u00f3n aceptada",
        "Performance Status": "Estado de Presentaci\u00f3n",
        "Performance Time": "Horario de la Presentaci\u00f3n",
        "Performance will be canceled": "La Presentaci\u00f3n ser\u00e1 cancelada",
        "Performance will go on as scheduled": "La Presentaci\u00f3n continuar\u00e1 seg\u00fan lo programado",
        "Performance will move to a nearby sheltered place": "La Presentaci\u00f3n se trasladar\u00e1 a un lugar protegido cercano",
        "Performances": "Presentaciones",
        "Performer": "Artista",
        "Performer Availability": "Disponibilidad del artista",
        "Performer Bio": "Bio del artista",
        "Performer Info": "Informaci\u00f3n del artista",
        "Performer Name": "Nombre del artista",
        "Performer or Venue<br/>Looking for a Match": "Artista o lugar <br/> Buscando emparejamiento",
        "Performers": "Artistas",
        "Performers seeking<br/>Venue": "Artistas en b\u00fasqueda de<br/> Lugares",
        "Phone": "Tel\u00e9fono",
        "Phone Number": "N\u00famero de tel\u00e9fono",
        "Piano": "Piano",
        "Pick An Image": "Elige una imagen",
        "Pick Another Image": "Elegir otra imagen",
        "Plaza or Patio": "Plaza o Patio",
        "Please check your email inbox, and follow the instructions to reset your password.": "Revisar su bandeja de entrada de su correo electr\u00f3nico y seguir las instrucciones para restablecer su contrase\u00f1a.",
        "Please check your inbox to confirm your email address.": "Revisar su bandeja de entrada para confirmar su direcci\u00f3n de correo electr\u00f3nico.",
        "Please confirm your email address and you'll be able to find a performer. Click the button below to resend the confirmation message.": "Por favor confirme su correo electr\u00f3nico y podr\u00e1 buscar un artista. Haga clic en el bot\u00f3n de abajo para reenviar el correo de confirmaci\u00f3n. ",
        "Please confirm your email address and you'll be able to find a venue. Click the button below to resend the confirmation message.": "Por favor confirme su correo electr\u00f3nico y podr\u00e1 buscar un lugar. Haga clic en el bot\u00f3n de abajo para reenviar el correo de confirmaci\u00f3n. ",
        "Please describe your venue, including anything you plan to offer musicians, for example, what equipment you have, expected audiences, any hospitality or monetary compensation, etc (1000 character max)": "Describa su lugar, incluido todo lo que planea ofrecer a los m\u00fasicos, por ejemplo, qu\u00e9 equipo tiene, p\u00fablico esperado, cualquier hospitalidad o compensaci\u00f3n monetaria, etc. (1000 caracteres como m\u00e1ximo)",
        "Please login with your new password": "Iniciar sesi\u00f3n con su nueva contrase\u00f1a",
        "Pop": "Pop",
        "Postal Code": "C\u00f3digo postal",
        "Poster Options": "Opciones de Poster",
        "Preferred Genre": "G\u00e9nero preferido",
        "Preferred Genre(s)": "G\u00e9neros preferidos",
        "Preview": "Vista previa",
        "Previous": "Anterior",
        "Primary Buttons & Highlight Color": "Botones primarios y color de resaltado",
        "Profile Created Date": "Fecha de creaci\u00f3n del perfil",
        "Profile Status": "Estado del perfil",
        "Profiles": "Perfiles",
        "Provide any introduction you feel is helpful.": "Proporcionar cualquier presentaci\u00f3n que considere \u00fatil.",
        "Provides": "Proporciona",
        "Provides Electricity": "Proporciona Electricidad",
        "Punk": "Punk",
        "R&B": "R&B",
        "Ragtime": "Rag-time",
        "Rain Location": "Ubicaci\u00f3n por lluvia",
        "Rain or Shine": "Lluvia o sol",
        "Rain or shine": "Lluvia o sol",
        "Reactivate": "Reactivar",
        "Reggae": "Reggae",
        "Register Event": "Registrar evento",
        "Register Performer": "Registrar artista",
        "Register Venue": "Registrar lugar",
        "Registered Performers": "Artistas registrados",
        "Registered Venues": "Lugares registrados",
        "Religious": "Religioso",
        "Remember Me": "Recordarme",
        "Remove a genre from this city.": "Eliminar un g\u00e9nero de esta ciudad.",
        "Remove a venue type from this city.": "Eliminar un tipo de lugar de esta ciudad.",
        "Remove all custom email templates from this city.": "Eliminar todas las plantillas de correo electr\u00f3nico personalizadas de esta ciudad.",
        "Remove all custom genres from this city.": "Eliminar todos los g\u00e9neros personalizados de esta ciudad.",
        "Remove all custom venue types from this city.": "Eliminar todos los tipos de lugares personalizados de esta ciudad.",
        "Renew this artist for another festival.": "Renovar este artista para otro festival.",
        "Renew this city for the current festival year.": "Renovar esta ciudad para el a\u00f1o festivo actual.",
        "Renew this venue for another festival.": "Renovar este lugar para otro festival.",
        "Reply to this conversation.": "Responder a esta conversaci\u00f3n.",
        "Reply to this message.": "Responder a este mensaje.",
        "Request a Performance": "Solicitar una Presentaci\u00f3n",
        "Request a new confirmation email.": "Solicitar un nuevo correo electr\u00f3nico de confirmaci\u00f3n.",
        "Request a password reset email.": "Solicitar un correo electr\u00f3nico para restablecer la contrase\u00f1a.",
        "Request a performance from: ": "Solicitar una presentaci\u00f3n de: ",
        "Request from performer": "Solicitud del artista",
        "Request from venue": "Solicitud del lugar",
        "Request origin blocked due to CORS configuration.": "Origen de la solicitud bloqueada debido a la configuraci\u00f3n de CORS.",
        "Request to perform at: ": "Solicitar una presentaci\u00f3n en: ",
        "Requested by you": "Solicitado por ti",
        "Requested scopes do not match with scopes the OAuth client and user is authorized for.": "Los \u00e1mbitos solicitados no coinciden con los \u00e1mbitos para los que el cliente y el usuario de OAuth est\u00e1n autorizados.",
        "Requests over 1 week old": "Solicitudes de m\u00e1s de 1 semana",
        "Requests over 72 hours old": "Solicitudes de m\u00e1s de 72 horas",
        "Required field is missing": "Falta un campo obligatorio",
        "Required field is missing or empty": "Falta el campo obligatorio o est\u00e1 vac\u00edo",
        "Resend Confirmation": "Reenviar Confirmaci\u00f3n",
        "Reset": "Reiniciar",
        "Reset Password": "Restablecer la contrase\u00f1a",
        "Resize & Crop": "Cambiar tama\u00f1o y recortar",
        "Retail Shop": "Tienda",
        "Revoke city admin capabilities of a user.": "Revocar las capacidades de administraci\u00f3n de la ciudad de un usuario.",
        "Rock": "Rock",
        "Rooftop": "Terraza",
        "Samba": "Samba",
        "Save": "Guardar",
        "Save Changes": "Guardar cambios",
        "Save Festival": "Guardar festival",
        "Save Performance": "Guardar Presentaci\u00f3n",
        "Save Performances": "Guardar Presentaciones",
        "Save Request": "Guardar solicitud",
        "Save Template": "Guardar plantilla",
        "Save as CSV": "Guardar como CSV",
        "Save as Excel": "Guardar como Excel",
        "Save to Profile": "Guardar en el perfil",
        "Schedule": "Programa",
        "Schedule a Performance": "Programar una Presentaci\u00f3n",
        "Schedule a performance at: ": "Programar una presentaci\u00f3n en: ",
        "Schedule a performance from: ": "Programar una presentaci\u00f3n de: ",
        "Scheduling deadline": "Fecha l\u00edmite de programaci\u00f3n",
        "School": "Escuela",
        "Search": "Buscar",
        "Search Performers": "Buscar Artistas",
        "Search Venues": "Buscar lugares",
        "Secondary Buttons": "Botones secundarios",
        "Select a performer.": "Seleccionar un artista.",
        "Select a venue.": "Seleccionar un lugar.",
        "Select an Image": "Seleccionar una imagen",
        "Select neighborhood": "Seleccionar barrio",
        "Select one": "Seleccionar uno",
        "Select performance time:": "Seleccionar el horario de Presentaci\u00f3n:",
        "Select the city": "Seleccionar la ciudad/pa\u00eds",
        "Select up to 3 genres": "Seleccionar hasta 3 g\u00e9neros",
        "Select venue type": "Seleccionar el tipo de lugar",
        "Self-Match Profiles": "Perfiles autocompatibles",
        "Send": "Enviar",
        "Send Request": "Enviar soliciitud",
        "Send a Message": "Enviar un mensaje",
        "Send a performance request to the performer.": "Enviar una solicitud de Presentaci\u00f3n al artista",
        "Set Default to NO": "Establecer predeterminado en NO",
        "Set Default to YES": "Establecer predeterminado en SI",
        "Set New Password": "Establecer nueva contrase\u00f1a",
        "Set deadlines for users to sign up, create profiles, and create performances. All deadlines begin at midnight at the beginning of the day selected. (For instance, if you set a deadline for June 1, it will take effect on June 1st at 12:01am.) If you do not have any deadlines for participation, set all three dates to be after your festival date. These deadlines can be changed later, even after your site is open to the public.": "Establecer fechas l\u00edmite para que los usuarios se registren, creen perfiles y creen presentaciones. Todos los plazos comienzan a la medianoche del final del d\u00eda seleccionado. (Por ejemplo, si se establece una fecha l\u00edmite para el 1 de junio, entrar\u00e1 en vigencia el 2 de junio a las 12:01 am.) Si no se tiene ninguna fecha l\u00edmite para participar, establecer las tres fechas para que sean posteriores al 21 de junio. Estas fechas l\u00edmite pueden ser cambiadas m\u00e1s tarde, incluso despu\u00e9s de que su sitio est\u00e9 abierto al p\u00fablico.",
        "Set up your city\u2019s districts and neighborhoods. <span accent>Once the platform goes live for participants, you can not edit existing districts or neighborhoods, but you can add new ones.</span>": "Configurar los distritos y barrios de su ciudad. <span accent> Una vez que la plataforma se activa para los participantes, no puede editar distritos o barrios existentes, pero puede agregar nuevos. </span>",
        "Setup": "Configurar",
        "Shopping Center": "Centro comercial",
        "Showing": "Show",
        "Shy": "T\u00edmido",
        "Shy Users": "Usuarios t\u00edmidos",
        "Sidewalk": "Vereda",
        "Sign Up": "Reg\u00edstrate",
        "Sign Up for": "Registrarse para",
        "Sign Up for ": "Registrarse para ",
        "Sign up deadline": "Fecha l\u00edmite de registro",
        "Singer / Songwriter": "Cantante / compositor",
        "Ska": "Ska",
        "Social Media": "Redes sociales",
        "Sort by:": "Ordenar por:",
        "Soul": "Soul",
        "SoundCloud Tracks (optional)": "Pistas de SoundCloud (opcional)",
        "SoundCloud URL": "URL de SoundCloud",
        "Soundcloud": "Soundcloud",
        "Standards": "Est\u00e1ndares",
        "Start Time": "Hora de inicio",
        "Start from the Beginning": "Comienza desde el principio",
        "Start time (earliest to latest)": "Tiempo de inicio (de temprano a tarde)",
        "Start time (latest to earliest)": "Tiempo de inicio (de tarde a temprano)",
        "Street Address": "Direcci\u00f3n",
        "Street or Block Party": "Fiesta en la calle o en la cuadra",
        "Subject": "Asunto",
        "Submit Event for Approval": "Ingresar evento para Aprobaci\u00f3n",
        "Success": "\u00c9xito",
        "Success!": "\u00a1\u00c9xito!",
        "Super Admin": "Super Admin",
        "Swing": "Swing",
        "Switch to": "Cambiar a",
        "System": "Sistema",
        "Tell the audience a little about the venue. (300 character max)": "Cu\u00e9ntele a la audiencia un poco sobre el lugar. (300 caracteres como m\u00e1ximo)",
        "Tell the performer and the venue why you think they are a good match.": "Cu\u00e9ntele al artista y al lugar por qu\u00e9 cree que son una buena pareja.",
        "Tell us about your performer(s).": "Cu\u00e9ntenos acerca de su(s) artista(s).",
        "Terms & Conditions": "T\u00e9rminos y condiciones",
        "Terms & Conditions Can Not Be Empty": "Los t\u00e9rminos y condiciones no pueden estar vac\u00edos",
        "Thank you": "Gracias",
        "Thank you for confirming your email address.": "Gracias por confirmar su direcci\u00f3n de correo electr\u00f3nico.",
        "Thank you for registering for Make Music Day! Please confirm your email": "\u00a1Gracias por registrarse para Make Music Day! Confirme su correo electr\u00f3nico",
        "Thank you, the confirmation email has been re-sent. Please check your email inbox": "Gracias, el correo electr\u00f3nico de confirmaci\u00f3n ha sido reenviado. Revise su bandeja de entrada de correo electr\u00f3nico",
        "That email is already in use.": "Ese correo electr\u00f3nico ya est\u00e1 en uso.",
        "That genre already exists.": "Ese g\u00e9nero ya existe.",
        "That venue type already exists.": "Ese tipo de lugar ya existe.",
        "The City Admin will be in touch to confirm performances or follow up if needed. This may take a little while, but don't worry, we will get back to you!": "El administrador de la ciudad/pa\u00eds se pondr\u00e1 en contacto con usted para confirmar las presentaciones o hacer un seguimiento si es necesario. Esto puede tardar un poco, pero no se preocupe, \u00a1nos comunicaremos con usted!",
        "The SELECT query for the database succeeded, but did not return a valid value.": "La consulta SELECT (SELECCIONAR) para la base de datos se realiz\u00f3 correctamente, pero no devolvi\u00f3 un valor v\u00e1lido.",
        "The administrator has disabled this city's \"matchmaking\" features. Please continue to sign up as a venue or a performer, and you will be able to register your events.": "El administrador ha deshabilitado el emparejamiento de este sitio. Por favor proceda a registrarse como lugar o artista y, podr\u00e1 listar sus eventos.",
        "The current user is not an admin for the given city.": "El usuario actual no es un administrador de dicha ciudad.",
        "The end time cannot be before the start time.": "La hora de finalizaci\u00f3n no puede ser antes de la hora de inicio.",
        "The event has been confirmed by the artist or venue. You cannot change the time of it.": "El evento ha sido confirmado por el artista o el lugar. No se puede cambiar la hora.",
        "The event is in an unhandled status.": "El evento est\u00e1 en estado no controlado.",
        "The event request is not valid.": "La solicitud de evento no es v\u00e1lida.",
        "The genre selected is not valid for an artist.": "El g\u00e9nero seleccionado no es v\u00e1lido para un artista.",
        "The performance has been approved!": "\u00a1La presentaci\u00f3n ha sido aprobada!",
        "The performance has been canceled!": "\u00a1La presentaci\u00f3n ha sido cancelada!",
        "The performance has been created!": "\u00a1La presentaci\u00f3n ha sido creada!",
        "The performance has been updated!": "\u00a1La presentaci\u00f3n ha sido actualizada!",
        "The performer already has a confirmed event that overlaps with this performance time.": "El artista ya tiene un evento confirmado que se superpone con este horario de presentaci\u00f3n.",
        "The performer already has a confirmed event that overlaps with this performance time. Are you sure you want to go ahead?": "El artista ya tiene un evento confirmado que se superpone con este horario de presentaci\u00f3n. \u00bfDesea continuar?",
        "The performer is unavailable during the requested time.": "El artista no est\u00e1 disponible durante el horario solicitado.",
        "The performer will be notified.<br/><br/>The City Admin will be in touch to confirm or follow up if needed. This may take a little while, but don\u2019t worry, we will get back to you!": "Se notificar\u00e1 al artista. <br/> <br/> El administrador de la ciudad se comunicar\u00e1 con usted para confirmar o hacer un seguimiento si es necesario. Esto puede demorar un poco, pero no se preocupe, \u00a1nos comunicaremos con usted!",
        "The provided credentials were invalid.": "Las credenciales proporcionadas no son v\u00e1lidas.",
        "The provided credentials were unauthorized to perform this action.": "Las credenciales proporcionadas no estaban autorizadas para realizar esta acci\u00f3n.",
        "The provided district cannot be deleted until all neighbourhoods have been removed from it.": "El distrito proporcionado no se puede eliminar hasta que se hayan eliminado todos los barrios.",
        "The provided genre is a system value and cannot be deleted.": "El g\u00e9nero proporcionado es un valor del sistema y no se puede eliminar.",
        "The provided neighbourhood cannot be added without specifying a district unless all districts have been removed first.": "El barrio proporcionado no se puede agregar sin especificar un distrito a menos que todos los distritos se hayan eliminado primero.",
        "The provided object was not a City object": "El objeto proporcionado no era un objeto Ciudad",
        "The provided object was not a User object": "El objeto proporcionado no era un objeto Usuario",
        "The provided password was invalid.": "La contrase\u00f1a proporcionada no es v\u00e1lida.",
        "The provided query arguments contain an invalid key and/or value.": "Los argumentos de consulta proporcionados contienen una clave y/o valor no v\u00e1lidos.",
        "The provided timezone is invalid.": "La zona horaria proporcionada no es v\u00e1lida.",
        "The provided type was not a valid export format.": "El tipo proporcionado no era un formato de exportaci\u00f3n v\u00e1lido.",
        "The provided venue type is a system value and cannot be deleted.": "El tipo de lugar proporcionado es un valor del sistema y no se puede eliminar.",
        "The request appears in your inbox and the other person\u2019s, where you can have a conversation about the performance, adjust the time as needed, and confirm.": "La solicitud aparece en tu bandeja de entrada y en la de la otra persona, donde puedes tener una conversaci\u00f3n sobre la presentaci\u00f3n, ajustar el tiempo seg\u00fan sea necesario, y confirmar.",
        "The request cannot be fulfilled due to bad syntax.": "La solicitud no se puede cumplir debido a una sintaxis incorrecta.",
        "The request export type is not valid.": "El tipo de exportaci\u00f3n de la solicitud no es v\u00e1lido.",
        "The request requires user authentication.": "La solicitud requiere autenticaci\u00f3n de usuario.",
        "The request signature is not valid.": "La firma de la solicitud no es v\u00e1lida.",
        "The requested city is not setup in this system.": "La ciudad solicitada no est\u00e1 configurada en este sistema.",
        "The requested resource could not be located.": "No se pudo localizar el recurso solicitado.",
        "The requested resource owner could not be determined.": "No se pudo determinar el propietario del recurso solicitado.",
        "The requested time is outside the festival start or end time.": "La hora solicitada est\u00e1 fuera de la hora de inicio o finalizaci\u00f3n del festival.",
        "The reset token has expired.": "El token de reinicio ha caducado.",
        "The server does not support the functionality required to fulfill the request.": "El servidor no admite la funcionalidad requerida para cumplir con la solicitud.",
        "The server has not found anything matching the Request-URI.": "El servidor no ha encontrado nada que coincida con la Solicitud-URI.",
        "The server is currently unavailable.": "El servidor no est\u00e1 disponible actualmente.",
        "The server understood the request, but is refusing to fulfill it.": "El servidor entendi\u00f3 la solicitud, pero se niega a cumplirla.",
        "The specified city already exists.": "La ciudad especificada ya existe.",
        "The specified template type already exists for this city": "El tipo de plantilla especificado ya existe para esta ciudad",
        "The specified user account already exists.": "La cuenta de usuario especificada ya existe.",
        "The system could not send the appropriate message via email. Please try again later.": "El sistema no pudo enviar el mensaje apropiado por correo electr\u00f3nico. Int\u00e9ntelo de nuevo m\u00e1s tarde.",
        "The system could not send the email because the required template was not found.": "El sistema no pudo enviar el correo electr\u00f3nico porque no se encontr\u00f3 la plantilla requerida.",
        "The system has not been completely initialized.": "El sistema no se ha inicializado completamente.",
        "The token you are using is not attached to a user account.": "El token que est\u00e1 utilizando no est\u00e1 adjunto a una cuenta de usuario.",
        "The translation catalog was not found.": "No se encontr\u00f3 el cat\u00e1logo de traducci\u00f3n.",
        "The user with that email and password could not be found.": "No se pudo encontrar al usuario con ese correo electr\u00f3nico y contrase\u00f1a.",
        "The venue already has a confirmed event that overlaps with this performance time.": "El lugar ya tiene un evento confirmado que se superpone con este tiempo de presentaci\u00f3n.",
        "The venue already has a confirmed event that overlaps with this performance time. Are you sure you want to go ahead?": "El lugar ya tiene un evento confirmado que se superpone con este horario de presentaci\u00f3n. \u00bfEst\u00e1 seguro de que quiere continuar?",
        "The venue already has an event confirmed starting or ending during the requested times.": "El lugar ya tiene un evento confirmado que comienza o termina durante los horarios solicitados.",
        "The venue is unavailable during the requested time.": "El lugar no est\u00e1 disponible durante el horario solicitado.",
        "The venue will be notified.<br/><br/>The City Admin will be in touch to confirm or follow up if needed. This may take a little while, but don\u2019t worry, we will get back to you!": "Se notificar\u00e1 al lugar. <br/> <br/> El administrador de la ciudad se comunicar\u00e1 con usted para confirmar o hacer un seguimiento si es necesario. Esto puede demorar un poco, pero no se preocupe, \u00a1nos comunicaremos con usted!",
        "Theater or Arts Venue": "Lugar de teatro o arte",
        "There are overlapped performance times. Are you sure you want to go ahead?": "Hay horarios de presentaciones superpuestos. \u00bfDesea continuar?",
        "There are too many profile images for this user. Unable to add another.": "Hay demasiadas im\u00e1genes de perfil para este usuario. No se puede agregar otra.",
        "There is currently no active festival for this city.": "Por el momento no hay festival activo para esta ciudad/pa\u00eds.",
        "These can change later on as you and the performer discuss specifics.": "Se puede cambiar m\u00e1s adelante a medida que usted y el artista concuerden los detalles.",
        "These can change later on as you and the venue discuss specifics.": "Se puede cambiar m\u00e1s adelante a medida que usted y el lugar concuerden los detalles.",
        "This OAuth client is not allowed to create users.": "Este cliente OAuth no puede crear usuarios.",
        "This chapter is currently paused.": "El evento esta en pausa actualmente.",
        "This export may take time, be patient.": "Esta exportaci\u00f3n demorar\u00e1, se paciente.",
        "This site is not yet ready to accept new user registrations, please try again later.": "Este sitio a\u00fan no est\u00e1 listo para aceptar nuevos registros de usuarios. Vuelva a intentarlo m\u00e1s tarde.",
        "This will be included in the event listing. Tell the audience a little about the performer (1000 character max)": "Esto se incluir\u00e1 en la lista de eventos. Cu\u00e9ntele a la audiencia un poco sobre el artista (1000 caracteres como m\u00e1ximo)",
        "Time Conflict": "Conflicto de horarios",
        "Time Increments for Bookings": "Incrementos de tiempo para reservas",
        "Times & Deadlines": "Horarios y fechas l\u00edmite",
        "Timezone": "Zona Horaria",
        "To complete your registration, please confirm your email address. Just click the link in the confirmation email we sent you. Check your spam folder if you don't see the message right away.": "Para completar su registro, por favor confirme su correo electr\u00f3nico. Solo haga clic en el enlace del correo de confirmaci\u00f3n enviado. Revise su buz\u00f3n de Spam en caso no vea el mensaje enseguida.",
        "To create your event, we\u2019ll need some basic information about your venue, and performer(s).": "Para crear tu evento, necesitaremos informaci\u00f3n general sobre tu lugar, y artista(s).",
        "To create your event, we\u2019ll need the following information:": "Para crear tu evento, necesitaremos la siguiente informaci\u00f3n:",
        "To use the matching platform to find performers, fill out the following:": "Para utilizar la plataforma de emparejamiento, complete lo siguiente:",
        "Total Accounts": "Cuentas totales",
        "Total Confirmed": "Total confirmado",
        "Transfer this artist profile to another user.": "Transferir este perfil de artista a otro usuario.",
        "Transfer this venue profile to another user.": "Transferir este perfil de lugar a otro usuario.",
        "Transit Station": "Estaci\u00f3n de tr\u00e1nsito",
        "Twitter": "Twitter",
        "Twitter URL": "URL de Twitter",
        "Type": "Tipo",
        "Unable to connect to the server at the moment, please check your network connection": "No se puede conectar al servidor en este momento, verifique su conexi\u00f3n de red",
        "Unaccept this event on behalf of a venue.": "No aceptar este evento en nombre de un lugar.",
        "Unaccept this event on behalf of an artist.": "No aceptar este evento en nombre de un artista.",
        "Unaccept this event.": "No aceptar este evento.",
        "Unapprove this event.": "No aprobar este evento.",
        "Unknown object class": "Clase de objeto desconocida",
        "Unpause Chapter": "Activar sitio",
        "Unsupported object type": "Tipo de objeto no admitido",
        "Unsupported value": "Valor no admitido",
        "Update": "Actualizar",
        "Update Message": "Actualizar mensaje",
        "Update Performer": "Actualizar artista",
        "Update Profile": "Actualizar Perfil",
        "Update Venue": "Actualizar lugar",
        "Updated at": "Actualizado en",
        "Upload": "Cargar",
        "Upload a new logo for this city.": "Cargar un nuevo logo para esta ciudad.",
        "Upload a new profile image for this artist.": "Cargar una nueva imagen de perfil para este artista.",
        "Upload a profile image in preparation for creating a performer profile for this user.": "Cargar una imagen de perfil como preparaci\u00f3n para crear un perfil de artista para este usuario.",
        "Uploaded file type was invalid or not acceptable.": "El tipo de archivo cargado no es v\u00e1lido o no es aceptable.",
        "Use Districts": "Usar Distritos",
        "Use mouse to click or drag to define the image crop area": "Usar el mouse para hacer clic o arrastrar para definir el \u00e1rea de recorte de la imagen",
        "User data and profile information.": "Datos de usuario e informaci\u00f3n de perfil.",
        "Users will not be able to create a new venue or artist profile after this date.": "Los usuarios no podr\u00e1n crear un nuevo lugar o perfil de artista despu\u00e9s de esta fecha.",
        "Users will not be able to request or confirm a performance after this date, or to create their own event.": "Los usuarios no podr\u00e1n solicitar o confirmar una presentaci\u00f3n despu\u00e9s de esta fecha, ni crear su propio evento.",
        "Users will only be allowed to schedule performances within this time range.": "Los usuarios solo podr\u00e1n programar actuaciones dentro de este rango de tiempo.",
        "Validation error occurred": "Ocurri\u00f3 un error de validaci\u00f3n",
        "Venue": "Lugar de evento",
        "Venue Availability": "Disponibilidad del lugar",
        "Venue Defaults": "Valores predeterminados del lugar",
        "Venue Description (optional)": "Descripci\u00f3n del lugar (opcional)",
        "Venue Info": "Informaci\u00f3n del lugar",
        "Venue Name": "Nombre del lugar",
        "Venue Type": "Tipo de lugar",
        "Venue Types": "Tipos de lugares",
        "Venue creation is currently disabled. Try again later.": "La creaci\u00f3n de lugares est\u00e1 actualmente deshabilitada. Int\u00e9ntelo de nuevo m\u00e1s tarde.",
        "Venue does not have a piano": "El lugar no tiene piano",
        "Venue has a piano": "El lugar tiene un piano",
        "Venue seeking<br/>Performers": "Lugares en b\u00fasqueda de<br/> Artistas",
        "Venue type is already existed": "El tipo de lugar ya existe",
        "Venues": "Lugares del evento",
        "View": "Visualizar",
        "View Full Profile": "Visualizar todo el perfil",
        "Virtual Option": "Opci\u00f3n Virtual",
        "Visit Facebook": "Visitar Facebook",
        "Visit Instagram": "Visitar Instagram",
        "Visit Twitter": "Visitar Twitter",
        "Visit YouTube": "Visitar YouTube",
        "WARNING": "PELIGRO",
        "WARNING: You are about to create a new festival. Users will no longer be able to register or create events for the current festival. Are you sure you want to continue?": "PELIGRO: Est\u00e1s por crear un nuevo festival. Los usuarios ya no podr\u00e1n o crear eventos para el festival actual. \u00bfEst\u00e1s seguro que deseas continuar?",
        "Warning": "Advertencia",
        "Way to go!": "\u00a1Camino a seguir!",
        "We found one or more profiles in your account from a previous year. ": "Encontramos uno o m\u00e1s perfiles en su cuenta de un a\u00f1o previo. ",
        "We have populated your profile with the information we have on file. Please check each field and make any necessary updates.": "Hemos actualizado su perfil con la informaci\u00f3n que tenemos en archivo. Por favor revise cada campo y haga las actualizaciones necesarias.",
        "We're excited!": "\u00a1Estamos contentos!",
        "Website": "Sitio web",
        "Website (optional)": "Sitio web (opcional)",
        "Welcome Blurb": "Bienvenida",
        "Welcome back!": "\u00a1Bienvenido de vuelta!",
        "Welcome to Make Music": "Bienvenido a Make Music",
        "Welcome to the<br/>  Matching Site": "Bienvenido al sitio de emparejamiento de<br/>",
        "Welcome to the<br/> %(festival_name)s Matching Site": "Bienvenido al sitio de emparejamiento de<br/> %(festival_name)s",
        "Welcome, let's get started!": "\u00a1Bienvenido, comencemos!",
        "What kind of music is this venue looking to host?": "\u00bfQu\u00e9 tipo de m\u00fasica busca este lugar?",
        "What kind of music will this performer play?": "\u00bfQu\u00e9 tipo de m\u00fasica tocar\u00e1 este artista?",
        "Wheelchair Accessible": "Accesible con silla de ruedas",
        "When is this performer available? Include your full range, performances will be scheduled individually.": "\u00bfCu\u00e1ndo est\u00e1 disponible este artista? Incluya su rango completo, las presentaciones se programar\u00e1n individualmente.",
        "When is this venue available to host? Include your full range, performances will be scheduled individually.": "\u00bfCu\u00e1ndo est\u00e1 disponible este lugar para realizar eventos? Incluya su rango completo, las presentaciones se programar\u00e1n individualmente.",
        "When users create an account for the first time, they must agree to the \"Terms and Conditions\" to use the website. Paste your city's Terms and Conditions here. If you are using language from a previous year, please check that the dates, city names, and URLs are all up to date. This form supports HTML tags.": "Cuando los usuarios crean una cuenta por primera vez, deben aceptar los \"T\u00e9rminos y condiciones\" para utilizar el sitio web. Incluya aqu\u00ed los t\u00e9rminos y condiciones de su ciudad. Si utiliza un texto de un a\u00f1o anterior, verifique que las fechas, los nombres de las ciudades y las URL est\u00e9n actualizados. Este formulario admite etiquetas HTML.",
        "When would you like this performance?": "\u00bfCu\u00e1ndo le gustar\u00eda realizar esta presentaci\u00f3n?",
        "When would you like to perform?": "\u00bfCu\u00e1ndo te gustar\u00eda presentarte?",
        "Who is the contact person for this event?": "\u00bfQui\u00e9n es la persona de contacto para este evento?",
        "Who is the contact person for this performer?": "\u00bfQui\u00e9n es la persona de contacto de este artista?",
        "Who is the contact person for this venue?": "\u00bfQui\u00e9n es la persona de contacto para este lugar?",
        "Why do you think this performer would be a good fit?": "\u00bfPor qu\u00e9 crees que este artista es apropiado?",
        "Why is this location a good fit for you?": "\u00bfPor qu\u00e9 esta ubicaci\u00f3n es apropiada para usted?",
        "Will electricity be used for performances at this venue?": "\u00bfSe utilizar\u00e1 electricidad para las presentaciones en este lugar?",
        "World": "Mundo",
        "Write a few words about your venue.": "Escribe algunas palabras sobre tu lugar.",
        "Write a few words about yourself and your music.": "Escribe algunas palabras sobre ti y tu m\u00fasica.",
        "Yes": "s\u00ed",
        "Yes, at ": "S\u00ed, en ",
        "Yes, go ahead": "S\u00ed, contin\u00fae",
        "Yes, if necessary": "S\u00ed, si es necesario",
        "Yes, if necessary, at": "S\u00ed, si es necesario, en",
        "Yes, let them play!": "\u00a1S\u00ed, d\u00e9jalos tocar!",
        "You": "Usted",
        "You already have a confirmed event that overlaps with this performance time.": "Ya tiene un evento confirmado que se superpone con este tiempo de presentaci\u00f3n.",
        "You are about to accept this performance request.": "Est\u00e1 a punto de aceptar esta solicitud de presentaci\u00f3n.",
        "You are about to deactivate this chapter. This action makes the city's site offline completely. Are you sure you want to continue?": "Est\u00e1 a punto de desactivar este sitio. Esta acci\u00f3n har\u00e1 que la p\u00e1gina est\u00e9 completamente fuera de l\u00ednea. \u00bfDesea continuar?",
        "You are about to delete this chapter. This action cannot be undone. Are you sure you want to continue?": "Est\u00e1 a punto de eliminar este sitio. Esta acci\u00f3n no puede ser revertida. \u00bfDesea continuar?",
        "You are about to re-enable this chapter. This action brings the city's site online again. Are you sure you want to continue?": "Est\u00e1 a punto de activar este sitio. Esta acci\u00f3n har\u00e1 que la p\u00e1gina est\u00e9 en l\u00ednea nuevamente. \u00bfDesea continuar?",
        "You are required to indicate the user that is receiving ownership of this artist profile.": "Debe indicar el usuario que recibe la propiedad de este perfil de Artista.",
        "You are required to indicate the user that is receiving ownership of this venue profile.": "Debe indicar el usuario que recibe la propiedad de este perfil del lugar.",
        "You are searching for a performer for venue ": "Est\u00e1s en b\u00fasqueda de un artista para un lugar ",
        "You are searching for a venue for performer ": "Est\u00e1s en b\u00fasqueda de un lugar para un artista ",
        "You can find and book performers through our platform. Check out the <a href=\"/find\">Find Performers</a> page to get started.": "Puede buscar y reservar artistas a trav\u00e9s de nuestra plataforma. Consulte la p\u00e1gina <a href=\"/find\"> Buscar artistas </a> para comenzar.",
        "You can import past profiles and use them for  (), or create something new. If you don't import your old profiles now, you can always do so later.": "Puedes importar perfiles previos y usarlos para (), o crear algo nuevo. Si no importas tus perfiles antiguos ahora, lo puedes hacer luego.",
        "You can import past profiles and use them for %(city_name)s (%(festival_date)s), or create something new. If you don't import your old profiles now, you can always do so later.": "Puedes importar perfiles previos y usarlos para %(city_name)s (%(festival_date)s), o crear algo nuevo. Si no importas tus perfiles antiguos ahora, lo puedes hacer luego.",
        "You created an event!": "\u00a1Cre\u00f3 un evento!",
        "You have exceeded the rate limit allotted. Try again later.": "Ha superado el l\u00edmite de velocidad asignado. Int\u00e9ntelo de nuevo m\u00e1s tarde.",
        "You have no performances.": "No tiene Presentaciones.",
        "You may choose an alternate font family.": "Puede elegir una familia de fuentes alternativa.",
        "You may edit your site's welcome text here. This form supports HTML tags.": "Puede editar el texto de bienvenida de su sitio aqu\u00ed. Este formulario admite etiquetas HTML.",
        "You must accept the terms and conditions in order to proceed": "Debe aceptar los t\u00e9rminos y condiciones para continuar.",
        "You must confirm your email in order to access this feature.": "Debes confirmar tu correo electr\u00f3nico para acceder.",
        "You must enter a email address": "Debe ingresar una direcci\u00f3n de correo electr\u00f3nico",
        "You must enter a location": "Debe ingresar una ubicaci\u00f3n",
        "You must enter a name": "Debe ingresar un nombre",
        "You must enter a phone number": "Debe ingresar un n\u00famero de tel\u00e9fono",
        "You must enter performer's bio": "Debe ingresar la biograf\u00eda del artista",
        "You must enter the performer's description": "Debe ingresar la descripci\u00f3n del artista",
        "You must enter the performer's name": "Debe ingresar el nombre del artista",
        "You must enter the venue's description": "Debe ingresar la descripci\u00f3n del lugar",
        "You must enter the venue's name": "Debe ingresar el nombre del lugar",
        "You must enter the venue's notes for performers": "Debe ingresar las notas del lugar para los artistas",
        "You must pick 1 to 3 genres": "Debe elegir de 1 a 3 g\u00e9neros",
        "You must provide address and zip_code when the venue allows in person events.": "Debe proporcionar la direcci\u00f3n y el c\u00f3digo postal cuando el lugar permita eventos en persona.",
        "You must select a neighborhood": "Debe seleccionar un barrio",
        "You must select a venue type": "Debe seleccionar un tipo de lugar",
        "You must select an option": "Debe seleccionar una opci\u00f3n",
        "You must select one": "Debe seleccionar uno",
        "You must specify the district to add.": "Debe especificar el distrito a agregar.",
        "You must specify the district to delete.": "Debe especificar el distrito a eliminar.",
        "You must specify the neighbourhood to delete.": "Debe especificar el barrio que desea eliminar.",
        "You must specify the neighbourhoods to add": "Debes especificar los barrios a agregar",
        "You'll fill out a profile and be able to see who is available that might be a good fit to plan a performance with.": "Los usuarios no podr\u00e1n solicitar o confirmar una presentaci\u00f3n despu\u00e9s de esta fecha, ni crear su propio evento.",
        "You're really doing it": "Realmente lo est\u00e1 haciendo",
        "YouTube": "Youtube",
        "YouTube URL": "URL de YouTube",
        "YouTube Video (optional)": "Video de YouTube (opcional)",
        "Your Availability": "Su disponibilidad",
        "Your Event Profile has been created.<br/><br/>The City Admin will be in touch to confirm performances or follow up if needed. This may take a little while, but don't worry, we will get back to you!": "Se ha creado su perfil del evento. <br/> <br/> El administrador de la ciudad se pondr\u00e1 en contacto para confirmar las presentaciones o realizar un seguimiento si es necesario. Esto puede tardar un poco, pero no se preocupe, \u00a1nos comunicaremos con usted!",
        "Your Performance Time": "Su horario de presentaci\u00f3n",
        "Your Performer Profile has been created.": "Se ha creado su perfil de artista.",
        "Your Performer Profile has been imported.": "Su perfil de artista ha sido importado.",
        "Your Performer Profile has been updated.": "Su perfil de artista ha sido actualizado.",
        "Your Venue Profile has been updated.": "Se ha actualizado su perfil de lugar.",
        "Your confirmation token has expired. Check your inbox for a new confirmation email.": "Su token de confirmaci\u00f3n ha caducado. Revise su bandeja de entrada para recibir un nuevo correo electr\u00f3nico de confirmaci\u00f3n.",
        "Your home neighborhood (optional)": "Su barrio (opcional)",
        "Your performance request has been sent to the performer's inbox.": "Su solicitud de presentaci\u00f3n se ha enviado a la bandeja de entrada del artista.",
        "Your performance request has been sent to the venue's inbox.": "Su solicitud de presentaci\u00f3n ha sido enviada a la bandeja de entrada del lugar.",
        "Your profile will be deleted, along with all uploaded images and created events": "Se eliminar\u00e1 su perfil, junto con todas las im\u00e1genes cargadas y los eventos creados",
        "Your site is currently open to the public": "Su sitio est\u00e1 abierto al p\u00fablico actualmente",
        "Youtube URL": "URL de Youtube",
        "You\u2019re really doing it! Your venue profile has been created.": "\u00a1Realmente lo est\u00e1 haciendo! Se ha creado su perfil del lugar.",
        "You\u2019re really doing it! Your venue profile has been imported.": "\u00a1Realmente lo est\u00e1s haciendo! Tu perfil de lugar ha sido importado.",
        "ZIP": "ZIP",
        "Zip": "C\u00f3digo postal",
        "address field can not be empty": "El campo de direcci\u00f3n no puede estar vacio",
        "artist": "artista",
        "by ": "por ",
        "by %(profile_name)s": "por %(profile_name)s",
        "by City Admin": "por el administrador de la ciudad",
        "by You": "por usted",
        "cancelled": "Evento cancelado",
        "confirmed": "confirmado",
        "delete this event": "eliminar este evento",
        "delete this profile": "eliminar este perfil",
        "does not match the password": "no coincide con la contrase\u00f1a",
        "email address not found": "Direcci\u00f3n de correo electr\u00f3nico no encontrado",
        "have sent and received no requests": "no han enviado ni recibido solicitudes",
        "haven't replied to requests": "no he respondido a las solicitudes",
        "here": "aqu\u00ed",
        "in person": "Presencial",
        "in person, but will move online if necessary": "Presencial, pero se puede cambiar a virtual si es necesario",
        "include up to 3 SoundCloud tracks on your profile": "incluir hasta 3 pistas de SoundCloud en su perfil",
        "include up to 3 YouTube videos on your profile": "incluir hasta 3 videos de YouTube en su perfil",
        "minutes": "minutos",
        "new": "nuevo",
        "online only": "Solo Virtual",
        "password must be at least 8 characters": "La contrase\u00f1a debe tener al menos 8 caracteres",
        "pending": "pendiente",
        "pending city approval": "pendiente de aprobaci\u00f3n de la ciudad",
        "performers": "artistas",
        "playing at": "tocando en",
        "please enter a street address": "ingresar una direcci\u00f3n",
        "please enter a valid URL": "por favor ingrese un URL v\u00e1lido",
        "please enter a valid name": "Ingresar un nombre v\u00e1lido",
        "please enter a valid phone number": "Ingresar un n\u00famero de tel\u00e9fono v\u00e1lido",
        "please enter a valid postal code": "Ingresar un c\u00f3digo postal v\u00e1lido",
        "please enter a valid timezone": "Por favor ingrese una zona horaria v\u00e1lida",
        "please enter a valid url": "por favor ingrese un URL v\u00e1lido",
        "please move the map below to locate your city": "Por favor mueva el mapa para localizar su ciudad/pa\u00eds",
        "please read and agree with terms & conditions": "por favor lee y acepta los t\u00e9rminos y condiciones",
        "postal code can not be empty": "el c\u00f3digo postal no puede estar vac\u00edo",
        "remove": "eliminar",
        "says that no amplification is permitted at their venue. Are you sure you want to continue?": "dice que no se permite equipo de sonido en su lugar. \u00bfEst\u00e1 seguro de que quiere continuar?",
        "says that no amplification is permitted at their venue. But your performer profile says you require electricity and a PA. Are you sure you want to continue?": "dice que no se permite ning\u00fan equipo de sonido en su lugar. Pero su perfil de artista dice que necesita electricidad y equipo de sonido. \u00bfEst\u00e1 seguro de que quiere continuar?",
        "says that no amplification is permitted at their venue. But your performer profile says you require electricity. Are you sure you want to continue?": "dice que no se permite ning\u00fan equipo de sonido en su lugar. Pero su perfil de artista dice que necesita electricidad. \u00bfEst\u00e1 seguro de que quiere continuar?",
        "says that they can supply electricity, but not a PA. Your performer profile says you require a PA from the venue. Are you sure you want to continue?": "dice que puede suministrar electricidad, pero no un equipo de sonido. Su perfil de artista dice que necesita equipo de sonido en el lugar. \u00bfEst\u00e1 seguro de que quiere continuar?",
        "says that they cannot supply electricity. But your performer profile says you require electricity and a PA from the venue. Are you sure you want to continue?": "dice que no pueden suministrar electricidad. Pero su perfil de artista dice que necesita electricidad y un equipo de sonido en el lugar. \u00bfEst\u00e1 seguro de que quiere continuar?",
        "says that they cannot supply electricity. But your performer profile says you require electricity from the venue. Are you sure you want to continue?": "dice que no pueden suministrar electricidad. Pero su perfil de artista dice que necesita electricidad del lugar. \u00bfEst\u00e1 seguro de que quiere continuar?",
        "says they need electricity and a PA from the venue. But your venue profile says amplification is not allowed. Are you sure you want to continue?": "dice que necesitan electricidad y un equipo de sonido en el lugar. Pero el perfil de su lugar dice que no se permite la amplificaci\u00f3n. \u00bfEst\u00e1 seguro de que quiere continuar?",
        "says they need electricity and a PA from the venue. But your venue profile says you can't supply a PA. Are you sure you want to continue?": "dice que necesitan electricidad y equipo de sonido en el lugar. Pero el perfil de su lugar dice que no puede proporcionar equipo de sonido. \u00bfEst\u00e1 seguro de que quiere continuar?",
        "says they need electricity and a PA from the venue. But your venue profile says you can't supply either one. Are you sure you want to continue?": "dice que necesitan electricidad y equipo de sonido en el lugar. Pero el perfil de su lugar dice que no puede proporcionar ninguno. \u00bfEst\u00e1 seguro de que quiere continuar?",
        "says they need electricity from the venue. But your venue profile says amplification is not allowed. Are you sure you want to continue?": "dice que necesitan electricidad del lugar. Pero el perfil de su lugar dice que no se permite equipo de sonido. \u00bfEst\u00e1 seguro de que quiere continuar?",
        "says they need electricity from the venue. But your venue profile says you can't supply electricity. Are you sure you want to continue?": "dice que necesitan electricidad del lugar. Pero el perfil de su lugar dice que no puede suministrar electricidad. \u00bfEst\u00e1 seguro de que quiere continuar?",
        "says they will use electricity for their performance. But your venue profile says amplification is not allowed. Are you sure you want to continue?": "dice que usar\u00e1n electricidad para su presentaci\u00f3n. Pero el perfil de su lugar dice que no se permite equipo de sonido. \u00bfEst\u00e1 seguro de que quiere continuar?",
        "total": "total",
        "venue": "lugar",
        "venues": "lugares",
        "{{profile.name}}: new message from {{requestor}}!": "{{profile.name}}: \u00a1nuevo mensaje de {{requestor}}!",
        "{{profile.name}}: new request from {{requestor}}!": "{{profile.name}}: \u00a1nueva solicitud de {{requestor}}!",
        "{{profile.name}}: your event has been accepted": "{{profile.name}}: tu evento ha sido aceptado",
        "{{profile.name}}: your event has been approved": "{{profile.name}}: tu evento ha sido aprobado",
        "{{profile.name}}: your event has been canceled": "{{profile.name}}:tu evento ha sido cancelado",
        "{{profile.name}}: your event has been declined": "{{profile.name}}: tu evento ha sido denegado"
    },
    "fr_FR": {
        "%(attr_name)s is not a valid attribute of %(obj)s": "%(attr_name)s is not a valid attribute of %(obj)s",
        "%(profile_name)s Schedule": "Horaire de %(profile_name)s",
        "A password was expected, but none was provided.": "Le mot de passe \u00e9tait requis, mais n'\u00e9tait pas inclus.",
        "Accepting Requests": "D\u00e9mandes d'\u00e9v\u00e9nements accepter",
        "Account Deleted Successfully": "Compte suprimmer",
        "Active": "Actif",
        "Active as:": "S\u00e9lectionn\u00e9:",
        "Add a SoundCloud Song URL to this artist.": "Ajouter un chanson SoundCloud.",
        "Add a YouTube Video URL to this artist.": "Ajouter un vid\u00e9o YouTube.",
        "Add a genre to this city.": "Ajouter un genre \u00e0 cette ville.",
        "Add an event in this city.": "Ajoutez un \u00e9v\u00e9nement dans cette ville.",
        "Add neighbourhoods to this district.": "Ajouter des quartiers \u00e0 cette r\u00e9gion.",
        "Address": "Adresse",
        "Afrobeat": "Afrobeat",
        "Already have a performance planned and not interested in additional matches? This option is for you.": "Already have a performance planned and not interested in additional matches? This option is for you.",
        "Americana / Bluegrass": "Americana / Bluegrass",
        "An error has occurred!": "La syst\u00e8me a rencontr\u00e9 un erreur!",
        "An internal error prevented the request from being handled.": "Un erreur a bloqu\u00e9 votre demande.",
        "An unhandled exception has occurred!": "La syst\u00e8me a rencontr\u00e9 un erreur!",
        "Artist": "Musicien",
        "Artist creation is currently disabled. Try again later.": "L'ajout des musiciens est pr\u00e9sentement d\u00e9sactiv\u00e9.",
        "Ascending": "Ordre croissant",
        "Availability": "Disponibilit\u00e9",
        "Availability End Time": "Fin de disponibilit\u00e9",
        "Availability Start Time": "D\u00e9but de disponibilit\u00e9",
        "Beach": "Plage",
        "Blues": "Blues",
        "Boat": "Bateau",
        "Both in-person and online": "Pr\u00e9sentiel et en-ligne",
        "Brass": "Brass",
        "Cabaret": "Cabaret",
        "Cancelled": "Cancell\u00e9",
        "Cannot serialize; no default schema has been defined for %(classname)s": "Cannot serialize; no default schema has been defined for %(classname)s",
        "Celtic": "Celtic",
        "Choral": "Choral",
        "City": "Ville",
        "City Administrator": "Administrateur de la ville",
        "Classical": "Classique",
        "Community Center": "Centre communautaire",
        "Contact Email": "Courriel de contacte",
        "Contact Name": "Nom de contacte",
        "Contact Phone": "T\u00e9l de contacte",
        "Could not create a OAuth token. Perhaps missing scopes?": "Could not create a OAuth token. Perhaps missing scopes?",
        "Country": "Country",
        "Create a new conversation for this artist.": "Commencer un conversation pour le musicien.",
        "Create a new event for this artist.": "Ajouter un \u00e9v\u00e9nement au musicien.",
        "Create a new self-match event for this artist.": "Ajouter un \u00e9v\u00e9nement \u00abself-match\u00bb au musicien.",
        "Created at": "Cr\u00e9e \u00e0",
        "Deactivate": "D\u00e9sactivez",
        "Delete this artist.": "Supprimez ce musicien.",
        "Delete this district.": "Supprimez cette r\u00e9gion.",
        "Delete this neighbourhood.": "Supprimez ce quartier.",
        "Delete this user account.": "Delete this user account.",
        "Descending": "Ordre d\u00e9croissant",
        "Description": "Description",
        "District": "R\u00e9gion",
        "Early Music": "Early Music",
        "East Asian": "East Asian",
        "Eastern European": "Eastern European",
        "Edit this artist.": "Modifiez ce musicien.",
        "Edit this city.": "Modifiez cette ville.",
        "Edit this district.": "Modifiez cette r\u00e9gion.",
        "Edit this neighbourhood.": "Modifiez ce quartier.",
        "Electricity Needs": "Besoins \u00e9l\u00e9ctriques",
        "Electronic": "Electronic",
        "Email": "Courriel",
        "Email Address": "Courriel",
        "End Time": "Heure de fin",
        "Enter email address": "Courriel",
        "Event": "\u00c9v\u00e9nement",
        "Event Accepted": "L'\u00e9v\u00e9nement \u00e9tait accept\u00e9",
        "Event Approved": "L'\u00e9v\u00e9nement approuv\u00e9",
        "Event Cancelled": "L'\u00e9venement cancell\u00e9",
        "Event Declined": "L'\u00e9v\u00e9nement \u00e9tait r\u00e9fus\u00e9",
        "Event Pending": "L'\u00e9venement en attente",
        "Event creation is currently disabled. Try again later.": "Les demandes d'\u00e9v\u00e9nement sont pr\u00e9sentement d\u00e9sactiv\u00e9.",
        "Event has been accepted and is pending festival organizer approval": "L'\u00e9v\u00e9nement \u00e9tait accept\u00e9 et attends l'approbation de l'organisateur de festival",
        "Event has been aceepted and been approved by the festival organizers": "L'\u00e9v\u00e9nement \u00e9tait accept\u00e9 et approuv\u00e9 par l'organisateur de festival",
        "Event has been cancelled": "L'\u00e9v\u00e9nement \u00e9tait annul\u00e9",
        "Event has been requested": "L'\u00e9v\u00e9nement \u00e9tait d\u00e9mand\u00e9",
        "Experimental": "Experimental",
        "Farm": "F\u00e9rme",
        "Field": "Champs",
        "Find Venues": "Trouvez un lieu",
        "First Name": "Pr\u00e9nom",
        "Folk": "Folk",
        "Full artist information.": "Renseignments de musicien compl\u00e8te.",
        "Full city information.": "Renseignments de la ville compl\u00e8te.",
        "Funk": "Funk",
        "Garden": "Jardin",
        "Genres": "Genres",
        "Get all conversations that this city's admin has commented on.": "Obtenir les conversations de l'administrateur de ville.",
        "Get all genres for this city.": "Afficher les genres pour cette ville.",
        "Get conversations that this artist is a part of.": "Obtenir les conversations de ce musicien.",
        "Gospel": "Gospel",
        "Great": "\u00c9xcellent",
        "Has Artist Confirmed?": "Accept\u00e9 par le musicien?",
        "Has City Confirmed?": "Accept\u00e9 par la ville?",
        "Has Piano": "Piano sur lieu",
        "Has Venue Confirmed?": "Accept\u00e9 par le lieu?",
        "Has confirmed email?": "Valid\u00e9 courriel?",
        "Health check FAILED due to 'SELECT NOW()' raising an exception.": "Health check FAILED due to 'SELECT NOW()' raising an exception.",
        "Hi %(profile_name)s": "Bonjour %(profile_name)s",
        "Highlife": "Highlife",
        "Hip-Hop": "Hip-Hop",
        "Hospital or Senior Center": "H\u00f4pital ou r\u00e9sidence",
        "I can provide both electricity and a PA": "Je peux fournir de l'\u00e9lectricit\u00e9 et un PA.",
        "I can provide electricity, but not a PA": "Je peux fournir de l'\u00e9lectricit\u00e9, mais non un PA.",
        "I can't provide electricity or a PA, but performers may bring their own": "Je ne peux pas fournir de l'\u00e9lectricit\u00e9, ni un PA",
        "I need electricity and a PA from the venue, or else I can't perform": "J'ai besoin de l'\u00e9lectricit\u00e9 et un PA de la salle.",
        "I need electricity from the venue, or else I can't perform": "J'ai besoin de l'\u00e9lectricit\u00e9 de la salle.",
        "I need electricity, but I can provide my own batteries or generator if necessary": "Je vais apporter mon propre source d'\u00e9lectricit\u00e9, si n\u00e9cessaire.",
        "I will not use amplification (no amps, mics, or speakers) and I don't need electricity": "Je ne vais pas utiliser l'\u00e9lectricit\u00e9",
        "I'd love to have electricity and a PA provided, but I'm flexible and will make it work regardless": "J'aimerai utiliser l'\u00e9lectricit\u00e9, mais pourvoirai jouer m\u00eame sans \u00e9lectricit\u00e9",
        "Image (optional, but strongly encouraged)": "Image (encourag\u00e9)",
        "Image URL": "Lien Image",
        "Images (optional, but strongly encouraged)": "Images (encourag\u00e9)",
        "In Case of Rain": "En cas de pluie",
        "In-person": "Pr\u00e9sentiel",
        "In-person, but will move online if necessary": "Pr\u00e9sentiel, si possible. En-ligne, si non.",
        "Indian": "Indian",
        "Indie-Folk": "Indie-Folk",
        "Indie-Rock": "Indie-Rock",
        "Invalid Basic credentials in Authorization header. The OAuth client ID and secret must be provided to create a user.": "Invalid Basic credentials in Authorization header. The OAuth client ID and secret must be provided to create a user.",
        "Invalid data type": "Invalid data type",
        "Invalid email address": "Le courriel fourni est invalide",
        "Invalid email address.": "Le courriel fourni est invalide.",
        "Invalid value": "Valeur invalide",
        "Jazz": "Jazz",
        "Kids": "Kids",
        "Last Name": "Nom",
        "Latin": "Latin",
        "Lesson": "Le\u00e7on",
        "Lessons": "Le\u00e7ons",
        "Library": "Biblioth\u00e9que",
        "List confirmed events for this artist.": "Afficher les \u00e9v\u00e9nements confirm\u00e9s de ce musicien.",
        "List events for this artist.": "Afficher les \u00e9v\u00e9nements de ce musicien.",
        "Livestreaming website (optional)": "Site web de diffusion en direct (facultatif)",
        "MPB": "MPB",
        "Make Music Day is a celebration of music on the summer solstice that is performed by anyone and enjoyed by everyone. You're invited to take part in the festivities and help show off our local talent and join the public celebration.\n\nThe festival is on June 21st and goes all day. It takes place in over 1,000 cities around the world.": "La F\u00eate de la musique est un c\u00e9l\u00e9bration de musique pendant le solstice d'\u00e9t\u00e9 que n'importe qui peut particip\u00e9 ou s'amuser. Vous \u00eates invit\u00e9 a participer en r\u00e9v\u00e9ler notre talent locale et joindre la c\u00e9l\u00e9bration.\n\nLa f\u00eate est le 21 juin pendant toute la journ\u00e9e. Il y a plusiers villes qui participe autour du monde entier.",
        "Market": "March\u00e9 publique",
        "Message from Admin": "Message d'un administrateur",
        "Metal": "Metal",
        "Middle Eastern": "Middle Eastern",
        "Missing data for required field.": "Missing data for required field.",
        "Missing required field": "Il manque unew valeur requise",
        "Missing soundcloud_song_urls": "soundcloud_song_urls est requis",
        "Missing youtube_video_urls": "youtube_video_urls est requis",
        "Musical Theater": "Musical Theater",
        "Name": "Nom",
        "Native American": "Native American",
        "Neighborhood": "Quartier",
        "New Message": "Nouveau m\u00e9ssage",
        "Next: Availability": "Disponibilit\u00e9",
        "No amplification of any kind (amps, mics, or speakers) is permitted at this site": "Aucune amplification est autoris\u00e9e \u00e0 cet endroit.",
        "No such schema %(classname)s in package %(path)s exists!": "No such schema %(classname)s in package %(path)s exists!",
        "Notes for Performers": "Remarques pour les musiciens",
        "Online only": "En-ligne",
        "Open to Anything": "N'importe quel genre",
        "Opera": "Opera",
        "Other": "Other",
        "Park": "Parc",
        "Parking Lot": "Stationnement",
        "Participatory": "Participatif",
        "Password Reset": "Password Reset",
        "Password Reset Requested": "R\u00e9initialisation du mot de passe demand\u00e9e",
        "Password Update Notification": "Notification de mise \u00e0 jour du mot de passe",
        "Pending": "En attente",
        "Performance Cancelled": "Annul\u00e9 en cas de pluie",
        "Performance will be canceled": "Annul\u00e9 en cas de pluie",
        "Performance will go on as scheduled": "Quel que soit le temps",
        "Performance will move to a nearby sheltered place": "En cas de pluie, sera transf\u00e9r\u00e9 sous abri",
        "Performances": "\u00c9venements",
        "Phone": "T\u00e9l",
        "Pop": "Pop",
        "Postal Code": "Code postale",
        "Profile Created Date": "Date de cr\u00e9ation du profil",
        "Provides": "Fournit",
        "Punk": "Punk",
        "R&B": "R&B",
        "Ragtime": "Ragtime",
        "Rain Location": "Lieu s\u00e9condaire",
        "Reactivate": "Activez",
        "Reggae": "Reggae",
        "Religious": "Religious",
        "Remove a genre from this city.": "Supprimez un genre de cette ville.",
        "Remove all custom genres from this city.": "Supprimez tous les genres p\u00e9rsonnalis\u00e9s de cette ville.",
        "Renew this artist for another festival.": "Renouvellez ce musicien dans le festival courant.",
        "Renew this city for the current festival year.": "Renouvellez cette ville pour cette ann\u00e9e.",
        "Request a password reset email.": "Request a password reset email.",
        "Requested scopes do not match with scopes the OAuth client and user is authorized for.": "Requested scopes do not match with scopes the OAuth client and user is authorized for.",
        "Required field is missing": "Il manque une valeur requise",
        "Required field is missing or empty": "Il manque une valeur requise",
        "Rock": "Rock",
        "Rooftop": "Toit",
        "Samba": "Samba",
        "School": "\u00c9cole",
        "Sidewalk": "Trottoir",
        "Singer / Songwriter": "Singer / Songwriter",
        "Ska": "Ska",
        "Soul": "Soul",
        "SoundCloud Tracks (optional)": "Liens SoundCloud",
        "SoundCloud URL": "Profile SoundCloud",
        "Standards": "Standards",
        "Start Time": "Heure de d\u00e9part",
        "Street or Block Party": "Dans la rue",
        "Swing": "Swing",
        "Thank you for registering for Make Music Day! Please confirm your email": "Merci d'avoir inscrire pour La f\u00eate de la musique! Validez ton adresse courriel, SVP.",
        "That email is already in use.": "Cette courriel est d\u00e9j\u00e0 utiliser.",
        "That genre already exists.": "Ce genre est d\u00e9j\u00e0 existant.",
        "That venue type already exists.": "Ce type de lieu est d\u00e9j\u00e0 existant.",
        "The SELECT query for the database succeeded, but did not return a valid value.": "The SELECT query for the database succeeded, but did not return a valid value.",
        "The current user is not an admin for the given city.": "Tu n'est pas un administrateur pour cette ville.",
        "The end time cannot be before the start time.": "L'heure de la fin ne peut pas \u00eatre inferieure que l'heure du d\u00e9but.",
        "The event has been confirmed by the artist or venue. You cannot change the time of it.": "L'\u00e9v\u00e9nement est d\u00e9j\u00e0 confirm\u00e9 soit par le musicien ou le lieu. L'heure ne peut plus \u00eatre modifier.",
        "The event is in an unhandled status.": "L'\u00e9tat d'\u00e9v\u00e9nement est invalide.",
        "The event request is not valid.": "La demande d'\u00e9v\u00e9nement n'est pas valide.",
        "The genre selected is not valid for an artist.": "La genre sp\u00e9cifi\u00e9 n'est pas valide pour des musiciens.",
        "The performer already has a confirmed event that overlaps with this performance time.": "Le musicien a d\u00e9j\u00e0 un \u00e9v\u00e9nement confirm\u00e9 durant les heures indiqu\u00e9s.",
        "The performer is unavailable during the requested time.": "Le musicien n'est pas disponible durant l'heures indiqu\u00e9s.",
        "The provided credentials were invalid.": "L'identification fournit n'est pas valide.",
        "The provided credentials were unauthorized to perform this action.": "Tu n'est pas autoris\u00e9 \u00e0 effectuer cette action.",
        "The provided district cannot be deleted until all neighbourhoods have been removed from it.": "La r\u00e9gion sp\u00e9cifi\u00e9e ne peut pas \u00eatre supprimer car des quartiers associ\u00e9s \u00e9xistent.",
        "The provided neighbourhood cannot be added without specifying a district unless all districts have been removed first.": "Le quartier sp\u00e9cifi\u00e9 ne peut pas \u00eatre ajouter sans indiquer la r\u00e9gion aussi, sauf si toutes les r\u00e9gions sont supprim\u00e9es.",
        "The provided password was invalid.": "Le mot de pass est invalide.",
        "The provided query arguments contain an invalid key and/or value.": "Une des valeurs soumisses \u00e9taient invalide.",
        "The provided timezone is invalid.": "Le fuseau horaire sp\u00e9cifi\u00e9 n'est pas valide.",
        "The request requires user authentication.": "La demande n\u00e9cessite une authentification de l'utilisateur.",
        "The requested time is outside the festival start or end time.": "L'heure indiqu\u00e9 est hors de l'horaire de festival.",
        "The reset token has expired.": "Le jeton 'reset' est expir\u00e9.",
        "The server is currently unavailable.": "Le serveur est incapable de r\u00e9pondre.",
        "The specified city already exists.": "La ville est d\u00e9j\u00e0 existante.",
        "The specified user account already exists.": "Le compte specifi\u00e9 est d\u00e9j\u00e0 existante.",
        "The system could not send the appropriate message via email. Please try again later.": "Le message n'\u00e9tait pas envoy\u00e9.",
        "The system could not send the email because the required template was not found.": "Le message n'\u00e9tait pas envoy\u00e9.",
        "The token you are using is not attached to a user account.": "The token you are using is not attached to a user account.",
        "The user with that email and password could not be found.": "The user with that email and password could not be found.",
        "The venue already has an event confirmed starting or ending during the requested times.": "Le lieu a d\u00e9j\u00e0 un \u00e9v\u00e9nement confirm\u00e9 durant les heures indiqu\u00e9s.",
        "The venue is unavailable during the requested time.": "Le lieu n'est pas disponible durant les heures indiqu\u00e9s.",
        "Theater or Arts Venue": "Th\u00e9atre",
        "There are too many profile images for this user. Unable to add another.": "Le maximum nombre d'images est d\u00e9j\u00e0 atteint.",
        "This OAuth client is not allowed to create users.": "This OAuth client is not allowed to create users.",
        "This chapter is currently paused.": "Cette ville est pr\u00e9sentement d\u00e9sactiv\u00e9.",
        "Transfer this artist profile to another user.": "Transferrez ce profil musicien \u00e0 un autre utilisateur.",
        "Transit Station": "Gare",
        "Unknown object class": "Unknown object class",
        "Unsupported object type": "Unsupported object type",
        "Unsupported value": "Valeur non-support\u00e9",
        "Update Message": "Modifiez la m\u00e9ssage",
        "Updated at": "Mis-a-jour \u00e0",
        "Upload a new logo for this city.": "T\u00e9l\u00e9chargez une image nouvelle pour cette ville.",
        "Upload a new profile image for this artist.": "Ajouter une image nouvelle pour le profil de ce musicien.",
        "Uploaded file type was invalid or not acceptable.": "Le fichier n'\u00e9tait pas accept\u00e9.",
        "User data and profile information.": "User data and profile information.",
        "Venue": "Lieu",
        "Venue creation is currently disabled. Try again later.": "L'ajout des lieux est pr\u00e9sentement d\u00e9sactiv\u00e9.",
        "Virtual Option": "Option virtuelle",
        "WARNING": "ATTENTION",
        "Website": "Site web",
        "Welcome, let's get started!": "Bienvenue, commen\u00e7ons!",
        "Wheelchair Accessible": "Accessible aux fauteuils roulants",
        "World": "World",
        "You are required to indicate the user that is receiving ownership of this artist profile.": "L'utilisateur d\u00e9stinaire doit \u00eatre sp\u00e9cifi\u00e9.",
        "You must confirm your email in order to access this feature.": "SVP validez ton courriel pour acc\u00e9der \u00e0 cette fonctionnalit\u00e9.",
        "You must specify the district to add.": "La r\u00e9gion \u00e0 ajouter n'\u00e9tait pas indiqu\u00e9.",
        "You must specify the district to delete.": "La r\u00e9gion \u00e0 supprimer n'\u00e9tait pas indiqu\u00e9.",
        "You must specify the neighbourhood to delete.": "Le quartier \u00e0 ajouter n'\u00e9tait pas indiqu\u00e9.",
        "You must specify the neighbourhoods to add": "Le quartier \u00e0 supprimer n'\u00e9tait pas indiqu\u00e9",
        "You'll fill out a profile and be able to see who is available that might be a good fit to plan a performance with.": "You'll fill out a profile and be able to see who is available that might be a good fit to plan a performance with.",
        "YouTube URL": "Profile YouTube",
        "YouTube Video (optional)": "Liens YouTube",
        "ZIP": "Code postale",
        "Zip": "Code postale",
        "artist": "musicien",
        "cancelled": "cancell\u00e9",
        "venue": "lieu"
    },
    "pt_BR": {
        "%(attr_name)s is not a valid attribute of %(obj)s": "%(attr_name)s n\u00e3o \u00e9 um atributo v\u00e1lido de %(obj)s",
        "%(enum_value)s is not an accepted value.": "%(enum_value)s n\u00e3o \u00e9 um valor aceito.",
        "%(profile_name)s Schedule": "Agenda de %(profile_name)s",
        "A cappella / Barbershop": "A cappella",
        "A password was expected, but none was provided.": "Uma senha era esperada, mas nenhuma foi fornecida.",
        "Accent Color": "Cor de destaque",
        "Accept": "Aceitar",
        "Accept Performance": "Aceitar performance",
        "Accept this event on behalf of a venue.": "Aceite este evento em nome de um local.",
        "Accept this event on behalf of an artist.": "Aceite este evento em nome de um artista.",
        "Accept this event.": "Aceite este evento.",
        "Accept, Decline or Change Time of this performance": "Aceitar, recusar ou alterar o tempo desta performance",
        "Accepting Requests": "Aceitando solicita\u00e7\u00f5es",
        "Accepting Requests for Performances": "Aceitar solicita\u00e7\u00f5es de apresenta\u00e7\u00f5es",
        "Accepting Requests from Performers": "Aceitando solicita\u00e7\u00f5es de artistas",
        "Account": "Conta",
        "Account Deleted Successfully": "Conta exclu\u00edda com sucesso",
        "Account Settings": "Configura\u00e7\u00f5es da conta",
        "Active as:": "Ativo como:",
        "Add": "Adicionar",
        "Add Another Performer": "Adicionar outro artista",
        "Add a SoundCloud Song URL to this artist.": "Adicione um URL de m\u00fasica do SoundCloud a este artista.",
        "Add a YouTube Video URL to this artist.": "Adicione um URL de v\u00eddeo do YouTube para este artista.",
        "Add a genre to this city.": "Adicione um g\u00eanero a esta cidade.",
        "Add a venue type to this city.": "Adicione um tipo de local a esta cidade.",
        "Add an event in this city.": "Adicione um evento nesta cidade.",
        "Add districts to this city.": "Adicione distritos a esta cidade.",
        "Add neighbourhoods to this city.": "Adicione bairros a esta cidade.",
        "Add neighbourhoods to this district.": "Adicione bairros a este distrito.",
        "Add template to this city.": "Adicione um modelo a esta cidade.",
        "Add to Event": "Adicionar ao Evento",
        "Adding your web profiles is strongly recommended! These links will be displayed on your public profile and performance listings.": "Adicionar seus perfis da web \u00e9 altamente recomendado! Esses links ser\u00e3o exibidos em seu perfil p\u00fablico e listas de desempenho.",
        "Address": "Endere\u00e7o",
        "Afrobeat": "Afrobeat",
        "After going public, you will no longer be able to change your site's neighborhood names, venue types, genres, or time increments.<br/><br/>Are you sure you want to go public now?": "Depois de tornar-se p\u00fablico, voc\u00ea n\u00e3o poder\u00e1 mais alterar os nomes dos bairros, tipos de local, g\u00eaneros ou incrementos de tempo do seu site. <br/> <br/> Tem certeza de que deseja tornar-se p\u00fablico agora?",
        "Alert": "Alerta",
        "All": "Tudo",
        "All Conversations": "Todas as conversas",
        "Almost there, now select the performance time": "Arraste os controles deslizantes para selecionar o tempo de desempenho",
        "Almost there, now select your performance time.": "Arraste o controle deslizante para selecionar o tempo de execu\u00e7\u00e3o.",
        "Already have a performance planned and not interested in additional matches? This option is for you.": "J\u00e1 tem uma performance planejada e n\u00e3o est\u00e1 interessado em partidas adicionais? Esta op\u00e7\u00e3o \u00e9 para voc\u00ea.",
        "Alternative Images": "Imagens Alternativas",
        "Americana / Bluegrass": "Americana / Bluegrass",
        "Amphitheater or Bandstand": "Anfiteatro ou Coreto",
        "An API Exception occurred": "Ocorreu uma exce\u00e7\u00e3o de API",
        "An OAuth2 related error has occurred.": "Ocorreu um erro relacionado ao OAuth2.",
        "An account with this email address already exists. Click <a href=\"/account/login\">here</a> to login": "J\u00e1 existe uma conta com este endere\u00e7o de e-mail. Clique <a href=\"/account/login\"> aqui </a> para fazer o login",
        "An error has occurred!": "Ocorreu um erro!",
        "An internal error prevented the request from being handled.": "Um erro interno impediu que a solicita\u00e7\u00e3o fosse tratada.",
        "An unhandled exception has occurred!": "Ocorreu uma exce\u00e7\u00e3o n\u00e3o tratada!",
        "App config is missing \"BASE_HOSTNAME\"": "A configura\u00e7\u00e3o do aplicativo n\u00e3o cont\u00e9m \"BASE_HOSTNAME\"",
        "Approve": "Aprovar",
        "Approve Performance": "Aprovar Desempenho",
        "Approve this event.": "Aprove este evento.",
        "Are you a venue or a performer?": "Voc\u00ea \u00e9 um local de encontro ou um artista?",
        "Are you flexible about timing? How long a set could the performer play?": "Voc\u00ea \u00e9 flex\u00edvel quanto ao tempo? Por quanto tempo um set o artista poderia tocar?",
        "Are you flexible about what time you can perform here? How long is your set?": "Voc\u00ea \u00e9 flex\u00edvel sobre os hor\u00e1rios em que pode se apresentar aqui? Qual a dura\u00e7\u00e3o do seu conjunto?",
        "Are you looking for matches, or just want to list your event?": "Voc\u00ea est\u00e1 procurando jogos ou apenas quer listar seu evento?",
        "Are you sure you want to add this new venue type? This action cannot be undone.": "Tem certeza de que deseja adicionar este novo tipo de local? Essa a\u00e7\u00e3o n\u00e3o pode ser desfeita.",
        "Are you sure?": "Voc\u00ea tem certeza?",
        "Artist": "Artista",
        "Artist creation is currently disabled. Try again later.": "A cria\u00e7\u00e3o de artista est\u00e1 atualmente desativada. Tente mais tarde.",
        "As a performer, you can create an event at a venue you've secured on your own. Once approved by the City Admin, your event will be listed on our event website.": "Como artista, voc\u00ea pode criar um evento em um local que voc\u00ea mesmo reservou. Depois de aprovado pelo administrador da cidade, seu evento ser\u00e1 listado em nosso site de eventos.",
        "As a venue, you can list events with performances you\u2019ve found on your own. Once approved by the City Admin, your event will be listed on our event website.": "Como local, voc\u00ea pode listar eventos com apresenta\u00e7\u00f5es que encontrou por conta pr\u00f3pria. Depois de aprovado pelo administrador da cidade, seu evento ser\u00e1 listado em nosso site de eventos.",
        "Ascending": "Ascendente",
        "Availability": "Disponibilidade",
        "Availability End Time": "Hor\u00e1rio de t\u00e9rmino",
        "Availability Start Time": "Hor\u00e1rio de in\u00edcio",
        "Available template variables": "Vari\u00e1veis \u200b\u200bde modelo dispon\u00edveis",
        "Awaiting approval from City Admin": "Aguardando aprova\u00e7\u00e3o do administrador da cidade",
        "Back": "Voltar",
        "Bar, Caf\u00e9, or Restaurant": "Bar, Caf\u00e9 ou Restaurante",
        "Beach": "Praia",
        "Bio": "Bio",
        "Blues": "Blues",
        "Boat": "Barco",
        "Both in-person and online": "Tanto pessoalmente quanto online",
        "Brass": "Metal",
        "Cabaret": "Cabar\u00e9",
        "Cancel": "Cancelar",
        "Cancelled": "Cancelar",
        "Cannot serialize; no default schema has been defined for %(classname)s": "N\u00e3o \u00e9 poss\u00edvel serializar; nenhum esquema padr\u00e3o foi definido para %(classname)s",
        "Celtic": "C\u00e9ltico",
        "Change": "Mudar",
        "Change Time": "Mudan\u00e7a de tempo",
        "Choose whether your users can schedule events in 5 minute increments (such as 5:20pm to 5:55pm), or if they must use 15 minute increments (such as 5:15pm to 6:00pm). Most cities use 15 minute increments. <span accent>Once your site is open to the public, this setting cannot be changed.</span>": "Escolha se seus usu\u00e1rios podem agendar eventos em incrementos de 5 minutos (como 17h20 \u00e0s 17h55) ou se devem usar incrementos de 15 minutos (como 17h15 \u00e0s 18h00). A maioria das cidades usa incrementos de 15 minutos. <span accent> Depois que seu site for aberto ao p\u00fablico, essa configura\u00e7\u00e3o n\u00e3o poder\u00e1 ser alterada. </span>",
        "Choral": "Coral",
        "City": "Cidade",
        "City Admin": "Administrador da cidade",
        "City Administrator": "Administrador da cidade",
        "City Center": "Centro Comunit\u00e1rio",
        "Classical": "Cl\u00e1ssico",
        "Close": "Perto",
        "Colors": "Cores",
        "Comma-separated values": "Valores Separados Por Virgula",
        "Community Center": "Centro Comunit\u00e1rio",
        "Complete Profile": "exclua este perfil",
        "Confirm": "Confirme",
        "Confirm New Password": "Confirme a nova senha",
        "Confirm Password": "Confirme a Senha",
        "Confirmed": "confirmado",
        "Confirmed Performances": "Performances confirmadas",
        "Congratulations! You are ready to open your site to the public.": "Parab\u00e9ns! Voc\u00ea est\u00e1 pronto para abrir seu site ao p\u00fablico.",
        "Contact": "Contato",
        "Contact Email": "Email de contato",
        "Contact Info": "Informa\u00e7\u00e3o de contato",
        "Contact Information": "Informa\u00e7\u00f5es de Contato",
        "Contact Name": "Nome de contato",
        "Contact Phone": "Telefone de contato",
        "Contact Us": "Contate-Nos",
        "Content-type header must be one of %(mime_types)s": "O cabe\u00e7alho do tipo de conte\u00fado deve ser um de %(mime_types)s",
        "Continue": "Continuou",
        "Continue Anyway": "De qualquer forma, continue",
        "Conversations": "Conversas",
        "Could not create a OAuth token. Perhaps missing scopes?": "N\u00e3o foi poss\u00edvel criar um token OAuth. Talvez faltando escopos?",
        "Country": "Pa\u00eds",
        "Courtyard": "P\u00e1tio",
        "Create Account": "Criar Conta",
        "Create An Event": "Crie um evento",
        "Create Event": "Criar Evento",
        "Create New Festival": "Crie uma nova conversa.",
        "Create Your Own Event": "Crie seu pr\u00f3prio evento",
        "Create a Matchmaking Profile": "Crie um Perfil de Matchmaking",
        "Create a Performer Profile": "Crie um perfil de artista",
        "Create a Venue Profile": "Crie um Perfil do Local",
        "Create a new conversation for this artist.": "Crie uma nova conversa para este artista.",
        "Create a new conversation for this venue.": "Crie uma nova conversa para este local.",
        "Create a new conversation.": "Crie uma nova conversa.",
        "Create a new event for this artist.": "Crie um novo evento para este artista.",
        "Create a new event for this venue.": "Crie um novo evento para este local.",
        "Create a new performer profile for this user.": "Crie um novo perfil de artista para este usu\u00e1rio.",
        "Create a new self-match event for this artist.": "Crie um novo evento de auto-correspond\u00eancia para este artista.",
        "Create a new self-match event for this user.": "Crie um novo evento de correspond\u00eancia autom\u00e1tica para este usu\u00e1rio.",
        "Create a new self-match event for this venue.": "Crie um novo evento de auto-correspond\u00eancia para este local.",
        "Create a new venue profile for this user.": "Crie um novo perfil de local para este usu\u00e1rio.",
        "Created at": "Criado em",
        "Current City Locale": "Local da cidade atual",
        "Current Font Family": "Fam\u00edlia de fontes atual",
        "Custom CSS": "CSS customizado",
        "Customize by uploading your city\u2019s event logo. It will replace the Make Music logo in the upper left. <br/>File format should be jpg or png. A transparent png will look best. Area available for logo is 150px x 150px.": "Personalize enviando o logotipo do evento de sua cidade. Ele substituir\u00e1 o logotipo do Make Music no canto superior esquerdo. <br/> O formato do arquivo deve ser jpg ou png. Um png transparente ficar\u00e1 melhor. A \u00e1rea dispon\u00edvel para logotipo \u00e9 150px x 150px.",
        "Customize the platform colors, fonts and logo.": "Personalize as cores, fontes e logotipo da plataforma.",
        "Dashboard": "Painel de controle",
        "Deadline To Register": "Prazo para inscri\u00e7\u00e3o",
        "Deadline to Create Profile": "Prazo para Criar Perfil",
        "Deadline to Register": "Prazo para inscri\u00e7\u00e3o",
        "Deadline to Request or Confirm a Performance": "Prazo para solicitar ou confirmar uma apresenta\u00e7\u00e3o",
        "Deadlines": "Prazos",
        "Decline": "Decl\u00ednio",
        "Default": "Imagem Padr\u00e3o",
        "Default Image": "Imagem Padr\u00e3o",
        "Define the musical genres performers and venues will be able to select. <span accent>Once the platform goes live for participants, you can not delete existing genres, but you can add new ones.</span>": "Defina os g\u00eaneros musicais que os int\u00e9rpretes e locais poder\u00e3o selecionar. <span accent> Assim que a plataforma entrar no ar para os participantes, voc\u00ea n\u00e3o pode excluir g\u00eaneros existentes, mas pode adicionar novos. </span>",
        "Define the types of venues that users will be able to select. <span accent>Once the platform goes live for participants, you cannot delete existing venue types, but you can add new ones.</span>": "Defina os tipos de locais que os usu\u00e1rios poder\u00e3o selecionar. <span accent> Assim que a plataforma entrar no ar para os participantes, voc\u00ea n\u00e3o pode excluir os tipos de local existentes, mas pode adicionar novos. </span>",
        "Delete": "Excluir",
        "Delete Account": "Deletar conta",
        "Delete Performance": "Excluir desempenho",
        "Delete Performance for:": "Excluir desempenho para:",
        "Delete this artist.": "Exclua este artista.",
        "Delete this district.": "Exclua este distrito.",
        "Delete this event.": "Exclua este evento.",
        "Delete this neighbourhood.": "Exclua esta vizinhan\u00e7a.",
        "Delete this user account.": "Exclua esta conta de usu\u00e1rio.",
        "Delete this venue.": "Exclua este local.",
        "Deletes districts from this city.": "Exclui bairros desta cidade.",
        "Deletes neighbourhoods from this city.": "Exclui bairros desta cidade.",
        "Descending": "descendente",
        "Description": "Descri\u00e7\u00e3o",
        "Disabling Matchmaking": "Desativando Matchmaking",
        "Dismiss": "Dispensar",
        "Displaying": "Exibindo",
        "District is already existed": "Distrito j\u00e1 existe",
        "Districts & Neighborhood": "Distritos e Vizinhan\u00e7a",
        "Done": "Feito",
        "Download Event Data": "Baixar dados do evento",
        "Drag the map and change the zoom levels to adjust how your concert listings appear to the public. This will not affect the way that maps display on this registration/matchmaking site.": "Arraste o mapa e altere os n\u00edveis de zoom para ajustar como a lista de seus shows aparece para o p\u00fablico. Isso n\u00e3o afetar\u00e1 a maneira como os mapas s\u00e3o exibidos neste site de registro / combina\u00e7\u00e3o.",
        "Drag the slider to select the performance time.": "Arraste o controle deslizante para selecionar o tempo de execu\u00e7\u00e3o.",
        "Drag the sliders below to select the start and end times for performance match.": "Arraste os controles deslizantes abaixo para selecionar os hor\u00e1rios de in\u00edcio e t\u00e9rmino para a correspond\u00eancia de desempenho.",
        "Drag the sliders below to select the start and end times for your requested performance.": "Arraste os controles deslizantes abaixo para selecionar os hor\u00e1rios de in\u00edcio e t\u00e9rmino para o desempenho solicitado.",
        "Drag the sliders to select the performance time": "Arraste os controles deslizantes para selecionar o tempo de desempenho",
        "Drag the sliders to update the performance time": "Arraste os controles deslizantes para atualizar o tempo de desempenho",
        "Dragging the map will reposition the flag that shows the exact location for your venue. Zoom in to make fine adjustments.": "Arrastar o mapa reposicionar\u00e1 a bandeira que mostra a localiza\u00e7\u00e3o exata do seu local. Aumente o zoom para fazer ajustes finos.",
        "Due to social distancing concerns this year, our community is disabling the \"matchmaking\" features for Make Music Day. Please continue to sign up as a venue or a performer, and you can still register your June 21 events.": "Devido a preocupa\u00e7\u00f5es com o distanciamento social este ano, nossa comunidade est\u00e1 desativando os recursos de \"combina\u00e7\u00e3o\" para o Make Music Day. Continue a se inscrever como local ou artista e ainda poder\u00e1 registrar seus eventos de 21 de junho.",
        "Early Music": "M\u00fasica Antiga",
        "East Asian": "leste Asi\u00e1tico",
        "Eastern European": "leste Europeu",
        "Edit": "Editar",
        "Email Address": "Endere\u00e7o",
        "Enter address": "Endere\u00e7o",
        "Enter email address": "Endere\u00e7o",
        "Enter performer name": "Adicionar outro artista",
        "Hi %(profile_name)s": "Oi %(profile_name)s",
        "Next: Availability": "Disponibilidade",
        "Next: Performance Details": "Excluir desempenho",
        "Next: Performance Time": "Excluir desempenho",
        "Online Only": "De qualquer forma, continue",
        "Pending": "Ascendente",
        "Resend Confirmation": "Crie uma nova conversa.",
        "The administrator has disabled this city's \"matchmaking\" features. Please continue to sign up as a venue or a performer, and you will be able to register your events.": "Devido a preocupa\u00e7\u00f5es com o distanciamento social este ano, nossa comunidade est\u00e1 desativando os recursos de \"combina\u00e7\u00e3o\" para o Make Music Day. Continue a se inscrever como local ou artista e ainda poder\u00e1 registrar seus eventos de 21 de junho.",
        "Update Profile": "exclua este perfil",
        "address field can not be empty": "campo de endere\u00e7o n\u00e3o pode estar vazio",
        "artist": "artista",
        "by City Admin": "por Administrador",
        "by You": "por voc\u00ea",
        "confirmed": "confirmado",
        "delete this event": "deletar este evento",
        "delete this profile": "exclua este perfil",
        "does not match the password": "n\u00e3o corresponde \u00e0 senha",
        "online only": "De qualquer forma, continue"
    },
    "tr_TR": {
        " Performance for:": "Performans:",
        " canceled the performance": "performans\u0131 iptal etti",
        " changed the performance end time from ": "performans biti\u015f zaman\u0131n\u0131 de\u011fi\u015ftirdi",
        " changed the performance start time from ": "performans ba\u015flang\u0131\u00e7 \u200b\u200b\u200b\u200bzaman\u0131n\u0131 de\u011fi\u015ftirdi",
        " changed the performance time from ": "performans s\u00fcresini de\u011fi\u015ftirdi",
        " confirmed the performance": "performans\u0131 onaylad\u0131",
        " confirmed the performance for the artist": "Bu sanat\u00e7\u0131 i\u00e7in onaylanm\u0131\u015f etkinlikleri listeleyin.",
        " confirmed the performance for the city": "\u015fehir i\u00e7in performans\u0131n\u0131 do\u011frulad\u0131",
        " confirmed the performance for the venue": "Bu mekan i\u00e7in onaylanm\u0131\u015f etkinlikleri listeleyin.",
        " has listed their availability from ": "uygunluluklar\u0131n\u0131 listeledi\n",
        " requested a performance": "performans istedi",
        " to ": "ile",
        "%(attr_name)s is not a valid attribute of %(obj)s": "%(attr_name)s, %(obj)s \u00f6\u011fesinin ge\u00e7erli bir \u00f6zelli\u011fi de\u011fil",
        "%(enum_value)s is not an accepted value.": "%(enum_value)s kabul edilen bir de\u011fer de\u011fil.",
        "%(profile_name)s Schedule": "%(profile_name)s program\u0131",
        "%(profile_name)s has listed their availability from %(start_time)s to %(end_time)s": "%(profile_name)s, kullan\u0131labilirliklerini %(start_time)s ile %(end_time)s aras\u0131nda listeledi",
        "A cappella / Barbershop": "A capella / Berber d\u00fckkan\u0131",
        "A password was expected, but none was provided.": "Bir parola bekleniyordu, ancak girilmedi.",
        "Accent Color": "Vurgu Rengi",
        "Accept": "Kabul et",
        "Accept Performance": "Performans\u0131 Kabul Et",
        "Accept this event on behalf of a venue.": "Bu etkinli\u011fi bir mekan ad\u0131na kabul edin.",
        "Accept this event on behalf of an artist.": "Bu etkinli\u011fi bir sanat\u00e7\u0131 ad\u0131na kabul edin.",
        "Accept this event.": "Bu etkinli\u011fi kabul edin.",
        "Accept, Decline or Change Time of this performance": "Bu performans\u0131 Kabul Et, Reddet veya Zaman\u0131n\u0131 De\u011fi\u015ftir",
        "Accepting Requests": "\u0130stekleri Kabul Etme",
        "Accepting Requests for Performances": "Performans Taleplerini Kabul Etme",
        "Accepting Requests from Performers": "Sanat\u00e7\u0131lardan Gelen \u0130stekleri Kabul Etme",
        "Account": "Hesap",
        "Account Deleted Successfully": "Hesap Ba\u015far\u0131yla Silindi",
        "Account Settings": "Hesap ayarlar\u0131",
        "Active": "Aktif",
        "Active Chapters": "Aktif B\u00f6l\u00fcmler",
        "Active as:": "Aktif:",
        "Add": "Ekle",
        "Add Another Performer": "Ba\u015fka Bir Oyuncu Ekle",
        "Add a SoundCloud Song URL to this artist.": "Bu sanat\u00e7\u0131ya bir SoundCloud \u015eark\u0131 URL'si ekleyin.",
        "Add a YouTube Video URL to this artist.": "Bu sanat\u00e7\u0131ya bir YouTube Video URL'si ekleyin.",
        "Add a genre to this city.": "Bu \u015fehre bir m\u00fczik t\u00fcr\u00fc ekleyin.",
        "Add a venue type to this city.": "Bu \u015fehre bir mekan t\u00fcr\u00fc ekleyin.",
        "Add an event in this city.": "Bu \u015fehirde bir etkinlik ekleyin.",
        "Add districts to this city.": "Bu \u015fehre il\u00e7e ekleyin.",
        "Add neighbourhoods to this city.": "Bu \u015fehre mahalle ekleyin.",
        "Add neighbourhoods to this district.": "Bu b\u00f6lgeye mahalle ekleyin.",
        "Add template to this city.": "Bu \u015fehre \u015fablon ekleyin.",
        "Add to Event": "Etkinli\u011fe Ekle",
        "Adding your web profiles is strongly recommended! These links will be displayed on your public profile and performance listings.": "Web profillerinizi eklemeniz \u015fiddetle tavsiye edilir! Bu ba\u011flant\u0131lar, herkese a\u00e7\u0131k profilinizde ve performans listelerinizde g\u00f6r\u00fcnt\u00fclenecektir.",
        "Additional details: ": "Ek detaylar:",
        "Address": "Adres",
        "Afrobeat": "Afrobeat",
        "After going public, you will no longer be able to change your site's neighborhood names, venue types, genres, or time increments.<br/><br/>Are you sure you want to go public now?": "Herkese a\u00e7\u0131k hale geldikten sonra sitenizin mahalle adlar\u0131n\u0131, mekan t\u00fcrlerini, m\u00fczik t\u00fcrlerini veya zaman  aral\u0131k art\u0131\u015f tiplerini art\u0131k de\u011fi\u015ftiremeyeceksiniz.<br/><br/>\u015eimdi herkese a\u00e7\u0131k olmak istedi\u011finizden emin misiniz?",
        "Alert": "Uyar\u0131",
        "All": "Her\u015fey",
        "All Conversations": "T\u00fcm G\u00f6r\u00fc\u015fmeler",
        "All performances take place on <b>%(festival_date)s</b>, between <b>%(festival_start_time)s</b> and <b>%(festival_end_time)s</b> throughout the city, and are free and open to all.": "T\u00fcm performanslar <b>%(festival_date)s</b> tarihinde, \u015fehir genelinde <b>%(festival_start_time)s</b> ile <b>%(festival_end_time)s</b> aras\u0131nda ger\u00e7ekle\u015fir ve \u00fccretsiz ve herkese a\u00e7\u0131kt\u0131r.",
        "Almost there!": "Neredeyse tamam!",
        "Almost there, now select the performance time": "Performans s\u00fcresini se\u00e7mek i\u00e7in kayd\u0131r\u0131c\u0131lar\u0131 s\u00fcr\u00fckleyin",
        "Almost there, now select your performance time.": "Performans s\u00fcresini se\u00e7mek i\u00e7in kayd\u0131r\u0131c\u0131y\u0131 s\u00fcr\u00fckleyin.",
        "Almost there, now tell us about your performer(s)": "Bize sanat\u00e7\u0131(lar)\u0131n\u0131zdan bahsedin.",
        "Alphabetical (A to Z)": "Alfabetik (A'dan Z'ye)",
        "Alphabetical (Z to A)": "Alfabetik (Z'den A'ya)",
        "Already have a performance planned and not interested in additional matches? This option is for you.": "Zaten planlanm\u0131\u015f bir performans\u0131n\u0131z var ve ek e\u015fle\u015fmelerle ilgilenmiyor musunuz? Bu se\u00e7enek tam size g\u00f6re.",
        "Alternative Images": "Alternatif Resimler",
        "Americana / Bluegrass": "Amerikana / Bluegrass",
        "Amphitheater or Bandstand": "Amfi Tiyatro veya Orkestra Yeri",
        "An API Exception occurred": "Bir API \u0130stisnas\u0131 olu\u015ftu",
        "An OAuth2 related error has occurred.": "OAuth2 ile ilgili bir hata olu\u015ftu.",
        "An account with this email address already exists. Click <a href=\"/account/login\">here</a> to login": "Bu e-posta adresine sahip bir hesap zaten var. Giri\u015f yapmak i\u00e7in <a href=\"/account/login\">buray\u0131</a> t\u0131klay\u0131n",
        "An error has occurred!": "Bir hata olu\u015ftu!",
        "An internal error prevented the request from being handled.": "Dahili bir hata, iste\u011fin i\u015flenmesini engelledi.",
        "An unhandled exception has occurred!": "\u00c7\u00f6z\u00fclemeyen bir istisna oldu!",
        "App config is missing \"BASE_HOSTNAME\"": "Uygulama yap\u0131land\u0131rmas\u0131nda \"BASE_HOSTNAME\" eksik",
        "Approve": "Onayla",
        "Approve Performance": "Performans\u0131 Onayla",
        "Approve this event.": "Bu etkinli\u011fi onayla",
        "Are you a venue or a performer?": "Bir mekan m\u0131s\u0131n\u0131z yoksa bir icrac\u0131 m\u0131s\u0131n\u0131z?",
        "Are you flexible about timing? How long a set could the performer play?": "Zamanlama konusunda esnek misiniz? \u0130crac\u0131 ne kadar s\u00fcre boyunca m\u00fczik icra edebilir?",
        "Are you flexible about what time you can perform here? How long is your set?": "Bu mekanda g\u00f6sterebilece\u011finiz performans\u0131n saati konusunda esnek misiniz? Performans\u0131n\u0131z ne kadar uzun s\u00fcr\u00fcyor?",
        "Are you looking for matches, or just want to list your event?": "E\u015fle\u015fme mi ar\u0131yorsunuz yoksa sadece etkinli\u011finizi mi listelemek istiyorsunuz?",
        "Are you sure you want to add this new venue type? This action cannot be undone.": "Bu yeni mekan t\u00fcr\u00fcn\u00fc eklemek istedi\u011finizden emin misiniz? Bu i\u015flem geri al\u0131namaz.",
        "Are you sure you want to delete this event?": "Bu etkinli\u011fi silmek istedi\u011finizden emin misiniz?",
        "Are you sure you want to pause this chapter? Pausing will take %(city_domain)s offline": "Bu b\u00f6l\u00fcm\u00fc duraklatmak istedi\u011finizden emin misiniz? Duraklatma, %(city_domain)s'i \u00e7evrimd\u0131\u015f\u0131na alacak",
        "Are you sure you want to pause this chapter? Pausing will take .makemusicday.org": "Bu b\u00f6l\u00fcm\u00fc duraklatmak istedi\u011finizden emin misiniz? Duraklatma, .makemusicday.org\u2019da olacakt\u0131r",
        "Are you sure?": "Emin misin?",
        "Artist": "Sanat\u00e7\u0131",
        "Artist creation is currently disabled. Try again later.": "Sanat\u00e7\u0131 olu\u015fturma \u015fu anda devre d\u0131\u015f\u0131. Daha sonra tekrar deneyin.",
        "As a performer, you can create an event at a venue you've secured on your own. Once approved by the City Admin, your event will be listed on our event website.": "Bir sanat\u00e7\u0131 olarak, kendi ba\u015f\u0131n\u0131za g\u00fcvence alt\u0131na ald\u0131\u011f\u0131n\u0131z bir mekanda bir etkinlik olu\u015fturabilirsiniz. \u015eehir admini taraf\u0131ndan onayland\u0131ktan sonra etkinli\u011finiz etkinlik web sitemizde listelenecektir.",
        "As a venue, you can list events with performances you\u2019ve found on your own. Once approved by the City Admin, your event will be listed on our event website.": "Mekan olarak kendi buldu\u011funuz performanslardan olu\u015fan etkinlikleri listeleyebilirsiniz. \u015eehir admini taraf\u0131ndan onayland\u0131ktan sonra etkinli\u011finiz etkinlik web sitemizde listelenecektir.",
        "Ascending": "artan",
        "Availability": "Uygunluk",
        "Availability End Time": "M\u00fcsaitlik Biti\u015f Zaman\u0131",
        "Availability Start Time": "M\u00fcsaitlik Ba\u015flang\u0131\u00e7",
        "Available template variables": "Kullan\u0131labilir \u015fablon de\u011fi\u015fkenleri",
        "Awaiting approval from City Admin": "\u015eehir admininden onay bekleniyor",
        "Awaiting confirmation from ": "'dan onay bekleniyor",
        "Away message is successfully updated!": "D\u0131\u015far\u0131da mesaj\u0131 ba\u015far\u0131yla g\u00fcncellendi!",
        "Back": "Geri",
        "Bar, Caf\u00e9, or Restaurant": "Bar, Kafe veya Restoran",
        "Beach": "Sahil",
        "Bio": "Tan\u0131t\u0131m",
        "Blues": "Blues",
        "Boat": "Tekne",
        "Both in-person and online": "Hem y\u00fcz y\u00fcze hem de \u00e7evrimi\u00e7i",
        "Brass": "Bak\u0131r \u00dcflemeliler",
        "But, if you want to use the matching system to find more performers to play at your space, please create a separate Venue Profile. You can do that ": "Ancak, mekan\u0131n\u0131zda oynayacak daha fazla sanat\u00e7\u0131 bulmak i\u00e7in e\u015fle\u015ftirme sistemini kullanmak istiyorsan\u0131z, l\u00fctfen ayr\u0131 bir Mekan Profili olu\u015fturun. Bunu yapabilirsin",
        "Cabaret": "Kabare",
        "Cancel": "\u0130ptal et",
        "Canceled in case of rain": "Ya\u011fmur Durumunda",
        "Cancelled": "Etkinlik \u0130ptal Edildi",
        "Cannot serialize; no default schema has been defined for %(classname)s": "Serile\u015ftirilemiyor; %(classname)s i\u00e7in varsay\u0131lan \u015fema tan\u0131mlanmad\u0131",
        "Celtic": "Kelt m\u00fczi\u011fi",
        "Change": "De\u011fi\u015ftir",
        "Change Time": "Zaman\u0131 de\u011fi\u015ftir",
        "Change Time of Performance for: ": "\u015eunun i\u00e7in Performans Zaman\u0131n\u0131 De\u011fi\u015ftirin:",
        "Chapter Status: ": "B\u00f6l\u00fcm Durumu:",
        "Choose whether your users can schedule events in 5 minute increments (such as 5:20pm to 5:55pm), or if they must use 15 minute increments (such as 5:15pm to 6:00pm). Most cities use 15 minute increments. <span accent>Once your site is open to the public, this setting cannot be changed.</span>": "Kullan\u0131c\u0131lar\u0131n\u0131z\u0131n etkinlikleri 5 dakikal\u0131k art\u0131\u015flarla (5:20pm - 17:55pm gibi) veya 15 dakikal\u0131k art\u0131\u015flarla (\u00f6rne\u011fin 17:15pm - 18:00pm) kullanmalar\u0131 gerekip gerekmedi\u011fini se\u00e7in. \u00c7o\u011fu \u015fehir 15 dakikal\u0131k art\u0131\u015flar kullan\u0131r. <span aksan>Siteniz herkese a\u00e7\u0131k oldu\u011funda bu ayar de\u011fi\u015ftirilemez.</span>",
        "Choral": "Koro",
        "City": "\u015eehir",
        "City Admin": "\u015eehir admini",
        "City Administrator": "\u015eehir admini",
        "City Center": "Toplum Merkezi",
        "City Contact Us URL": "\u015eehir Bize Ula\u015f\u0131n URL'si",
        "City Information": "Tam \u015fehir bilgisi.",
        "City Match URL": "\u015eehir E\u015fleme URL'si",
        "City Name": "\u0130lk ad\u0131",
        "City URL": "\u015eehir URL'si",
        "City Website URL": "\u015eehir Web Sitesi URL'si",
        "City admin account is successfully created!": "\u015eehir admin hesab\u0131 ba\u015far\u0131yla olu\u015fturuldu!",
        "City admin confirmed the performance": "\u015eehir y\u00f6neticisi performans\u0131 onaylad\u0131",
        "City information successfully updated!": "\u015eehir bilgileri ba\u015far\u0131yla g\u00fcncellendi!",
        "Classical": "Klasik m\u00fczik",
        "Click here to resend the confirmation message.": "Onay mesaj\u0131n\u0131 yeniden g\u00f6ndermek i\u00e7in buray\u0131 t\u0131klay\u0131n.",
        "Close": "Kapat",
        "Colors": "Renkler",
        "Comma-separated values": "Virg\u00fclle ayr\u0131lm\u0131\u015f de\u011ferler",
        "Community Center": "Toplum Merkezi",
        "Complete Profile": "bu profili sil",
        "Confirm": "Onayla",
        "Confirm New Password": "Yeni \u015fifreyi onayla",
        "Confirm Password": "\u015eifreyi Onayla",
        "Confirm this performance on behalf of: ": "Bu performans\u0131 a\u015fa\u011f\u0131dakiler ad\u0131na onaylay\u0131n:",
        "Confirmed": "onaylanm\u0131\u015f",
        "Confirmed Performances": "Onaylanm\u0131\u015f Performanslar",
        "Congratulations! You are ready to open your site to the public.": "Tebrikler! Sitenizi herkese a\u00e7maya haz\u0131rs\u0131n\u0131z.",
        "Contact": "\u0130leti\u015fim",
        "Contact Email": "\u0130leti\u015fim E-posta Adresi",
        "Contact Info": "\u0130leti\u015fim bilgileri",
        "Contact Information": "\u0130leti\u015fim bilgileri",
        "Contact Name": "Ki\u015fi Ad\u0131",
        "Contact Phone": "\u0130leti\u015fim Telefonu",
        "Contact Us": "Bizimle ileti\u015fime ge\u00e7in",
        "Content-type header must be one of %(mime_types)s": "\u0130\u00e7erik t\u00fcr\u00fc ba\u015fl\u0131\u011f\u0131 %(mime_types)s \u00f6\u011fesinden biri olmal\u0131d\u0131r",
        "Continue": "Devam et",
        "Continue Anyway": "Her hal\u00fckarda devam et",
        "Conversations": "Konu\u015fmalar",
        "Could not create a OAuth token. Perhaps missing scopes?": "OAuth jetonu olu\u015fturulamad\u0131. Belki eksik kapsamlar?",
        "Country": "\u00dclke",
        "Courtyard": "Avlu",
        "Create Account": "Hesap olu\u015ftur",
        "Create Admin Account": "Y\u00f6netici Hesab\u0131 Olu\u015ftur",
        "Create An Event": "Etkinlik Olu\u015ftur",
        "Create Event": "Etkinlik Olu\u015ftur",
        "Create New Chapter": "Yeni B\u00f6l\u00fcm Olu\u015ftur",
        "Create New Festival": "Yeni bir sohbet olu\u015fturun.",
        "Create Your Own Event": "Kendi Etkinli\u011finizi Yarat\u0131n",
        "Create a Matchmaking Profile": "Bir E\u015fle\u015ftirici Profili Olu\u015fturun",
        "Create a Performance for: ": "\u015eunlar i\u00e7in bir Performans olu\u015fturun:",
        "Create a Performer Profile": "Bir Oyuncu Profili Olu\u015fturun",
        "Create a Venue Profile": "Bir Mekan Profili Olu\u015fturun",
        "Create a new conversation for this artist.": "Bu sanat\u00e7\u0131 i\u00e7in yeni bir sohbet olu\u015fturun.",
        "Create a new conversation for this venue.": "Bu mekan i\u00e7in yeni bir sohbet olu\u015fturun.",
        "Create a new conversation.": "Yeni bir sohbet olu\u015fturun.",
        "Create a new event for this artist.": "Bu sanat\u00e7\u0131 i\u00e7in yeni bir etkinlik olu\u015fturun.",
        "Create a new event for this venue.": "Bu mekan i\u00e7in yeni bir etkinlik olu\u015fturun.",
        "Create a new performer profile for this user.": "Bu kullan\u0131c\u0131 i\u00e7in yeni bir sanat\u00e7\u0131 profili olu\u015fturun.",
        "Create a new self-match event for this artist.": "Bu sanat\u00e7\u0131 i\u00e7in yeni bir kendi kendine e\u015fle\u015fme etkinli\u011fi olu\u015fturun.",
        "Create a new self-match event for this user.": "Bu kullan\u0131c\u0131 i\u00e7in yeni bir kendi kendine e\u015fle\u015fme etkinli\u011fi olu\u015fturun.",
        "Create a new self-match event for this venue.": "Bu mekan i\u00e7in yeni bir kendi kendine e\u015fle\u015fme etkinli\u011fi olu\u015fturun.",
        "Create a new venue profile for this user.": "Bu kullan\u0131c\u0131 i\u00e7in yeni bir mekan profili olu\u015fturun.",
        "Created at": "\u015eu saatte olu\u015fturuldu:",
        "Current City Locale": "Mevcut \u015eehir Yerel Ayar\u0131",
        "Current Font Family": "Ge\u00e7erli Yaz\u0131 Tipi Ailesi",
        "Custom CSS": "\u00d6zel CSS",
        "Customize by uploading your city\u2019s event logo. It will replace the Make Music logo in the upper left. <br/>File format should be jpg or png. A transparent png will look best. Area available for logo is 150px x 150px.": "\u015eehrinizin etkinlik logosunu y\u00fckleyerek \u00f6zelle\u015ftirin. Sol \u00fcstteki Make Music logosunun yerini alacak. <br/>Dosya format\u0131 jpg veya png olmal\u0131d\u0131r. \u015eeffaf bir png en iyi \u015fekilde g\u00f6r\u00fcnecektir. Logo i\u00e7in kullan\u0131labilir alan 150px x 150px'dir.",
        "Customize the platform colors, fonts and logo.": "Platform renklerini, yaz\u0131 tiplerini ve logoyu \u00f6zelle\u015ftirin.",
        "Dashboard": "G\u00f6sterge Paneli",
        "Deactivate": "Devre d\u0131\u015f\u0131 b\u0131rak",
        "Deadline To Register": "Kay\u0131t i\u00e7in Son Tarih",
        "Deadline to Create Profile": "Profil Olu\u015fturmak i\u00e7in Son Tarih",
        "Deadline to Register": "Kay\u0131t i\u00e7in Son Tarih",
        "Deadline to Request or Confirm a Performance": "Bir Performans\u0131 Talep Etmek veya Onaylamak i\u00e7in Son Tarih",
        "Deadlines": "son tarihler",
        "Decline": "Reddet",
        "Default": "Varsay\u0131lan Resim",
        "Default Image": "Varsay\u0131lan Resim",
        "Default Language": "Varsay\u0131lan dil",
        "Define the musical genres performers and venues will be able to select. <span accent>Once the platform goes live for participants, you can not delete existing genres, but you can add new ones.</span>": "Sanat\u00e7\u0131lar\u0131n ve mekanlar\u0131n olu\u015fturaca\u011f\u0131 m\u00fczik t\u00fcrlerini tan\u0131mlay\u0131n.",
        "Define the types of venues that users will be able to select. <span accent>Once the platform goes live for participants, you cannot delete existing venue types, but you can add new ones.</span>": "Sanat\u00e7\u0131lar\u0131n ve mekanlar\u0131n olu\u015fturaca\u011f\u0131 m\u00fczik t\u00fcrlerini tan\u0131mlay\u0131n.",
        "Delete": "Sil",
        "Delete Account": "Hesab\u0131 sil",
        "Delete Performance": "Performans\u0131 Sil",
        "Delete Performance for:": "\u015eunun i\u00e7in Performans\u0131 Sil:",
        "Delete this artist.": "Bu sanat\u00e7\u0131y\u0131 silin.",
        "Delete this district.": "Bu b\u00f6lgeyi silin.",
        "Delete this event.": "Bu etkinli\u011fi silin.",
        "Delete this neighbourhood.": "Bu mahalleyi silin.",
        "Delete this user account.": "Bu kullan\u0131c\u0131 hesab\u0131n\u0131 silin.",
        "Delete this venue.": "Bu mekan\u0131 silin.",
        "Deletes districts from this city.": "Bu \u015fehirden il\u00e7eleri siler.",
        "Deletes neighbourhoods from this city.": "Bu \u015fehirden mahalleleri siler.",
        "Descending": "Azalan",
        "Description": "Tan\u0131m",
        "Disabling Matchmaking": "E\u015fle\u015ftiriciyi Devre D\u0131\u015f\u0131 B\u0131rakma",
        "Dismiss": "Atla",
        "Displaying": "g\u00f6steriliyor",
        "District": "Semt",
        "District is already existed": "Bu b\u00f6lge zaten var",
        "Districts": "\u0130l\u00e7eler",
        "Districts & Neighborhood": "\u0130l\u00e7eler ve Mahalleler",
        "Do Not Use Districts": "Bu b\u00f6lgeyi d\u00fczenleyin.",
        "Done": "Tamamland\u0131",
        "Download": "\u0130ndirin",
        "Download Event Data": "Etkinlik Verilerini \u0130ndir",
        "Drag the map and change the zoom levels to adjust how your concert listings appear to the public. This will not affect the way that maps display on this registration/matchmaking site.": "Konser listelerinizin herkese nas\u0131l g\u00f6r\u00fcnece\u011fini ayarlamak i\u00e7in haritay\u0131 s\u00fcr\u00fckleyin ve yak\u0131nla\u015ft\u0131rma d\u00fczeylerini de\u011fi\u015ftirin. Bu, haritalar\u0131n bu kay\u0131t/\u00e7\u00f6p\u00e7atanl\u0131k sitesinde g\u00f6r\u00fcnt\u00fclenme \u015feklini etkilemeyecektir.",
        "Drag the slider to select the performance time.": "Performans s\u00fcresini se\u00e7mek i\u00e7in kayd\u0131r\u0131c\u0131y\u0131 s\u00fcr\u00fckleyin.",
        "Drag the sliders below to select the start and end times for performance match.": "Performans e\u015fle\u015fmesinin ba\u015flang\u0131\u00e7",
        "Drag the sliders below to select the start and end times for your requested performance.": "\u0130stedi\u011finiz performans\u0131n ba\u015flang\u0131\u00e7",
        "Drag the sliders to select the performance time": "Performans s\u00fcresini se\u00e7mek i\u00e7in kayd\u0131r\u0131c\u0131lar\u0131 s\u00fcr\u00fckleyin",
        "Drag the sliders to update the performance time": "Performans s\u00fcresini g\u00fcncellemek i\u00e7in kayd\u0131r\u0131c\u0131lar\u0131 s\u00fcr\u00fckleyin",
        "Dragging the map will reposition the flag that shows the exact location for your venue. Zoom in to make fine adjustments.": "Haritay\u0131 s\u00fcr\u00fcklemek, mekan\u0131n\u0131z\u0131n tam konumunu g\u00f6steren bayra\u011f\u0131 yeniden konumland\u0131racakt\u0131r. \u0130nce ayarlamalar yapmak i\u00e7in yak\u0131nla\u015ft\u0131r\u0131n.",
        "Due to social distancing concerns this year, our community is disabling the \"matchmaking\" features for Make Music Day. Please continue to sign up as a venue or a performer, and you can still register your June 21 events.": "Bu y\u0131l sosyal mesafe endi\u015feleri nedeniyle toplulu\u011fumuz Make Music Day i\u00e7in \"e\u015fle\u015ftirme\" \u00f6zelliklerini devre d\u0131\u015f\u0131 b\u0131rak\u0131yor. L\u00fctfen bir mekan veya sanat\u00e7\u0131 olarak kaydolmaya devam edin ve yine de 21 Haziran etkinliklerinizi ekleyebilirsiniz.",
        "Early Music": "Ge\u00e7mi\u015f D\u00f6nem M\u00fczik Tarzlar\u0131",
        "East Asian": "Do\u011fu Asya M\u00fczi\u011fi",
        "Eastern European": "Do\u011fu Avrupa M\u00fczi\u011fi",
        "Edit": "D\u00fczenle",
        "Edit Performance for: ": "\u015eunun i\u00e7in Performans\u0131 D\u00fczenle:",
        "Edit Performer Profile": "Sanat\u00e7\u0131 Profilini D\u00fczenle",
        "Edit Profile to Find Performers": "Sanat\u00e7\u0131lar\u0131 Bulmak i\u00e7in Profili D\u00fczenle",
        "Edit Template": "\u015eablonu D\u00fczenle",
        "Edit Times": "Saatleri D\u00fczenle",
        "Edit and preview email templates here. When you are satisfied with the preview, click save to keep your changes.": "E-posta \u015fablonlar\u0131n\u0131 burada d\u00fczenleyin ve \u00f6nizleyin. \u00d6nizlemeden memnun kald\u0131\u011f\u0131n\u0131zda, de\u011fi\u015fikliklerinizi saklamak i\u00e7in kaydet'i t\u0131klay\u0131n.",
        "Edit basic information about your venue": "Mekan\u0131n\u0131zla ilgili temel bilgileri d\u00fczenleyin",
        "Edit the Venue Profile": "Mekan Profilini D\u00fczenle",
        "Edit this artist.": "Bu sanat\u00e7\u0131y\u0131 d\u00fczenleyin.",
        "Edit this city.": "Bu \u015fehri d\u00fczenleyin.",
        "Edit this district.": "Bu b\u00f6lgeyi d\u00fczenleyin.",
        "Edit this email template.": "Bu e-posta \u015fablonunu d\u00fczenleyin.",
        "Edit this event.": "Bu etkinli\u011fi d\u00fczenleyin.",
        "Edit this neighbourhood.": "Bu mahalleyi d\u00fczenleyin.",
        "Edit this user account.": "Bu kullan\u0131c\u0131 hesab\u0131n\u0131 d\u00fczenleyin.",
        "Edit this venue.": "Bu mekan\u0131 d\u00fczenleyin.",
        "Electrical Requirement": "Elektrik Gereksinimi",
        "Electrical Requirements": "Elektrik Gereksinimleri",
        "Electricity / PA Needs": "Elektrik / PA \u0130htiya\u00e7lar\u0131",
        "Electricity Conflict": "Elektrik \u00c7at\u0131\u015fmas\u0131",
        "Electricity Needed": "Elektrik \u0130htiya\u00e7lar\u0131",
        "Electricity Needs": "Elektrik \u0130htiya\u00e7lar\u0131",
        "Electricity Provided": "Elektrik \u0130htiya\u00e7lar\u0131",
        "Electricity and PA Needed": "Elektrik / PA \u0130htiya\u00e7lar\u0131",
        "Electricity and PA Provided": "Elektrik / PA \u0130htiya\u00e7lar\u0131",
        "Electronic": "Elektronik",
        "Email": "E-posta",
        "Email Address": "Ge\u00e7ersiz e-posta adresi.",
        "Email Templates": "E-posta \u015eablonlar\u0131",
        "Email Templates & MailChimp": "E-posta \u015eablonlar\u0131",
        "Enable Matchmaking": "E\u015fle\u015ftirmeyi Etkinle\u015ftir",
        "End Time": "Biti\u015f zaman\u0131",
        "End Time has to be after start time, please pick a proper value": "Biti\u015f Zaman\u0131, ba\u015flang\u0131\u00e7 ",
        "End time (earliest to latest)": "Biti\u015f zaman\u0131 (en erkenden en yeniye)",
        "End time (latest to earliest)": "Biti\u015f zaman\u0131 (en ge\u00e7ten en erkene)",
        "Enter 5 digit postal code": "L\u00fctfen ge\u00e7erli bir posta kodu girin",
        "Enter District Name": "B\u00f6lge Ad\u0131n\u0131 Girin",
        "Enter Genre Name": "M\u00fczik T\u00fcr\u00fc Ad\u0131n\u0131 Girin",
        "Enter Location": "Bir konum girmelisiniz",
        "Enter Neighborhood Name": "Mahalle Ad\u0131n\u0131 Girin",
        "Enter Venue Type": "Mekan T\u00fcr\u00fcn\u00fc Girin",
        "Enter address": "A\u00e7\u0131k adres",
        "Enter contact first and last name": "Ki\u015finin ad\u0131n\u0131 ve soyad\u0131n\u0131 girin",
        "Enter email address": "Bir e-posta adresi girmelisiniz",
        "Enter performer name": "\u0130crac\u0131n\u0131n ad\u0131n\u0131 girmelisiniz",
        "Enter performer name or venue name": "Sanat\u00e7\u0131 ad\u0131n\u0131 veya mekan ad\u0131n\u0131 girin",
        "Enter phone number": "Bir telefon numaras\u0131 girmelisiniz",
        "Enter the URLs for any social media accounts your festival uses. These will be displayed at the bottom of each page on your site.": "Festivalinizin kulland\u0131\u011f\u0131 t\u00fcm sosyal medya hesaplar\u0131n\u0131n URL'lerini girin. Bunlar, sitenizdeki her sayfan\u0131n alt\u0131nda g\u00f6r\u00fcnt\u00fclenecektir.",
        "Enter venue name": "M\u00fczik T\u00fcr\u00fc Ad\u0131n\u0131 Girin",
        "Error": "Hata",
        "Event": "Etkinlik",
        "Event Accepted": "Etkinlik Kabul Edildi",
        "Event Approved": "Etkinlik Onayland\u0131",
        "Event Cancelled": "Etkinlik \u0130ptal Edildi",
        "Event Declined": "Etkinlik Reddedildi",
        "Event Pending": "Etkinlik Beklemede",
        "Event created by you": "Sizin taraf\u0131n\u0131zdan olu\u015fturulan etkinlik",
        "Event creation is currently disabled. Try again later.": "Etkinlik olu\u015fturma \u015fu anda devre d\u0131\u015f\u0131. Daha sonra tekrar deneyin.",
        "Event has been accepted and is pending festival organizer approval": "Etkinlik kabul edildi ve festival organizat\u00f6r\u00fcn\u00fcn onay\u0131 bekleniyor",
        "Event has been aceepted and been approved by the festival organizers": "Etkinlik, festival organizat\u00f6rleri taraf\u0131ndan kabul edildi ve onayland\u0131",
        "Event has been cancelled": "Etkinlik iptal edildi",
        "Event has been requested": "Etkinlik istendi",
        "Experimental": "Deneysel",
        "Export data for this city.": "Bu \u015fehir i\u00e7in verileri d\u0131\u015fa aktar\u0131n.",
        "Facebook": "Facebook",
        "Facebook URL": "Facebook URL",
        "Farm": "\u00c7iftlik",
        "Festival Date": "Festival Tarihi",
        "Festival Details": "Festival Detaylar\u0131",
        "Festival End Time": "Festival Ba\u015flang\u0131\u00e7",
        "Festival Name": "Festival Ad\u0131",
        "Festival Start & End Times": "Festival Ba\u015flang\u0131\u00e7",
        "Festival Start Time": "Festival Ba\u015flang\u0131\u00e7",
        "Fickle": "karars\u0131z",
        "Fickle Users": "Karars\u0131z Kullan\u0131c\u0131lar",
        "Field": "Tarla",
        "Find Performers": "Sanat\u00e7\u0131 Bul",
        "Find Venues": "Mekanlar\u0131 Bul",
        "Finish Setup": "Kurulumu Bitir",
        "First Name": "\u0130lk ad\u0131",
        "Folk": "Folk m\u00fczik",
        "Fonts": "yaz\u0131 tipleri",
        "Forgot Password": "Parolan\u0131z\u0131 m\u0131 unuttunuz",
        "Full artist information.": "Tam sanat\u00e7\u0131 bilgisi.",
        "Full city information.": "Tam \u015fehir bilgisi.",
        "Full conversation information.": "Tam sohbet bilgisi.",
        "Full event information.": "Tam etkinlik bilgisi.",
        "Full venue information.": "Tam mekan bilgisi.",
        "Funk": "funk",
        "Gallery or Museum": "Galeri veya M\u00fcze",
        "Garage": "Garaj",
        "Garden": "Bah\u00e7e",
        "Genre": "M\u00fczik T\u00fcr\u00fc",
        "Genre already exists": "Bu m\u00fczik t\u00fcr\u00fc zaten var",
        "Genre(s)": "M\u00fczik T\u00fcr\u00fc(leri)",
        "Genres": "M\u00fczik t\u00fcrleri",
        "Get all conversations for this user account.": "Bu kullan\u0131c\u0131 hesab\u0131 i\u00e7in t\u00fcm konu\u015fmalar\u0131 al\u0131n.",
        "Get all conversations that this city's admin has commented on.": "Bu \u015fehrin y\u00f6neticisinin yorum yapt\u0131\u011f\u0131 t\u00fcm konu\u015fmalar\u0131 al\u0131n.",
        "Get all custom email templates for this city.": "Bu \u015fehir i\u00e7in t\u00fcm \u00f6zel e-posta \u015fablonlar\u0131n\u0131 al\u0131n.",
        "Get all genres for this city.": "Bu \u015fehir i\u00e7in t\u00fcm m\u00fczik t\u00fcrlerini al\u0131n.",
        "Get all options relevant to artists of this city.": "Bu \u015fehrin sanat\u00e7\u0131lar\u0131yla ilgili t\u00fcm se\u00e7enekleri al\u0131n.",
        "Get all options relevant to events of this city.": "Bu \u015fehrin etkinlikleriyle ilgili t\u00fcm se\u00e7enekleri al\u0131n.",
        "Get all options relevant to venues of this city.": "Bu \u015fehrin mekanlar\u0131 ile ilgili t\u00fcm se\u00e7enekleri al\u0131n.",
        "Get conversations that this artist is a part of.": "Bu sanat\u00e7\u0131n\u0131n bir par\u00e7as\u0131 oldu\u011fu sohbetleri al\u0131n.",
        "Get conversations that this venue is a part of.": "Bu mekan\u0131n bir par\u00e7as\u0131 oldu\u011fu konu\u015fmalar\u0131 al\u0131n.",
        "Get event conversation.": "Etkinlik g\u00f6r\u00fc\u015fmesi al\u0131n.",
        "Go Back": "Geri",
        "Go Public": "Herkese a\u00e7\u0131k hale getir",
        "Go to City": "\u015eehre Git",
        "Go to Find Performers": "Sanat\u00e7\u0131 Bul'a gidin",
        "Gospel": "Gospel",
        "Grant city admin capabilities to a user.": "Bir kullan\u0131c\u0131ya \u015fehir admini yetkilerini verin.",
        "Great": "Afrobeat",
        "Great, now we need a little information about your performer's availability": "Harika, \u015fimdi sanat\u00e7\u0131n\u0131z\u0131n m\u00fcsaitli\u011fi hakk\u0131nda biraz bilgiye ihtiyac\u0131m\u0131z var",
        "Great, now we need a little information about your venue's location": "Mekan\u0131n\u0131zla ilgili temel bilgileri d\u00fczenleyin",
        "Has Artist Confirmed?": "Sanat\u00e7\u0131 Onaylad\u0131 m\u0131?",
        "Has City Confirmed?": "\u015eehir Onayland\u0131 m\u0131?",
        "Has Piano": "Piyano var",
        "Has Venue Confirmed?": "Mekan Onayland\u0131 m\u0131?",
        "Has confirmed email?": "Onaylanm\u0131\u015f e-posta var m\u0131?",
        "Health check FAILED due to 'SELECT NOW()' raising an exception.": "\u015e\u0130MD\u0130 SE\u00c7()' bir istisna olu\u015fturmas\u0131 nedeniyle durum denetimi BA\u015eARISIZ oldu.",
        "Hello City Admin!": "Merhaba \u015eehir Admini!",
        "Hello Super Admin!": "Merhaba S\u00fcper Admin!",
        "Help Me Find Performers": "\u0130crac\u0131lar\u0131 Bulmama Yard\u0131m Edin",
        "Hi ": "Merhaba",
        "Hi %(profile_name)s": "%(profile_name)s tarafindan",
        "Highlife": "Highlife",
        "Hip-Hop": "Hip-Hop",
        "Home": "Ana Sayfa",
        "Home (Yard, Porch, Roof)": "Ev (Avlu, Sundurma, \u00c7at\u0131)",
        "Hospital or Senior Center": "Hastane veya K\u0131demli Merkez",
        "House of Worship": "\u0130badethane",
        "How to schedule a performance": "Bir performans nas\u0131l planlan\u0131r",
        "I can provide both electricity and a PA": "Hem elektrik hem de PA sa\u011flayabilirim",
        "I can provide electricity, but not a PA": "Elektrik sa\u011flayabilirim ama PA de\u011fil",
        "I can't provide electricity or a PA, but performers may bring their own": "Elektrik veya PA sa\u011flayam\u0131yorum, ancak sanat\u00e7\u0131lar kendilerininkini getirebilir",
        "I have read and agree to the terms and conditions": "\u015eartlar ve ko\u015fullar\u0131 okudum ve kabul ediyorum",
        "I need electricity and a PA from the venue, or else I can't perform": "Mekandan elektri\u011fe ve PA'ya ihtiyac\u0131m var, yoksa performans g\u00f6steremem",
        "I need electricity from the venue, or else I can't perform": "Mekandan elektri\u011fe ihtiyac\u0131m var, yoksa performans g\u00f6steremem",
        "I need electricity, but I can provide my own batteries or generator if necessary": "Elektri\u011fe ihtiyac\u0131m var ama gerekirse kendi pillerimi veya jenerat\u00f6r\u00fcm\u00fc sa\u011flayabilirim",
        "I will not use amplification (no amps, mics, or speakers) and I don't need electricity": "Amplifikasyon kullanmayaca\u011f\u0131m (amplifikat\u00f6r, mikrofon veya hoparl\u00f6r yok) ve elektri\u011fe ihtiyac\u0131m yok",
        "I'd love to have electricity and a PA provided, but I'm flexible and will make it work regardless": "Elektri\u011fin ve bir PA'n\u0131n sa\u011flanmas\u0131n\u0131 \u00e7ok isterim, ancak esnekim ve ne olursa olsun \u00e7al\u0131\u015fmas\u0131n\u0131 sa\u011flayaca\u011f\u0131m",
        "ID": "Tan\u0131m numaras\u0131",
        "If matchmaking is not possible for your chapter (for example, due to social distancing requirements), you can turn it off here. Users will still be able to create profiles and events for themselves, but they won't be able to search for other users and make matches with them.": "B\u00f6l\u00fcm\u00fcn\u00fcz i\u00e7in e\u015fle\u015ftirme m\u00fcmk\u00fcn de\u011filse (\u00f6rne\u011fin, sosyal mesafe gereksinimleri nedeniyle), buradan kapatabilirsiniz. Kullan\u0131c\u0131lar yine kendileri i\u00e7in profiller ve etkinlikler olu\u015fturabilecekler, ancak di\u011fer kullan\u0131c\u0131lar\u0131 arayamayacak ve onlarla e\u015fle\u015femeyecekler.",
        "If the requested performance time doesn't work for you but you're still interested in scheduling the performance, change the time here. The other party will receive a notification and be able to confirm, modify or decline your newly proposed time.": "\u0130stenen performans zaman\u0131 sizin i\u00e7in uygun de\u011filse ancak yine de performans\u0131 planlamakla ilgileniyorsan\u0131z, zaman\u0131 buradan de\u011fi\u015ftirin. Di\u011fer taraf bir bildirim alacak ve yeni \u00f6nerilen s\u00fcrenizi onaylayabilecek, de\u011fi\u015ftirebilecek veya reddedebilecek.",
        "If you are planning on livestreaming your event, enter the URL you will use (such as the address of your Facebook page or YouTube channel). If you're not sure, leave it blank and you can enter it later.": "Etkinli\u011finizi canl\u0131 yay\u0131nlamay\u0131 planl\u0131yorsan\u0131z, kullanaca\u011f\u0131n\u0131z URL'yi (Facebook sayfan\u0131z\u0131n veya YouTube kanal\u0131n\u0131z\u0131n adresi gibi) girin. Emin de\u011filseniz, bo\u015f b\u0131rak\u0131n ve daha sonra girebilirsiniz.",
        "If you want to create an event for one of your performer or venue profiles, go back, switch to that profile and choose \"Create Your Own Event\".<br/><br/>If you want to list an event that does NOT involve any performers or venues you've already registered, click to continue. (This is uncommon.)": "Sanat\u00e7\u0131 veya mekan profillerinizden biri i\u00e7in bir etkinlik olu\u015fturmak istiyorsan\u0131z, geri d\u00f6n\u00fcn, o profile ge\u00e7in ve \"Kendi Etkinli\u011finizi Olu\u015fturun\"u se\u00e7in.<br/><br/>Etkinli\u011fi OLMAYAN bir etkinli\u011fi listelemek istiyorsan\u0131z daha \u00f6nce kaydetmi\u015f oldu\u011funuz herhangi bir sanat\u00e7\u0131y\u0131 veya mekan\u0131 dahil edin, devam etmek i\u00e7in t\u0131klay\u0131n. (Bu az rastlanan bir durumdur.)",
        "If you want to do both (you have an event to list but want to find matches as well) we recommend selecting the Performer or Venue Looking for a Match button. You'll have a profile that will allow you to seek matches, but you'll also be able to add your own performers from there.": "Her ikisini de yapmak istiyorsan\u0131z (listelemeniz gereken bir etkinli\u011finiz var ama ayn\u0131 zamanda e\u015fle\u015fmeleri de bulmak istiyorsan\u0131z), Oyuncu veya E\u015fle\u015fme Arayan Mekan butonunu se\u00e7menizi \u00f6neririz. E\u015fle\u015fme araman\u0131za izin verecek bir profiliniz olacak, ancak buradan kendi sanat\u00e7\u0131lar\u0131n\u0131z\u0131 da ekleyebileceksiniz.",
        "If your event is happening online, indicate where you will physically be performing (eg. your home)": "Etkinli\u011finiz \u00e7evrimi\u00e7i olarak ger\u00e7ekle\u015fiyorsa, fiziksel olarak nerede performans g\u00f6sterece\u011finizi belirtin (\u00f6rn. eviniz)",
        "Image (optional, but strongly encouraged)": "Resim (iste\u011fe ba\u011fl\u0131, ancak kesinlikle \u00f6nerilir)",
        "Image URL": "Resim URL'si",
        "Images (optional, but strongly encouraged)": "Resimler (iste\u011fe ba\u011fl\u0131, ancak kesinlikle \u00f6nerilir)",
        "Import": "\u0130\u00e7e aktar",
        "Import Past Profiles": "Ge\u00e7mi\u015f Profilleri \u0130\u00e7e Aktar",
        "Import Performer": "Sanat\u00e7\u0131lar i\u00e7in Notlar",
        "Import Performer Profile": "Sanat\u00e7\u0131 Profili \u0130\u00e7e Aktar",
        "Import Venue": "Mekan\u0131 \u0130\u00e7e Aktar",
        "Import the Venue Profile": "Mekan Profilini \u0130\u00e7e Aktar\u0131n",
        "In Case of Rain": "Ya\u011fmur Durumunda",
        "In Person": "Y\u00fcz y\u00fcze",
        "In case of inclement weather, will the show go on?": "K\u00f6t\u00fc havalarda g\u00f6steri devam edecek mi?",
        "In-Person Venues": "Y\u00fcz Y\u00fcze Mekanlar",
        "In-person": "Y\u00fcz y\u00fcze",
        "In-person, but will move online if necessary": "Y\u00fcz y\u00fcze, ancak gerekirse \u00e7evrimi\u00e7i olacak",
        "Inactive Chapters": "Aktif Olmayan B\u00f6l\u00fcmler",
        "Inbox": "Gelen kutusu",
        "Include a message (optional)": "Bir mesaj ekleyin (iste\u011fe ba\u011fl\u0131)",
        "Indian": "Hint m\u00fczi\u011fi",
        "Indie-Folk": "Indie-Folk",
        "Indie-Rock": "Indie-Rock",
        "Information for Performers": "Sanat\u00e7\u0131lar i\u00e7in Bilgi",
        "Instagram": "Instagram",
        "Instagram URL": "Instagram URL'si",
        "Invalid Basic credentials in Authorization header. The OAuth client ID and secret must be provided to create a user.": "Yetkilendirme ba\u015fl\u0131\u011f\u0131nda ge\u00e7ersiz Temel kimlik bilgileri. Bir kullan\u0131c\u0131 olu\u015fturmak i\u00e7in OAuth istemci kimli\u011fi ve s\u0131rr\u0131 sa\u011flanmal\u0131d\u0131r.",
        "Invalid Content-Type provided": "Ge\u00e7ersiz \u0130\u00e7erik T\u00fcr\u00fc",
        "Invalid `Authorization: Basic` token.": "Ge\u00e7ersiz \"Yetkilendirme: Temel\" belirteci.",
        "Invalid data type": "Ge\u00e7ersiz veri t\u00fcr\u00fc",
        "Invalid email address": "Ge\u00e7ersiz e-posta adresi",
        "Invalid email address.": "Ge\u00e7ersiz e-posta adresi.",
        "Invalid value": "ge\u00e7ersiz de\u011fer",
        "Jazz": "Caz",
        "Keep in mind that changes to color can affect usability. Test all pages to make sure color combinations still communicate the functionality of the site.": "Renk de\u011fi\u015fikliklerinin kullan\u0131labilirli\u011fi etkileyebilece\u011fini unutmay\u0131n. Renk kombinasyonlar\u0131n\u0131n hala sitenin i\u015flevselli\u011fini iletti\u011finden emin olmak i\u00e7in t\u00fcm sayfalar\u0131 test edin.",
        "Kids": "\u00c7ocuk m\u00fczi\u011fi",
        "Last Name": "Soyad\u0131",
        "Latin": "Latin m\u00fczi\u011fi",
        "Lesson": "Ders",
        "Lessons": "dersler",
        "Let me double check everything first": "\u00d6nce her \u015feyi iki kez kontrol etmeme izin ver",
        "Library": "K\u00fct\u00fcphane",
        "List My Event": "Etkinli\u011fimi Listele",
        "List New Event": "Yeni Etkinli\u011fi Listele",
        "List all events in this city.": "Bu \u015fehirdeki t\u00fcm etkinlikleri listeleyin.",
        "List confirmed events for this artist.": "Bu sanat\u00e7\u0131 i\u00e7in onaylanm\u0131\u015f etkinlikleri listeleyin.",
        "List confirmed events for this venue.": "Bu mekan i\u00e7in onaylanm\u0131\u015f etkinlikleri listeleyin.",
        "List events for this artist.": "Bu sanat\u00e7\u0131n\u0131n etkinliklerini listeleyin.",
        "List events for this venue.": "Bu mekan i\u00e7in etkinlikleri listeleyin.",
        "Listed Event": "Listelenen Etkinlik",
        "Live Streaming": "Canl\u0131 yay\u0131n",
        "Livestreaming website (optional)": "Canl\u0131 yay\u0131n web sitesi (iste\u011fe ba\u011fl\u0131)",
        "Livestreaming?": "Canl\u0131 yay\u0131n?",
        "Lobby": "lobi",
        "Locale": "yerel ayar",
        "Location": "Konum",
        "Login": "Giri\u015f yap",
        "Logo": "Logo",
        "Logout": "\u00c7\u0131k\u0131\u015f Yap",
        "Looking to sign up for Make Music Day? Select your city here": "Make Music Day'e kaydolmak m\u0131 istiyorsunuz? \u015eehrinizi buradan se\u00e7in",
        "MPB": "MPB",
        "Make Changes": "De\u011fi\u015fiklik yapmak",
        "Make Music Day": "D\u00fcnya M\u00fczik G\u00fcn\u00fc Sonu",
        "Make Music Day End": "D\u00fcnya M\u00fczik G\u00fcn\u00fc Sonu",
        "Make Music Day Start": "D\u00fcnya M\u00fczik G\u00fcn\u00fc Ba\u015flangc\u0131",
        "Make Music Day is a celebration of music on the summer solstice that is performed by anyone and enjoyed by everyone. You're invited to take part in the festivities and help show off our local talent and join the public celebration.\n\nThe festival is on June 21st and goes all day. It takes place in over 1,000 cities around the world.": "Make Music Day, yaz g\u00fcnd\u00f6n\u00fcm\u00fcnde herkes taraf\u0131ndan icra edilen ve herkesin keyif ald\u0131\u011f\u0131 bir m\u00fczik kutlamas\u0131d\u0131r. \u015eenliklere kat\u0131lmaya, yerel yeteneklerimizi sergilemeye ve halk kutlamalar\u0131na kat\u0131lmaya davetlisiniz. Festival 21 Haziran'da ve t\u00fcm g\u00fcn s\u00fcr\u00fcyor. D\u00fcnya \u00e7ap\u0131nda 1000'den fazla \u015fehirde ger\u00e7ekle\u015fir.",
        "Map": "Harita",
        "Maps": "Haritalar",
        "Mark this conversation as read.": "Bu konu\u015fmay\u0131 okundu olarak i\u015faretle.",
        "Mark this conversation as unread.": "Bu konu\u015fmay\u0131 okunmad\u0131 olarak i\u015faretle.",
        "Mark this message as read.": "Bu mesaj\u0131 okundu olarak i\u015faretleyin.",
        "Mark this message as unread.": "Bu mesaj\u0131 okunmam\u0131\u015f olarak i\u015faretle.",
        "Market": "Market",
        "Match My Preferences": "Tercihlerimi E\u015fle\u015ftir",
        "Message": "\u0130leti",
        "Message (optional)": "mesaj (iste\u011fe ba\u011fl\u0131)",
        "Message from Admin": "Y\u00f6neticiden Mesaj",
        "Metal": "Metal",
        "Microsoft Excel": "Microsoft Excel",
        "Middle Eastern": "Orta Do\u011fu M\u00fczi\u011fi",
        "Missing Pieces: ": "Kay\u0131p par\u00e7alar",
        "Missing data for required field.": "Gerekli alan i\u00e7in eksik veriler.",
        "Missing required field": "Gerekli alan eksik",
        "Missing soundcloud_song_urls": "Soundcloud_song_urls eksik",
        "Missing youtube_video_urls": "youtube_video_urls eksik",
        "Moving to shelter in case of rain": "Ya\u011fmur durumunda s\u0131\u011f\u0131na\u011fa ta\u015f\u0131nmak",
        "Musical Theater": "M\u00fczikal",
        "Mute this conversation.": "Bu konu\u015fmay\u0131 sessize al.",
        "My Profiles": "Profillerim",
        "Name": "\u0130sim",
        "Native American": "Amerikan Yerlisi M\u00fczi\u011fi",
        "Neighborhood": "Mahalle",
        "Neighborhood is already existed": "Mahalle zaten var",
        "New Admin Account": "Yeni Admin Hesab\u0131",
        "New Chapter": "Yeni b\u00f6l\u00fcm",
        "New Festival is successfully created!": "Yeni Festival ba\u015far\u0131yla olu\u015fturuldu!",
        "New Message": "Yeni Mesaj",
        "New Password": "Yeni \u015eifre",
        "New Password (at least 8 characters)": "Yeni \u015eifre (en az 8 karakter)",
        "New Performance Requested": "Yeni Performans \u0130stendi",
        "New Performer Profile": "Yeni Oyuncu Profili",
        "New This Week": "Bu Hafta Yeni",
        "New Venue Profile": "Yeni Mekan Profili",
        "New users will not be able to create an account after this date.": "Yeni kullan\u0131c\u0131lar bu tarihten sonra hesap olu\u015fturamayacaklard\u0131r.",
        "Newest First": "En Yeni \u0130lk",
        "Next": "Sonraki",
        "Next Step": "Sonraki ad\u0131m",
        "Next: Availability": "Uygunluk",
        "Next: Contacts": "Sonraki: Ki\u015filer",
        "Next: Location": "Ya\u011fmur Yeri",
        "Next: Performance Details": "Performans Ayr\u0131nt\u0131lar\u0131",
        "Next: Performance Time": "Performans zaman\u0131n\u0131 se\u00e7in:",
        "Next: Performer Details": "Performans Ayr\u0131nt\u0131lar\u0131",
        "No": "Numara",
        "No Electricity Allowed": "Elektrik \u0130htiya\u00e7lar\u0131",
        "No Electricity Needed": "Elektrik \u0130htiya\u00e7lar\u0131",
        "No Electricity Provided": "Elektrik \u0130htiya\u00e7lar\u0131",
        "No Electricity Used": "Elektrik \u0130htiya\u00e7lar\u0131",
        "No Performances Found": "Performans Bulunamad\u0131",
        "No Profiles Found": "Profil Bulunamad\u0131",
        "No amplification of any kind (amps, mics, or speakers) is permitted at this site": "Bu sitede herhangi bir amplifikasyona (amplifikat\u00f6r, mikrofon veya hoparl\u00f6r) izin verilmez.",
        "No conversations yet - drop them a line!": "Hen\u00fcz konu\u015fma yok - siz ba\u015flat\u0131n!",
        "No request body was provided.": "Herhangi bir istek g\u00f6vdesi sa\u011flanmad\u0131.",
        "No request body was provided. It should be JSON data.": "Herhangi bir istek g\u00f6vdesi sa\u011flanmad\u0131. JSON verileri olmal\u0131d\u0131r.",
        "No request body was provided. It should be form encoded data.": "Herhangi bir istek g\u00f6vdesi sa\u011flanmad\u0131. Form kodlu veri olmal\u0131d\u0131r.",
        "No such schema %(classname)s in package %(path)s exists!": "%(path)s paketinde %(classname)s b\u00f6yle bir \u015fema yok!",
        "Not Accepting Requests": "\u0130stekler Kabul Edilmez",
        "Not Wheelchair Accessible": "Tekerlekli Sandalye \u0130le Eri\u015filemez",
        "Not a JSON payload": "JSON y\u00fck\u00fc de\u011fil",
        "Not available for requests": "\u0130stekler i\u00e7in uygun de\u011filiz",
        "Notes for Performers": "Sanat\u00e7\u0131lar i\u00e7in Notlar",
        "OAuth client is not authorized for that grant type.": "OAuth istemcisi, bu hibe t\u00fcr\u00fc i\u00e7in yetkilendirilmedi.",
        "OK": "TAMAM",
        "OOPS! It looks like registration hasn't opened yet for this city. Please contact your city organizer or check back later.": "OOPS! G\u00f6r\u00fcn\u00fc\u015fe g\u00f6re bu \u015fehir i\u00e7in hen\u00fcz kay\u0131tlar a\u00e7\u0131lmam\u0131\u015f. L\u00fctfen \u015fehir organizat\u00f6r\u00fcn\u00fczle ileti\u015fime ge\u00e7in veya daha sonra tekrar kontrol edin.",
        "Office Building": "Ofis binas\u0131",
        "Ok": "TAMTAM",
        "Oldest First": "En Eski \u0130lk",
        "Once both parties have confirmed that the performance is set, your city admin will give final confirmation that the performance is a go. This may take some time, but never fear, you will hear back!": "Her iki taraf da performans\u0131n ayarland\u0131\u011f\u0131n\u0131 onaylad\u0131ktan sonra, \u015fehir y\u00f6neticiniz admininiz devam etti\u011fine dair nihai onay verecektir. Bu biraz zaman alabilir, ama mutlaka haber alacaks\u0131n\u0131z!",
        "One last thing!": "Son bir \u015fey!",
        "Online If Needed": "Gerekirse \u00c7evrimi\u00e7i",
        "Online Only": "Sadece \u00e7evrimi\u00e7i",
        "Online only": "sadece \u00e7evrimi\u00e7i",
        "Open to Anything": "Her \u015eeye A\u00e7\u0131k",
        "Opera": "Opera",
        "Other": "Di\u011fer",
        "Parade": "Ge\u00e7it t\u00f6reni",
        "Park": "Park",
        "Parking Lot": "Otopark",
        "Participatory": "Kat\u0131l\u0131mc\u0131",
        "Password": "\u015eifre",
        "Password (at least 8 characters)": "\u015eifre (en az 8 karakter)",
        "Password Reset": "\u015eifre s\u0131f\u0131rlama",
        "Password Reset Requested": "\u015eifre S\u0131f\u0131rlama \u0130stendi",
        "Password Update Notification": "\u015eifre G\u00fcncelleme Bildirimi",
        "Password was reset!": "\u015eifre s\u0131f\u0131rland\u0131!",
        "Pause Chapter": "B\u00f6l\u00fcm\u00fc Duraklat",
        "Paused": "Duraklat\u0131ld\u0131",
        "Pending": "Bekliyor",
        "Pending Your Approval": "Onay\u0131n\u0131z Bekleniyor",
        "Performance Cancelled": "Performans iptal edilecek",
        "Performance Confirmed": "Performans Onayland\u0131",
        "Performance Details": "Performans Ayr\u0131nt\u0131lar\u0131",
        "Performance Request Accepted": "Performans Talebi Kabul Edildi",
        "Performance Status": "Performans durumu",
        "Performance Time": "Performans S\u00fcresi",
        "Performance will be canceled": "Performans iptal edilecek",
        "Performance will go on as scheduled": "Performans planland\u0131\u011f\u0131 gibi devam edecek",
        "Performance will move to a nearby sheltered place": "Performans yak\u0131ndaki korunakl\u0131 bir yere ta\u015f\u0131nacak",
        "Performances": "performanslar",
        "Performer": "\u0130crac\u0131",
        "Performer Availability": "\u0130crac\u0131 Uygunlu\u011fu",
        "Performer Bio": "\u0130crac\u0131 Biyografisi",
        "Performer Info": "\u0130crac\u0131 Bilgisi",
        "Performer Name": "\u0130crac\u0131 Ad\u0131",
        "Performer or Venue<br/>Looking for a Match": "\u0130crac\u0131 veya Mekan<br/>E\u015fle\u015fme Ar\u0131yor",
        "Performers": "icrac\u0131lar",
        "Performers seeking<br/>Venue": "M\u00fczisyenim, <br/> Mekan Ar\u0131yorum",
        "Phone": "Telefon",
        "Phone Number": "Telefon numaras\u0131",
        "Piano": "Piyano",
        "Pick An Image": "Bir Resim Se\u00e7",
        "Pick Another Image": "Ba\u015fka Bir Resim Se\u00e7",
        "Plaza or Patio": "Plaza veya Veranda",
        "Please check your email inbox, and follow the instructions to reset your password.": "L\u00fctfen e-posta gelen kutunuzu kontrol edin ve \u015fifrenizi s\u0131f\u0131rlamak i\u00e7in talimatlar\u0131 izleyin.",
        "Please check your inbox to confirm your email address.": "L\u00fctfen e-posta adresinizi onaylamak i\u00e7in gelen kutunuzu kontrol edin.",
        "Please confirm your email address and you'll be able to find a performer. Click the button below to resend the confirmation message.": "L\u00fctfen e-posta adresinizi onaylay\u0131n ve bir sanat\u00e7\u0131 bulabileceksiniz. Onay mesaj\u0131n\u0131 yeniden g\u00f6ndermek i\u00e7in a\u015fa\u011f\u0131daki d\u00fc\u011fmeyi t\u0131klay\u0131n.",
        "Please confirm your email address and you'll be able to find a venue. Click the button below to resend the confirmation message.": "L\u00fctfen e-posta adresinizi onaylay\u0131n ve bir mekan bulabileceksiniz. Onay mesaj\u0131n\u0131 yeniden g\u00f6ndermek i\u00e7in a\u015fa\u011f\u0131daki d\u00fc\u011fmeyi t\u0131klay\u0131n.",
        "Please describe your venue, including anything you plan to offer musicians, for example, what equipment you have, expected audiences, any hospitality or monetary compensation, etc (1000 character max)": "M\u00fczisyenlere sunmay\u0131 planlad\u0131\u011f\u0131n\u0131z her \u015fey dahil olmak \u00fczere l\u00fctfen mekan\u0131n\u0131z\u0131 tan\u0131mlay\u0131n, \u00f6rne\u011fin sahip oldu\u011funuz ekipman, beklenen izleyiciler, herhangi bir misafirperverlik veya \u00fccret \u00f6denmesi vb (en fazla 1000 karakter)",
        "Please login with your new password": "L\u00fctfen yeni \u015fifrenizle giri\u015f yap\u0131n",
        "Pop": "Pop",
        "Postal Code": "Posta Kodu",
        "Poster Options": "Web sitesi (iste\u011fe ba\u011fl\u0131)",
        "Preferred Genre": "Tercih Edilen M\u00fczik T\u00fcr",
        "Preferred Genre(s)": "Tercih Edilen M\u00fczik T\u00fcr(leri)\u00fc",
        "Preview": "\u00d6n izleme",
        "Previous": "\u00d6nceki",
        "Primary Buttons & Highlight Color": "Birincil Butonlar ve Vurgu Rengi",
        "Profile Created Date": "Profil Olu\u015fturma Tarihi",
        "Profile Status": "Profil Durumu",
        "Profiles": "profiller",
        "Provide any introduction you feel is helpful.": "Yararl\u0131 oldu\u011funu d\u00fc\u015f\u00fcnd\u00fc\u011f\u00fcn\u00fcz herhangi bir a\u00e7\u0131klama yaz\u0131n.",
        "Provides": "Sa\u011flar",
        "Provides Electricity": "Elektrik Var",
        "Punk": "Punk",
        "R&B": "R&B",
        "Ragtime": "Ragtime",
        "Rain Location": "Ya\u011fmur Yeri",
        "Rain or Shine": "Her Hal\u00fckarda",
        "Rain or shine": "Her Hal\u00fckarda",
        "Reactivate": "Yeniden etkinle\u015ftir",
        "Reggae": "Reggae",
        "Register Event": "Etkinli\u011fi Kaydet",
        "Register Performer": "\u0130crac\u0131y\u0131 Kaydet",
        "Register Venue": "Mekan\u0131 Kaydet",
        "Registered Performers": "Kay\u0131tl\u0131 \u0130crac\u0131lar",
        "Registered Venues": "Kay\u0131tl\u0131 Mekanlar",
        "Religious": "Dini M\u00fczik",
        "Remember Me": "Beni Hat\u0131rla",
        "Remove a genre from this city.": "Bu \u015fehirden bir m\u00fczik t\u00fcr\u00fcn\u00fc kald\u0131r\u0131n.",
        "Remove a venue type from this city.": "Bu \u015fehirden bir mekan t\u00fcr\u00fcn\u00fc kald\u0131r\u0131n.",
        "Remove all custom email templates from this city.": "Bu \u015fehirdeki t\u00fcm \u00f6zel e-posta \u015fablonlar\u0131n\u0131 kald\u0131r\u0131n.",
        "Remove all custom genres from this city.": "Bu \u015fehirdeki t\u00fcm \u00f6zel m\u00fczik t\u00fcrlerini kald\u0131r\u0131n.",
        "Remove all custom venue types from this city.": "Bu \u015fehirdeki t\u00fcm \u00f6zel mekan t\u00fcrlerini kald\u0131r\u0131n.",
        "Renew this artist for another festival.": "Bu sanat\u00e7\u0131y\u0131 ba\u015fka bir festival i\u00e7in yenileyin.",
        "Renew this city for the current festival year.": "Bu \u015fehri mevcut festival y\u0131l\u0131 i\u00e7in yenileyin.",
        "Renew this venue for another festival.": "Bu mekan\u0131 ba\u015fka bir festival i\u00e7in yenileyin.",
        "Reply to this conversation.": "Bu konu\u015fmay\u0131 yan\u0131tlay\u0131n.",
        "Reply to this message.": "Bu mesaj\u0131 yan\u0131tlay\u0131n.",
        "Request a Performance": "Performans \u0130ste",
        "Request a new confirmation email.": "Yeni bir onay e-postas\u0131 isteyin.",
        "Request a password reset email.": "Parola s\u0131f\u0131rlama e-postas\u0131 isteyin.",
        "Request a performance from: ": "\u015euradan bir performans isteyin:",
        "Request from performer": "icrac\u0131dan istek",
        "Request from venue": "mekandan istek",
        "Request origin blocked due to CORS configuration.": "CORS yap\u0131land\u0131rmas\u0131 nedeniyle istek kayna\u011f\u0131 engellendi.",
        "Request to perform at: ": "\u015eurada ger\u00e7ekle\u015ftirme iste\u011fi:",
        "Requested by you": "sizin taraf\u0131n\u0131zdan talep edildi",
        "Requested scopes do not match with scopes the OAuth client and user is authorized for.": "\u0130stenen kapsamlar, OAuth istemcisinin ve kullan\u0131c\u0131n\u0131n yetkilendirildi\u011fi kapsamlarla e\u015fle\u015fmiyor.",
        "Requests over 1 week old": "1 haftadan eski istekler",
        "Requests over 72 hours old": "72 saatten eski istekler",
        "Required field is missing": "Gerekli alan eksik",
        "Required field is missing or empty": "Gerekli alan eksik veya bo\u015f",
        "Resend Confirmation": "Yeni bir sohbet olu\u015fturun.",
        "Reset": "S\u0131f\u0131rla",
        "Reset Password": "\u015eifreyi yenile",
        "Resize & Crop": "Yeniden Boyutland\u0131r ve K\u0131rp",
        "Retail Shop": "Perakende ma\u011fazas\u0131",
        "Revoke city admin capabilities of a user.": "Bir kullan\u0131c\u0131n\u0131n \u015fehir admini yetkilerini iptal edin.",
        "Rock": "Rock",
        "Rooftop": "\u00e7at\u0131 kat\u0131",
        "Samba": "Samba",
        "Save": "Kaydet",
        "Save Changes": "De\u011fi\u015fiklikleri Kaydet",
        "Save Festival": "Festivali Kaydet",
        "Save Performance": "Performans\u0131 Kaydet",
        "Save Performances": "Performanslar\u0131 Kaydet",
        "Save Request": "\u0130ste\u011fi Kaydet",
        "Save Template": "\u015eablonu Kaydet",
        "Save as CSV": "CSV olarak kaydet",
        "Save as Excel": "Excel olarak kaydet",
        "Save to Profile": "Profile Kaydet",
        "Schedule": "Planla",
        "Schedule a Performance": "Performans Planla",
        "Schedule a performance at: ": "\u015eu adreste bir performans planlay\u0131n:",
        "Schedule a performance from: ": "\u015euradan bir performans planlay\u0131n:",
        "Scheduling deadline": "planlama i\u00e7in son tarih",
        "School": "Okul",
        "Search": "Ara",
        "Search Performers": "\u0130crac\u0131lar\u0131 Ara",
        "Search Venues": "Mekanlar\u0131 Ara",
        "Secondary Buttons": "\u0130kincil Butonlar",
        "Select a performer.": "Bir icrac\u0131 se\u00e7in.",
        "Select a venue.": "Bir mekan se\u00e7in.",
        "Select an Image": "Birini se\u00e7",
        "Select neighborhood": "Mahalle se\u00e7in",
        "Select one": "Birini se\u00e7",
        "Select performance time:": "Performans zaman\u0131n\u0131 se\u00e7in:",
        "Select the city": "\u015fehri se\u00e7in",
        "Select up to 3 genres": "3 m\u00fczik t\u00fcr\u00fcne kadar se\u00e7in",
        "Select venue type": "Mekan t\u00fcr\u00fcn\u00fc se\u00e7in",
        "Self-Match Profiles": "Kendi Kendine E\u015fle\u015fen Profiller",
        "Send": "G\u00f6nder",
        "Send Request": "\u0130stek g\u00f6nder",
        "Send a Message": "Bir mesaj g\u00f6nder",
        "Send a performance request to the performer.": "Sanat\u00e7\u0131ya bir performans iste\u011fi g\u00f6nderin.",
        "Set Default to NO": "Varsay\u0131lan\u0131 HAYIR olarak ayarla",
        "Set New Password": "Yeni \u015eifre Belirle",
        "Set deadlines for users to sign up, create profiles, and create performances. All deadlines begin at midnight at the beginning of the day selected. (For instance, if you set a deadline for June 1, it will take effect on June 1st at 12:01am.) If you do not have any deadlines for participation, set all three dates to be after your festival date. These deadlines can be changed later, even after your site is open to the public.": "Kullan\u0131c\u0131lar\u0131n kaydolmas\u0131, profiller olu\u015fturmas\u0131 ve performanslar olu\u015fturmas\u0131 i\u00e7in son tarihleri belirleyin. T\u00fcm son tarihler, se\u00e7ilen g\u00fcn\u00fcn sonunda gece yar\u0131s\u0131nda ba\u015flar. (\u00d6rne\u011fin, 1 Haziran i\u00e7in bir son tarih belirlerseniz, 2 Haziran saat 00:01'de y\u00fcr\u00fcrl\u00fc\u011fe girer.) Kat\u0131l\u0131m i\u00e7in herhangi bir son tarih yoksa, \u00fc\u00e7 tarihi de 21 Haziran'dan sonraki bir tarih olarak ayarlay\u0131n. Bu son tarihler siteniz yay\u0131nland\u0131ktan sonra da de\u011fi\u015ftirilebilir.",
        "Set up your city\u2019s districts and neighborhoods. <span accent>Once the platform goes live for participants, you can not edit existing districts or neighborhoods, but you can add new ones.</span>": "\u015eehrinizin b\u00f6lgelerini ve mahallelerini ayarlay\u0131n. <span aksan>Platform kat\u0131l\u0131mc\u0131lar i\u00e7in yay\u0131na girdikten sonra mevcut il\u00e7eleri veya mahalleleri d\u00fczenleyemezsiniz, ancak yenilerini ekleyebilirsiniz.</span>",
        "Setup": "Kurulu\u015f",
        "Shopping Center": "Al\u0131\u015fveri\u015f Merkezi",
        "Showing": "g\u00f6steriliyor",
        "Shy": "Utanga\u00e7",
        "Shy Users": "Utanga\u00e7 Kullan\u0131c\u0131lar",
        "Sidewalk": "Kald\u0131r\u0131m",
        "Sign Up": "\u00dcye olun",
        "Sign Up for": "\u0130\u00e7in kay\u0131t olun",
        "Sign Up for ": "\u0130\u00e7in kay\u0131t olun",
        "Sign up deadline": "son kay\u0131t tarihi",
        "Singer / Songwriter": "Singer / Songwriter",
        "Ska": "Ska",
        "Social Media": "Sosyal medya",
        "Sort by:": "G\u00f6re s\u0131rala:",
        "Soul": "Soul",
        "SoundCloud Tracks (optional)": "SoundCloud Par\u00e7alar\u0131 (iste\u011fe ba\u011fl\u0131)",
        "SoundCloud URL": "SoundCloud URL'si",
        "Soundcloud": "SoundCloud",
        "Standards": "standartlar",
        "Start Time": "Ba\u015flang\u0131\u00e7",
        "Start from the Beginning": "Ba\u015flang\u0131\u00e7tan ba\u015fla",
        "Start time (earliest to latest)": "Ba\u015flang\u0131\u00e7 \u200b\u200bzaman\u0131 (en erkenden en yeniye)",
        "Start time (latest to earliest)": "Ba\u015flang\u0131\u00e7 \u200b\u200bzaman\u0131 (en ge\u00e7ten en erkene)",
        "Street Address": "A\u00e7\u0131k adres",
        "Street or Block Party": "Sokak veya Apartman Partisi",
        "Subject": "Konu",
        "Submit Event for Approval": "Etkinli\u011fi Onay \u0130\u00e7in G\u00f6nderin",
        "Success": "Ba\u015far\u0131",
        "Success!": "Ba\u015far\u0131!",
        "Super Admin": "S\u00fcper Admin",
        "Swing": "Swing",
        "Switch to": "Buna \u00c7evirin",
        "System": "Sistem",
        "Tell the audience a little about the venue. (300 character max)": "\u0130zleyicilere mekan hakk\u0131nda biraz bilgi verin. (en fazla 300 karakter)",
        "Tell the performer and the venue why you think they are a good match.": "Sanat\u00e7\u0131ya ve mekana neden iyi bir e\u015fle\u015fme oldu\u011funu d\u00fc\u015f\u00fcnd\u00fc\u011f\u00fcn\u00fcz\u00fc s\u00f6yleyin.",
        "Tell us about your performer(s).": "Bize sanat\u00e7\u0131(lar)\u0131n\u0131zdan bahsedin.",
        "Terms & Conditions": "\u015fartlar ve ko\u015fullar",
        "Terms & Conditions Can Not Be Empty": "\u015eartlar ve Ko\u015fullar Bo\u015f B\u0131rak\u0131lamaz",
        "Thank you": "Te\u015fekk\u00fcr ederim",
        "Thank you for confirming your email address.": "E-posta adresinizi onaylad\u0131\u011f\u0131n\u0131z i\u00e7in te\u015fekk\u00fcr ederiz.",
        "Thank you for registering for Make Music Day! Please confirm your email": "Make Music Day'e kaydoldu\u011funuz i\u00e7in te\u015fekk\u00fcr ederiz! L\u00fctfen e-postan\u0131z\u0131 onaylay\u0131n",
        "Thank you, the confirmation email has been re-sent. Please check your email inbox": "Te\u015fekk\u00fcrler, onay e-postas\u0131 yeniden g\u00f6nderildi. L\u00fctfen e-posta gelen kutunuzu kontrol edin",
        "That email is already in use.": "Bu e-posta zaten kullan\u0131l\u0131yor.",
        "That genre already exists.": "O m\u00fczik t\u00fcr zaten var.",
        "That venue type already exists.": "Bu mekan t\u00fcr\u00fc zaten var.",
        "The City Admin will be in touch to confirm performances or follow up if needed. This may take a little while, but don't worry, we will get back to you!": "\u015eehir Y\u00f6neticisi, performanslar\u0131 onaylamak veya gerekirse takip etmek i\u00e7in sizinle ileti\u015fime ge\u00e7ecektir. Bu biraz zaman alabilir ama merak etmeyin, size geri d\u00f6nece\u011fiz!",
        "The SELECT query for the database succeeded, but did not return a valid value.": "Veritaban\u0131 i\u00e7in SELECT sorgusu ba\u015far\u0131l\u0131, ancak ge\u00e7erli bir de\u011fer d\u00f6nd\u00fcrmedi.",
        "The administrator has disabled this city's \"matchmaking\" features. Please continue to sign up as a venue or a performer, and you will be able to register your events.": "Bu y\u0131l sosyal mesafe endi\u015feleri nedeniyle toplulu\u011fumuz Make Music Day i\u00e7in \"e\u015fle\u015ftirme\" \u00f6zelliklerini devre d\u0131\u015f\u0131 b\u0131rak\u0131yor. L\u00fctfen bir mekan veya sanat\u00e7\u0131 olarak kaydolmaya devam edin ve yine de 21 Haziran etkinliklerinizi ekleyebilirsiniz.",
        "The current user is not an admin for the given city.": "Ge\u00e7erli kullan\u0131c\u0131, belirtilen \u015fehir i\u00e7in y\u00f6netici de\u011fil.",
        "The end time cannot be before the start time.": "Biti\u015f zaman\u0131, ba\u015flang\u0131\u00e7 ",
        "The event has been confirmed by the artist or venue. You cannot change the time of it.": "Etkinlik, sanat\u00e7\u0131 veya mekan taraf\u0131ndan onayland\u0131. Bunun zaman\u0131n\u0131 de\u011fi\u015ftiremezsiniz.",
        "The event is in an unhandled status.": "Olay i\u015flenmemi\u015f durumda.",
        "The event request is not valid.": "Etkinlik iste\u011fi ge\u00e7erli de\u011fil.",
        "The genre selected is not valid for an artist.": "Se\u00e7ilen m\u00fczik t\u00fcr\u00fc bir sanat\u00e7\u0131 i\u00e7in ge\u00e7erli de\u011fil.",
        "The performance has been approved!": "Performans onayland\u0131!",
        "The performance has been canceled!": "Performans iptal edildi!",
        "The performance has been created!": "Performans olu\u015fturuldu!",
        "The performance has been updated!": "Performans g\u00fcncellendi!",
        "The performer already has a confirmed event that overlaps with this performance time.": "\u0130crac\u0131n\u0131n zaten bu performans s\u00fcresiyle \u00e7ak\u0131\u015fan onaylanm\u0131\u015f bir etkinli\u011fi var.",
        "The performer already has a confirmed event that overlaps with this performance time. Are you sure you want to go ahead?": "\u0130crac\u0131n\u0131n zaten bu performans s\u00fcresiyle \u00e7ak\u0131\u015fan onaylanm\u0131\u015f bir etkinli\u011fi var.",
        "The performer is unavailable during the requested time.": "\u0130crac\u0131, istenen s\u00fcre boyunca m\u00fcsait de\u011fil.",
        "The performer will be notified.<br/><br/>The City Admin will be in touch to confirm or follow up if needed. This may take a little while, but don\u2019t worry, we will get back to you!": "\u0130crac\u0131 bilgilendirilecektir.<br/><br/>\u015eehir admini gerekirse onaylamak veya takip etmek i\u00e7in ileti\u015fime ge\u00e7ecektir. Bu biraz zaman alabilir ama merak etmeyin, size geri d\u00f6nece\u011fiz!",
        "The provided credentials were invalid.": "Sa\u011flanan kimlik bilgileri ge\u00e7ersizdi.",
        "The provided credentials were unauthorized to perform this action.": "Sa\u011flanan kimlik bilgileri bu eylemi ger\u00e7ekle\u015ftirmek i\u00e7in yetkisizdi.",
        "The provided district cannot be deleted until all neighbourhoods have been removed from it.": "Se\u00e7ilen b\u00f6lge, t\u00fcm mahalleler ondan kald\u0131r\u0131lana kadar silinemez.",
        "The provided genre is a system value and cannot be deleted.": "Bu m\u00fczik t\u00fcr\u00fc bir sistem de\u011feridir ve silinemez.",
        "The provided neighbourhood cannot be added without specifying a district unless all districts have been removed first.": "Girilen mahalle, ilk \u00f6nce t\u00fcm il\u00e7eler kald\u0131r\u0131lmad\u0131\u011f\u0131 s\u00fcrece, il\u00e7e belirtilmeden eklenemez.",
        "The provided object was not a City object": "Sa\u011flanan nesne bir \u015eehir nesnesi de\u011fildi",
        "The provided object was not a User object": "Sa\u011flanan nesne bir Kullan\u0131c\u0131 nesnesi de\u011fildi",
        "The provided password was invalid.": "Girilen \u015fifre ge\u00e7ersiz.",
        "The provided query arguments contain an invalid key and/or value.": "Sa\u011flanan sorgu ba\u011f\u0131ms\u0131z de\u011fi\u015fkenleri ge\u00e7ersiz bir anahtar ve/veya de\u011fer i\u00e7eriyor.",
        "The provided timezone is invalid.": "Sa\u011flanan saat dilimi ge\u00e7ersiz.",
        "The provided type was not a valid export format.": "Sa\u011flanan t\u00fcr, ge\u00e7erli bir d\u0131\u015fa aktarma bi\u00e7imi de\u011fildi.",
        "The provided venue type is a system value and cannot be deleted.": "Se\u00e7ilen mekan t\u00fcr\u00fc bir sistem de\u011feridir ve silinemez.",
        "The request appears in your inbox and the other person\u2019s, where you can have a conversation about the performance, adjust the time as needed, and confirm.": "\u0130stek, performans hakk\u0131nda konu\u015fabilmeniz, zaman\u0131 gerekti\u011fi gibi ayarlayabilmeniz ve onaylayabilmenizi\u00e7in sizin ve di\u011fer ki\u015finin gelen kutusunda g\u00f6r\u00fcn\u00fcr.",
        "The request cannot be fulfilled due to bad syntax.": "K\u00f6t\u00fc s\u00f6zdizimi nedeniyle istek yerine getirilemez.",
        "The request export type is not valid.": "\u0130stek d\u0131\u015fa aktarma t\u00fcr\u00fc ge\u00e7erli de\u011fil.",
        "The request requires user authentication.": "\u0130stek, kullan\u0131c\u0131 kimlik do\u011frulamas\u0131 gerektiriyor.",
        "The request signature is not valid.": "\u0130stek imzas\u0131 ge\u00e7erli de\u011fil.",
        "The requested city is not setup in this system.": "\u0130stenen \u015fehir bu sistemde kurulu de\u011fil.",
        "The requested resource could not be located.": "\u0130stenen kaynak bulunamad\u0131.",
        "The requested resource owner could not be determined.": "\u0130stenen kaynak sahibi belirlenemedi.",
        "The requested time is outside the festival start or end time.": "\u0130stenen saat, festival ba\u015flang\u0131\u00e7 ",
        "The reset token has expired.": "S\u0131f\u0131rlama belirtecinin s\u00fcresi doldu.",
        "The server does not support the functionality required to fulfill the request.": "Sunucu, iste\u011fi yerine getirmek i\u00e7in gereken i\u015flevselli\u011fi desteklemiyor.",
        "The server has not found anything matching the Request-URI.": "Sunucu, \u0130stek URI'si ile e\u015fle\u015fen hi\u00e7bir \u015fey bulamad\u0131.",
        "The server is currently unavailable.": "Sunucu \u015fu anda kullan\u0131lam\u0131yor.",
        "The server understood the request, but is refusing to fulfill it.": "Sunucu iste\u011fi anlad\u0131, ancak yerine getirmeyi reddediyor.",
        "The specified city already exists.": "Belirtilen \u015fehir zaten var.",
        "The specified template type already exists for this city": "Belirtilen \u015fablon t\u00fcr\u00fc bu \u015fehir i\u00e7in zaten mevcut",
        "The specified user account already exists.": "Belirtilen kullan\u0131c\u0131 hesab\u0131 zaten var.",
        "The system could not send the appropriate message via email. Please try again later.": "Sistem uygun mesaj\u0131 e-posta yoluyla g\u00f6nderemedi. L\u00fctfen daha sonra tekrar deneyiniz.",
        "The system could not send the email because the required template was not found.": "Gerekli \u015fablon bulunamad\u0131\u011f\u0131ndan sistem e-postay\u0131 g\u00f6nderemedi.",
        "The system has not been completely initialized.": "Sistem tamamen ba\u015flat\u0131lmad\u0131.",
        "The token you are using is not attached to a user account.": "Kullanmakta oldu\u011funuz belirte\u00e7 bir kullan\u0131c\u0131 hesab\u0131na ba\u011fl\u0131 de\u011fil.",
        "The translation catalog was not found.": "\u00c7eviri katalo\u011fu bulunamad\u0131.",
        "The user with that email and password could not be found.": "Bu e-posta ve \u015fifreye sahip kullan\u0131c\u0131 bulunamad\u0131.",
        "The venue already has a confirmed event that overlaps with this performance time.": "\u0130crac\u0131n\u0131n zaten bu performans s\u00fcresiyle \u00e7ak\u0131\u015fan onaylanm\u0131\u015f bir etkinli\u011fi var.",
        "The venue already has a confirmed event that overlaps with this performance time. Are you sure you want to go ahead?": "Mekan\u0131n zaten bu performans s\u00fcresiyle \u00e7ak\u0131\u015fan onaylanm\u0131\u015f bir etkinli\u011fi var. Devam etmek istedi\u011finden emin misin?",
        "The venue already has an event confirmed starting or ending during the requested times.": "Mekan\u0131n, talep edilen zamanlarda ba\u015flamas\u0131 veya bitmesi onaylanm\u0131\u015f bir etkinli\u011fi zaten var.",
        "The venue is unavailable during the requested time.": "Mekan istenen saatte m\u00fcsait de\u011fil.",
        "The venue will be notified.<br/><br/>The City Admin will be in touch to confirm or follow up if needed. This may take a little while, but don\u2019t worry, we will get back to you!": "Mekan bilgilendirilecektir.<br/><br/>Kent Y\u00f6neticisi, gerekirse onaylamak veya takip etmek i\u00e7in ileti\u015fime ge\u00e7ecektir. Bu biraz zaman alabilir ama merak etmeyin, size geri d\u00f6nece\u011fiz!",
        "Theater or Arts Venue": "Tiyatro veya Sanat Mekan\u0131",
        "There are overlapped performance times. Are you sure you want to go ahead?": "\u00c7ak\u0131\u015fan performans s\u00fcreleri var. Devam etmek istedi\u011finden emin misin?",
        "There are too many profile images for this user. Unable to add another.": "Bu kullan\u0131c\u0131 i\u00e7in \u00e7ok fazla profil resmi var. Ba\u015fka eklenemiyor.",
        "There is currently no active festival for this city.": "\u015eu anda bu \u015fehir i\u00e7in aktif bir festival yok.",
        "These can change later on as you and the performer discuss specifics.": "Bunlar daha sonra siz ve icrac\u0131 ayr\u0131nt\u0131lar\u0131 tart\u0131\u015f\u0131rken de\u011fi\u015febilir.",
        "These can change later on as you and the venue discuss specifics.": "Bunlar daha sonra siz ve mekan ayr\u0131nt\u0131lar\u0131 tart\u0131\u015f\u0131rken de\u011fi\u015febilir.",
        "This OAuth client is not allowed to create users.": "Bu OAuth istemcisinin kullan\u0131c\u0131 olu\u015fturmas\u0131na izin verilmiyor.",
        "This chapter is currently paused.": "Bu b\u00f6l\u00fcm \u015fu anda duraklat\u0131lm\u0131\u015ft\u0131r.",
        "This export may take time, be patient.": "Bu aktar\u0131m zaman alabilir, sab\u0131rl\u0131 olun.",
        "This site is not yet ready to accept new user registrations, please try again later.": "Bu site hen\u00fcz yeni kullan\u0131c\u0131 kay\u0131tlar\u0131n\u0131 kabul etmeye haz\u0131r de\u011fil, l\u00fctfen daha sonra tekrar deneyin.",
        "This will be included in the event listing. Tell the audience a little about the performer (1000 character max)": "Bu, etkinlik listesine dahil edilecektir. \u0130zleyiciye icrac\u0131 hakk\u0131nda biraz bilgi verin (en fazla 1000 karakter)",
        "Time Conflict": "Zaman \u00e7at\u0131\u015fmas\u0131",
        "Time Increments for Bookings": "Rezervasyonlar i\u00e7in Zaman Art\u0131\u015flar\u0131",
        "Times & Deadlines": "S\u00fcreler ve Son Tarihler",
        "Timezone": "Saat dilimi",
        "To complete your registration, please confirm your email address. Just click the link in the confirmation email we sent you. Check your spam folder if you don't see the message right away.": "Kayd\u0131n\u0131z\u0131 tamamlamak i\u00e7in l\u00fctfen e-posta adresinizi onaylay\u0131n. Size g\u00f6nderdi\u011fimiz onay e-postas\u0131ndaki ba\u011flant\u0131ya t\u0131klaman\u0131z yeterlidir. Mesaj\u0131 hemen g\u00f6rmezseniz spam klas\u00f6r\u00fcn\u00fcz\u00fc kontrol edin.",
        "To create your event, we\u2019ll need some basic information about your venue, and performer(s).": "Etkinli\u011finizi olu\u015fturmak i\u00e7in mekan\u0131n\u0131z ve sanat\u00e7\u0131(lar)\u0131n\u0131z hakk\u0131nda baz\u0131 temel bilgilere ihtiyac\u0131m\u0131z olacak.",
        "To create your event, we\u2019ll need the following information:": "Etkinli\u011finizi olu\u015fturmak i\u00e7in a\u015fa\u011f\u0131daki bilgilere ihtiyac\u0131m\u0131z olacak:",
        "To use the matching platform to find performers, fill out the following:": "Sanat\u00e7\u0131lar\u0131 bulmak \u00fczere e\u015fle\u015fme platformunu kullanmak i\u00e7in a\u015fa\u011f\u0131dakileri doldurun:",
        "Total Accounts": "Toplam Hesap",
        "Total Confirmed": "Toplam Onayland\u0131",
        "Transfer this artist profile to another user.": "Bu sanat\u00e7\u0131 profilini ba\u015fka bir kullan\u0131c\u0131ya aktar\u0131n.",
        "Transfer this venue profile to another user.": "Bu mekan profilini ba\u015fka bir kullan\u0131c\u0131ya aktar\u0131n.",
        "Transit Station": "toplu ta\u015f\u0131ma istasyonu",
        "Twitter": "Twitter",
        "Twitter URL": "Twitter URL'si",
        "Type": "Tip",
        "Unable to connect to the server at the moment, please check your network connection": "\u015eu anda sunucuya ba\u011flan\u0131lam\u0131yor, l\u00fctfen a\u011f ba\u011flant\u0131n\u0131z\u0131 kontrol edin",
        "Unaccept this event on behalf of a venue.": "Bir mekan ad\u0131na bu etkinli\u011fi kabul etme",
        "Unaccept this event on behalf of an artist.": "Bir sanat\u00e7\u0131 ad\u0131na bu etkinli\u011fi kabul etme",
        "Unaccept this event.": "Bu etkinli\u011fi kabul etme",
        "Unapprove this event.": "Bu etkinli\u011fi onaylama",
        "Unknown object class": "Bilinmeyen nesne s\u0131n\u0131f\u0131",
        "Unpause Chapter": "B\u00f6l\u00fcm\u00fc Duraklatmay\u0131 Kald\u0131r",
        "Unsupported object type": "Desteklenmeyen nesne t\u00fcr\u00fc",
        "Unsupported value": "desteklenmeyen de\u011fer",
        "Update": "G\u00fcncelle",
        "Update Message": "Yeni Mesaj",
        "Update Performer": "\u0130crac\u0131y\u0131 G\u00fcncelle",
        "Update Profile": "bu profili sil",
        "Update Venue": "Mekan\u0131 G\u00fcncelle",
        "Updated at": "\u015eu saatte g\u00fcncellendi:",
        "Upload": "Y\u00fckle",
        "Upload a new logo for this city.": "Bu \u015fehir i\u00e7in yeni bir logo y\u00fckleyin.",
        "Upload a new profile image for this artist.": "Bu sanat\u00e7\u0131 i\u00e7in yeni bir profil resmi y\u00fckleyin.",
        "Upload a profile image in preparation for creating a performer profile for this user.": "Bu kullan\u0131c\u0131 i\u00e7in bir icrac\u0131 profili olu\u015fturmaya haz\u0131rl\u0131k olarak bir profil resmi y\u00fckleyin.",
        "Uploaded file type was invalid or not acceptable.": "Y\u00fcklenen dosya t\u00fcr\u00fc ge\u00e7ersiz veya kabul edilemez.",
        "Use Districts": "Bu b\u00f6lgeyi d\u00fczenleyin.",
        "Use mouse to click or drag to define the image crop area": "G\u00f6r\u00fcnt\u00fc k\u0131rpma alan\u0131n\u0131 tan\u0131mlamak i\u00e7in t\u0131klamak veya s\u00fcr\u00fcklemek i\u00e7in fareyi kullan\u0131n",
        "User data and profile information.": "Kullan\u0131c\u0131 verileri ve profil bilgileri.",
        "Users will not be able to create a new venue or artist profile after this date.": "Kullan\u0131c\u0131lar bu tarihten sonra yeni bir mekan veya icrac\u0131 profili olu\u015fturamayacaklard\u0131r.",
        "Users will not be able to request or confirm a performance after this date, or to create their own event.": "Kullan\u0131c\u0131lar bu tarihten sonra performans talep edemez veya onaylayamaz veya kendi etkinliklerini olu\u015fturamaz.",
        "Users will only be allowed to schedule performances within this time range.": "Kullan\u0131c\u0131lar\u0131n yaln\u0131zca bu zaman aral\u0131\u011f\u0131ndaki performanslar\u0131 planlamalar\u0131na izin verilir.",
        "Validation error occurred": "Do\u011frulama hatas\u0131 olu\u015ftu",
        "Venue": "Mekan",
        "Venue Availability": "Mekan M\u00fcsaitli\u011fi",
        "Venue Defaults": "Mekan Varsay\u0131lanlar\u0131",
        "Venue Description (optional)": "Mekan A\u00e7\u0131klamas\u0131 (iste\u011fe ba\u011fl\u0131)",
        "Venue Info": "Mekan Bilgileri",
        "Venue Name": "Mekan \u0130smi",
        "Venue Type": "Mekan T\u00fcr\u00fc",
        "Venue Types": "Mekan T\u00fcrleri",
        "Venue creation is currently disabled. Try again later.": "Mekan olu\u015fturma \u015fu anda devre d\u0131\u015f\u0131. Daha sonra tekrar deneyin.",
        "Venue does not have a piano": "Mekanda piyano yok",
        "Venue seeking<br/>Performers": "Mekan\u0131m Var, <br/> M\u00fczisyen Ar\u0131yorum",
        "Venue type is already existed": "Mekan t\u00fcr\u00fc zaten mevcut",
        "Venues": "Mekanlar",
        "View": "G\u00f6r\u00fcnt\u00fcle",
        "View Full Profile": "T\u00fcm profili G\u00f6r\u00fcnt\u00fcle",
        "Virtual Option": "Sanal Se\u00e7enek",
        "Visit Facebook": "Facebook",
        "Visit Instagram": "Instagram",
        "Visit Twitter": "Twitter",
        "Visit YouTube": "YouTube'u ziyaret edin",
        "WARNING": "Swing",
        "WARNING: You are about to create a new festival. Users will no longer be able to register or create events for the current festival. Are you sure you want to continue?": "UYARI: Yeni bir festival olu\u015fturmak \u00fczeresiniz. Kullan\u0131c\u0131lar art\u0131k mevcut festival i\u00e7in kay\u0131t yapt\u0131ramayacak veya etkinlik olu\u015fturamayacak. Devam etmek istedi\u011fine emin misin?",
        "Warning": "Uyar\u0131",
        "Way to go!": "Gitme zaman\u0131!",
        "We found one or more profiles in your account from a previous year. ": "Hesab\u0131n\u0131zda bir \u00f6nceki y\u0131la ait bir veya daha fazla profil bulduk.",
        "We have populated your profile with the information we have on file. Please check each field and make any necessary updates.": "Profilinizi dosyam\u0131zda bulunan bilgilerle doldurduk. L\u00fctfen her alan\u0131 kontrol edin ve gerekli g\u00fcncellemeleri yap\u0131n.",
        "We're excited!": "Heyecanl\u0131y\u0131z!",
        "Website": "\u0130nternet sitesi",
        "Website (optional)": "Web sitesi (iste\u011fe ba\u011fl\u0131)",
        "Welcome Blurb": "Ho\u015f Geldin Yaz\u0131s\u0131",
        "Welcome back!": "Tekrar ho\u015fgeldiniz!",
        "Welcome to Make Music": "M\u00fczik Yapmaya Ho\u015f Geldiniz",
        "Welcome to the<br/>  Matching Site": "E\u015fle\u015fen Siteye<br/> Ho\u015f Geldiniz",
        "Welcome to the<br/> %(festival_name)s Matching Site": "%(festival_name)s E\u015fle\u015ftirme Sitesine<br/> ho\u015f geldiniz",
        "Welcome, let's get started!": "Ho\u015f geldiniz, ba\u015flayal\u0131m!",
        "What kind of music is this venue looking to host?": "Bu mekan ne t\u00fcr m\u00fcziklere ev sahipli\u011fi yapmak istiyor?",
        "What kind of music will this performer play?": "Bu sanat\u00e7\u0131 ne t\u00fcr m\u00fczik \u00e7alacak?",
        "Wheelchair Accessible": "Tekerlekli Sandalye \u0130le Eri\u015filebilir",
        "When is this performer available? Include your full range, performances will be scheduled individually.": "Bu icrac\u0131 ne zaman uygun? T\u00fcm aral\u0131\u011f\u0131n\u0131z\u0131 ekleyin, performanslar ayr\u0131 ayr\u0131 planlanacakt\u0131r.",
        "When is this venue available to host? Include your full range, performances will be scheduled individually.": "Bu mekan ne zaman ev sahipli\u011fi yapmaya m\u00fcsait? T\u00fcm aral\u0131\u011f\u0131n\u0131z\u0131 ekleyin, performanslar ayr\u0131 ayr\u0131 planlanacakt\u0131r.",
        "When users create an account for the first time, they must agree to the \"Terms and Conditions\" to use the website. Paste your city's Terms and Conditions here. If you are using language from a previous year, please check that the dates, city names, and URLs are all up to date. This form supports HTML tags.": "Kullan\u0131c\u0131lar ilk kez bir hesap olu\u015fturduklar\u0131nda, web sitesini kullanmak i\u00e7in \"\u015eartlar ve Ko\u015fullar\" \u0131 kabul etmelidirler. \u015eehrinizin H\u00fck\u00fcm ve Ko\u015fullar\u0131n\u0131 buraya yap\u0131\u015ft\u0131r\u0131n. Bir \u00f6nceki y\u0131la ait bir dil kullan\u0131yorsan\u0131z, l\u00fctfen tarihlerin, \u015fehir adlar\u0131n\u0131n ve URL'lerin g\u00fcncel olup olmad\u0131\u011f\u0131n\u0131 kontrol edin. Bu form HTML etiketlerini destekler.",
        "When would you like this performance?": "Bu performans\u0131 ne zaman istersiniz?",
        "When would you like to perform?": "Ne zaman performans sergilemek istersiniz?",
        "Who is the contact person for this event?": "Bu etkinlik i\u00e7in irtibat ki\u015fisi kimdir?",
        "Who is the contact person for this performer?": "Bu icrac\u0131 i\u00e7in irtibat ki\u015fisi kimdir?",
        "Who is the contact person for this venue?": "Bu mekan i\u00e7in irtibat ki\u015fisi kimdir?",
        "Why do you think this performer would be a good fit?": "Sizce bu icrac\u0131 neden uygun olur?",
        "Why is this location a good fit for you?": "Bu konum neden sizin i\u00e7in uygun?",
        "Will electricity be used for performances at this venue?": "Bu mekanda performanslar i\u00e7in elektrik kullan\u0131lacak m\u0131?",
        "World": "World Music",
        "Write a few words about your venue.": "Mekan\u0131n\u0131z hakk\u0131nda birka\u00e7 kelime yaz\u0131n.",
        "Write a few words about yourself and your music.": "Kendiniz ve m\u00fczi\u011finiz hakk\u0131nda birka\u00e7 kelime yaz\u0131n.",
        "Yes": "Evet",
        "Yes, at ": "Evet, saat",
        "Yes, go ahead": "Evet, devam et",
        "Yes, if necessary": "Evet, gerekirse",
        "Yes, if necessary, at": "Evet, gerekirse,",
        "Yes, let them play!": "Evet, b\u0131rak \u00e7als\u0131nlar!",
        "You": "Sen",
        "You already have a confirmed event that overlaps with this performance time.": "\u0130crac\u0131n\u0131n zaten bu performans s\u00fcresiyle \u00e7ak\u0131\u015fan onaylanm\u0131\u015f bir etkinli\u011fi var.",
        "You are about to accept this performance request.": "Bu performans talebini kabul etmek \u00fczeresiniz.",
        "You are about to deactivate this chapter. This action makes the city's site offline completely. Are you sure you want to continue?": "Bu b\u00f6l\u00fcm\u00fc devre d\u0131\u015f\u0131 b\u0131rakmak \u00fczeresiniz. Bu eylem, \u015fehrin sitesini tamamen \u00e7evrimd\u0131\u015f\u0131 hale getirir. Devam etmek istedi\u011fine emin misin?",
        "You are about to delete this chapter. This action cannot be undone. Are you sure you want to continue?": "Bu b\u00f6l\u00fcm\u00fc silmek \u00fczeresiniz. Bu i\u015flem geri al\u0131namaz. Devam etmek istedi\u011fine emin misin?",
        "You are about to re-enable this chapter. This action brings the city's site online again. Are you sure you want to continue?": "Bu b\u00f6l\u00fcm\u00fc yeniden etkinle\u015ftirmek \u00fczeresiniz. Bu eylem \u015fehrin sitesini tekrar \u00e7evrimi\u00e7i hale getirir. Devam etmek istedi\u011fine emin misin?",
        "You are required to indicate the user that is receiving ownership of this artist profile.": "Bu icrac\u0131 profilinin sahipli\u011fini alan kullan\u0131c\u0131y\u0131 belirtmeniz gerekiyor.",
        "You are required to indicate the user that is receiving ownership of this venue profile.": "Bu mekan profilinin sahipli\u011fini alan kullan\u0131c\u0131y\u0131 belirtmeniz gerekmektedir.",
        "You are searching for a performer for venue ": "Mekan i\u00e7in bir sanat\u00e7\u0131 ar\u0131yorsunuz",
        "You are searching for a venue for performer ": "Sanat\u00e7\u0131 i\u00e7in bir mekan ar\u0131yorsunuz",
        "You can find and book performers through our platform. Check out the <a href=\"/find\">Find Performers</a> page to get started.": "Platformumuz arac\u0131l\u0131\u011f\u0131yla icrac\u0131lar\u0131 bulabilir ve rezerve edebilirsiniz. Ba\u015flamak i\u00e7in <a href=\"/find\">\u0130crac\u0131 Bul</a> sayfas\u0131na g\u00f6z at\u0131n.",
        "You can import past profiles and use them for  (), or create something new. If you don't import your old profiles now, you can always do so later.": "Ge\u00e7mi\u015f profilleri i\u00e7e aktarabilir ve bunlar\u0131 () i\u00e7in kullanabilir veya yeni bir \u015fey olu\u015fturabilirsiniz. Eski profillerinizi \u015fimdi i\u00e7e aktarmazsan\u0131z, bunu daha sonra istedi\u011finiz zaman yapabilirsiniz.",
        "You can import past profiles and use them for %(city_name)s (%(festival_date)s), or create something new. If you don't import your old profiles now, you can always do so later.": "Ge\u00e7mi\u015f profilleri i\u00e7e aktarabilir ve bunlar\u0131 %(city_name)s (%(festival_date)s) i\u00e7in kullanabilir veya yeni bir \u015fey olu\u015fturabilirsiniz. Eski profillerinizi \u015fimdi i\u00e7e aktarmazsan\u0131z, bunu daha sonra istedi\u011finiz zaman yapabilirsiniz.",
        "You created an event!": "Bir etkinlik olu\u015fturdunuz!",
        "You have exceeded the rate limit allotted. Try again later.": "Tahsis edilen oran s\u0131n\u0131r\u0131n\u0131 a\u015ft\u0131n\u0131z. Daha sonra tekrar deneyin.",
        "You have no performances.": "Hi\u00e7 performans\u0131n\u0131z yok.",
        "You may choose an alternate font family.": "Alternatif bir yaz\u0131 tipi ailesi se\u00e7ebilirsiniz.",
        "You may edit your site's welcome text here. This form supports HTML tags.": "Sitenizin kar\u015f\u0131lama metnini buradan d\u00fczenleyebilirsiniz. Bu form HTML etiketlerini destekler.",
        "You must accept the terms and conditions in order to proceed": "Devam etmek i\u00e7in \u015fartlar ve ko\u015fullar\u0131 kabul etmelisiniz",
        "You must confirm your email in order to access this feature.": "Bu \u00f6zelli\u011fe eri\u015fmek i\u00e7in e-postan\u0131z\u0131 onaylaman\u0131z gerekir.",
        "You must enter a email address": "Bir e-posta adresi girmelisiniz",
        "You must enter a location": "Bir konum girmelisiniz",
        "You must enter a name": "Bir ad girmelisiniz",
        "You must enter a phone number": "Bir telefon numaras\u0131 girmelisiniz",
        "You must enter performer's bio": "\u0130crac\u0131n\u0131n a\u00e7\u0131klamas\u0131n\u0131 girmelisiniz",
        "You must enter the performer's description": "\u0130crac\u0131n\u0131n a\u00e7\u0131klamas\u0131n\u0131 girmelisiniz",
        "You must enter the performer's name": "\u0130crac\u0131n\u0131n ad\u0131n\u0131 girmelisiniz",
        "You must enter the venue's description": "Mekan\u0131n a\u00e7\u0131klamas\u0131n\u0131 girmelisiniz",
        "You must enter the venue's name": "Mekan\u0131n ad\u0131n\u0131 girmelisiniz",
        "You must enter the venue's notes for performers": "Sanat\u00e7\u0131lar i\u00e7in mekan\u0131n notlar\u0131n\u0131 girmelisiniz",
        "You must pick 1 to 3 genres": "1 ila 3 m\u00fczik t\u00fcr\u00fc se\u00e7melisiniz",
        "You must provide address and zip_code when the venue allows in person events.": "Mekan y\u00fcz y\u00fcze etkinliklere izin verdi\u011finde adres ve posta kodu sa\u011flamal\u0131s\u0131n\u0131z.",
        "You must select a neighborhood": "Bir mahalle se\u00e7melisiniz",
        "You must select a venue type": "Bir mekan t\u00fcr\u00fc se\u00e7melisiniz",
        "You must select an option": "Bir se\u00e7enek se\u00e7melisiniz",
        "You must select one": "Birini se\u00e7melisiniz",
        "You must specify the district to add.": "Eklenecek il\u00e7eyi belirtmelisiniz.",
        "You must specify the district to delete.": "Silinecek il\u00e7eyi belirtmelisiniz.",
        "You must specify the neighbourhood to delete.": "Silinecek mahalleyi belirtmelisiniz.",
        "You must specify the neighbourhoods to add": "Eklenecek mahalleleri belirtmelisiniz",
        "You'll fill out a profile and be able to see who is available that might be a good fit to plan a performance with.": "Bir profil doldurdu\u011funuzda, birlikte performans planlamak i\u00e7in kimin uygun oldu\u011funu g\u00f6rebileceksiniz.",
        "You're really doing it": "Harika gidiyorsun.",
        "YouTube": "Youtube",
        "YouTube URL": "YouTube URL'si",
        "YouTube Video (optional)": "YouTube Videosu (iste\u011fe ba\u011fl\u0131)",
        "Your Availability": "M\u00fcsaitli\u011finiz",
        "Your Event Profile has been created.<br/><br/>The City Admin will be in touch to confirm performances or follow up if needed. This may take a little while, but don't worry, we will get back to you!": "Etkinlik Profiliniz olu\u015fturuldu.<br/><br/>\u015eehir admini, performanslar\u0131 onaylamak veya gerekirse takip etmek i\u00e7in sizinle ileti\u015fime ge\u00e7ecektir. Bu biraz zaman alabilir ama merak etmeyin, size geri d\u00f6nece\u011fiz!",
        "Your Performance Time": "Performans Zaman\u0131",
        "Your Performer Profile has been created.": "\u0130crac\u0131 Profiliniz olu\u015fturuldu.",
        "Your Performer Profile has been imported.": "Sanat\u00e7\u0131 Profiliniz i\u00e7e aktar\u0131ld\u0131.",
        "Your Performer Profile has been updated.": "\u0130crac\u0131 Profiliniz g\u00fcncellendi.",
        "Your Venue Profile has been updated.": "Mekan Profiliniz g\u00fcncellendi.",
        "Your confirmation token has expired. Check your inbox for a new confirmation email.": "Onay i\u00e7in belirlenen s\u00fcre doldu. Yeni bir onay e-postas\u0131 i\u00e7in gelen kutunuzu kontrol edin.",
        "Your home neighborhood (optional)": "Ya\u015fad\u0131\u011f\u0131n\u0131z mahalle (iste\u011fe ba\u011fl\u0131)",
        "Your performance request has been sent to the performer's inbox.": "Performans talebiniz icrac\u0131n\u0131n gelen kutusuna g\u00f6nderildi.",
        "Your performance request has been sent to the venue's inbox.": "Performans talebiniz mekan\u0131n gelen kutusuna g\u00f6nderildi.",
        "Your profile will be deleted, along with all uploaded images and created events": "Profiliniz, y\u00fcklenen t\u00fcm resimler ve olu\u015fturulan etkinliklerle birlikte silinecek",
        "Your site is currently open to the public": "Siteniz \u015fu anda herkese a\u00e7\u0131k",
        "Youtube URL": "YouTube URL'si",
        "You\u2019re really doing it! Your venue profile has been created.": "Harika gidiyorsun. Mekan profiliniz olu\u015fturuldu.",
        "You\u2019re really doing it! Your venue profile has been imported.": "Ger\u00e7ekten yap\u0131yorsun! Mekan profiliniz i\u00e7e aktar\u0131ld\u0131.",
        "ZIP": "Posta kodu",
        "Zip": "Posta kodu",
        "address field can not be empty": "Adres alan\u0131 bo\u015f b\u0131rak\u0131lamaz",
        "artist": "sanat\u00e7\u0131",
        "by ": "taraf\u0131ndan",
        "by %(profile_name)s": "%(profile_name)s tarafindan",
        "by City Admin": "\u015eehir admini taraf\u0131ndan",
        "by You": "senin taraf\u0131ndan",
        "cancelled": "Etkinlik \u0130ptal Edildi",
        "confirmed": "onaylanm\u0131\u015f",
        "delete this event": "Bu etkinli\u011fi sil",
        "delete this profile": "bu profili sil",
        "does not match the password": "\u015fifreyle e\u015fle\u015fmiyor",
        "email address not found": "E-posta adresi bulunamad\u0131",
        "have sent and received no requests": "hi\u00e7bir istek g\u00f6ndermedi ve almad\u0131",
        "haven't replied to requests": "\u0130steklere cevap vermedi",
        "here": "Burada",
        "in person": "Y\u00fcz y\u00fcze",
        "in person, but will move online if necessary": "Y\u00fcz y\u00fcze, ancak gerekirse \u00e7evrimi\u00e7i olacak",
        "include up to 3 SoundCloud tracks on your profile": "profilinize 3 adede kadar SoundCloud par\u00e7as\u0131 ekleyin",
        "include up to 3 YouTube videos on your profile": "profilinize en fazla 3 YouTube videosu ekleyin",
        "minutes": "dakika",
        "new": "yeni",
        "online only": "Sadece \u00e7evrimi\u00e7i",
        "password must be at least 8 characters": "\u015eifre en az 8 karakter olmal\u0131d\u0131r",
        "pending": "Bekliyor",
        "pending city approval": "bekleyen \u015fehir onay\u0131",
        "performers": "icrac\u0131lar",
        "playing at": "mekan\u0131nda \u00e7al\u0131yor",
        "please enter a street address": "l\u00fctfen bir sokak adresi girin",
        "please enter a valid URL": "l\u00fctfen ge\u00e7erli bir isim girin",
        "please enter a valid name": "l\u00fctfen ge\u00e7erli bir isim girin",
        "please enter a valid phone number": "Ge\u00e7erli bir telefon numaras\u0131 giriniz",
        "please enter a valid postal code": "l\u00fctfen ge\u00e7erli bir posta kodu girin",
        "please enter a valid timezone": "L\u00fctfen ge\u00e7erli bir saat dilimi girin",
        "please enter a valid url": "l\u00fctfen ge\u00e7erli bir isim girin",
        "please move the map below to locate your city": "\u015eehrinizi bulmak i\u00e7in l\u00fctfen a\u015fa\u011f\u0131daki haritay\u0131 hareket ettirin",
        "please read and agree with terms & conditions": "L\u00fctfen \u015fartlar ve ko\u015fullar\u0131 okuyun ve kabul edin",
        "postal code can not be empty": "Posta kodu bo\u015f olamaz",
        "remove": "kald\u0131r",
        "says that no amplification is permitted at their venue. Are you sure you want to continue?": "Mekanlar\u0131nda amplifikasyona izin verilmedi\u011fini s\u00f6yl\u00fcyor. Devam etmek istedi\u011fine emin misin?",
        "says that no amplification is permitted at their venue. But your performer profile says you require electricity and a PA. Are you sure you want to continue?": "Mekanlar\u0131nda amplifikasyona izin verilmedi\u011fini s\u00f6yl\u00fcyor. Ancak sanat\u00e7\u0131 profiliniz, elektrik ve bir PA'ya ihtiyac\u0131n\u0131z oldu\u011funu s\u00f6yl\u00fcyor. Devam etmek istedi\u011fine emin misin?",
        "says that no amplification is permitted at their venue. But your performer profile says you require electricity. Are you sure you want to continue?": "Mekanlar\u0131nda amplifikasyona izin verilmedi\u011fini s\u00f6yl\u00fcyor. Ancak sanat\u00e7\u0131 profiliniz elektri\u011fe ihtiyac\u0131n\u0131z oldu\u011funu s\u00f6yl\u00fcyor. Devam etmek istedi\u011fine emin misin?",
        "says that they can supply electricity, but not a PA. Your performer profile says you require a PA from the venue. Are you sure you want to continue?": "Elektrik sa\u011flayabileceklerini, ancak PA sa\u011flayamayacaklar\u0131n\u0131 s\u00f6yl\u00fcyor. \u0130crac\u0131 profiliniz, mekandan bir PA'ya ihtiyac\u0131n\u0131z oldu\u011funu s\u00f6yl\u00fcyor. Devam etmek istedi\u011fine emin misin?",
        "says that they cannot supply electricity. But your performer profile says you require electricity and a PA from the venue. Are you sure you want to continue?": "Elektri\u011fi sa\u011flayamad\u0131klar\u0131n\u0131 s\u00f6yl\u00fcyor. Ancak icrac\u0131 profiliniz, mekandan elektri\u011fe ve bir PA'ya ihtiyac\u0131n\u0131z oldu\u011funu s\u00f6yl\u00fcyor. Devam etmek istedi\u011fine emin misin?",
        "says that they cannot supply electricity. But your performer profile says you require electricity from the venue. Are you sure you want to continue?": "Elektri\u011fi sa\u011flayamad\u0131klar\u0131n\u0131 s\u00f6yl\u00fcyor. Ancak icrac\u0131 profiliniz, mekandan elektri\u011fe ihtiyac\u0131n\u0131z oldu\u011funu s\u00f6yl\u00fcyor. Devam etmek istedi\u011fine emin misin?",
        "says they need electricity and a PA from the venue. But your venue profile says amplification is not allowed. Are you sure you want to continue?": "Mekandan elektrik ve PA'ya ihtiya\u00e7lar\u0131 oldu\u011funu s\u00f6yl\u00fcyor. Ancak mekan profiliniz amplifikasyona izin verilmedi\u011fini s\u00f6yl\u00fcyor. Devam etmek istedi\u011fine emin misin?",
        "says they need electricity and a PA from the venue. But your venue profile says you can't supply a PA. Are you sure you want to continue?": "Mekandan elektrik ve PA'ya ihtiya\u00e7lar\u0131 oldu\u011funu s\u00f6yl\u00fcyor. Ancak mekan profiliniz bir PA sa\u011flayamayaca\u011f\u0131n\u0131z\u0131 s\u00f6yl\u00fcyor. Devam etmek istedi\u011fine emin misin?",
        "says they need electricity and a PA from the venue. But your venue profile says you can't supply either one. Are you sure you want to continue?": "Mekandan elektrik ve PA'ya ihtiya\u00e7lar\u0131 oldu\u011funu s\u00f6yl\u00fcyor. Ama mekan profiliniz ikisini de sa\u011flayamayaca\u011f\u0131n\u0131z\u0131 s\u00f6yl\u00fcyor. Devam etmek istedi\u011fine emin misin?",
        "says they need electricity from the venue. But your venue profile says amplification is not allowed. Are you sure you want to continue?": "Mekandan elektri\u011fe ihtiya\u00e7lar\u0131 oldu\u011funu s\u00f6yl\u00fcyor. Ancak mekan profiliniz amplifikasyona izin verilmedi\u011fini s\u00f6yl\u00fcyor. Devam etmek istedi\u011fine emin misin?",
        "says they need electricity from the venue. But your venue profile says you can't supply electricity. Are you sure you want to continue?": "Mekandan elektri\u011fe ihtiya\u00e7lar\u0131 oldu\u011funu s\u00f6yl\u00fcyor. Ama mekan profiliniz elektrik sa\u011flayamayaca\u011f\u0131n\u0131z\u0131 s\u00f6yl\u00fcyor. Devam etmek istedi\u011fine emin misin?",
        "says they will use electricity for their performance. But your venue profile says amplification is not allowed. Are you sure you want to continue?": "Performanslar\u0131 i\u00e7in elektrik kullanacaklar\u0131n\u0131 s\u00f6yl\u00fcyor. Ancak mekan profiliniz amplifikasyona izin verilmedi\u011fini s\u00f6yl\u00fcyor. Devam etmek istedi\u011fine emin misin?",
        "total": "Toplam",
        "venue": "mekan",
        "venues": "mekanlar",
        "{{profile.name}}: new message from {{requestor}}!": "{{profile.name}}: {{requestor}}'dan yeni mesaj!",
        "{{profile.name}}: new request from {{requestor}}!": "{{profile.name}}: {{requestor}}'dan yeni istek!",
        "{{profile.name}}: your event has been accepted": "{{profile.name}}: etkinli\u011finiz kabul edildi",
        "{{profile.name}}: your event has been approved": "{{profile.name}}: etkinli\u011finiz onayland\u0131",
        "{{profile.name}}: your event has been canceled": "{{profile.name}}: etkinli\u011finiz iptal edildi",
        "{{profile.name}}: your event has been declined": "{{profile.name}}: etkinli\u011finiz reddedildi"
    }
}