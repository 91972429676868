
import ReactHtmlParser from 'react-html-parser'

import dictionary from '../data/dictionary'
import { localeKeyFixer, getFallbackLocale, defaultLocale } from './utils'
import { isServer, __NEXT_REDUX_STORE__ } from '../lib/with-redux-store'


export const dataSubstitution = (translatedString, data) => {
  if (!translatedString) {
    return translatedString;
  }

  return translatedString.replace(/\%\((.*?)\)[sd]?/g, function(match, token) {
      return data[token]
  });
};

export const translateWithLocale = (locale, key) => {
  let result = key

  if (dictionary[locale] && key in dictionary[locale]) {
    result = dictionary[locale][key]
  }
  if (result == key) {
    const fallbackLocale = getFallbackLocale(locale);
    if (fallbackLocale) {
      result = translateWithLocale(fallbackLocale, key);
    }
  }
  return result;
};

export const translate = (key, data) => {
  const locale = isServer ? defaultLocale : window[__NEXT_REDUX_STORE__].getState().locale
  let result = key;

  if (locale in dictionary) {
    result = translateWithLocale(locale, key);
  }

  if (result == key && localeKeyFixer(locale) in dictionary) {
    result = translateWithLocale(localeKeyFixer(locale), key);
  }

  if (data) {
    result = dataSubstitution(result, data);
  }
  return ReactHtmlParser(result)
};
