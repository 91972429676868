
export const defaultLocale = 'en_US';

export const locales = [
  {
    code: 'de_DE',
    short: 'DE',
    name: 'Deutsch',
    fallback: 'en_GB'
  }, {
    code: 'en_US',
    short: 'US',
    name: 'English (US)'
  }, {
    code: 'en_GB',
    short: 'GB',
    name: 'English (UK)',
    fallback: 'en_US'
  }, {
    code: 'es_ES',
    short: 'ES',
    name: 'Español',
    fallback: 'en_US'
  }, {
    code: 'fr_FR',
    short: 'FR',
    name: 'Français',
    fallback: 'en_GB'
  }, {
    code: 'pt_BR',
    short: 'PT',
    name: 'Português',
    fallback: 'en_GB'
  }, {
    code: 'tr_TR',
    short: 'TR',
    name: 'Türkçe',
    fallback: 'en_US'
  }
];

export const getFallbackLocale = (locale) => {
  const localeData = locales.find(
    item => item.code.toLowerCase() == locale.toLowerCase() || item.short.toLowerCase() == locale.toLowerCase());
  if (localeData && localeData.fallback) {
    return localeData.fallback;
  }
  return null;
};

export const localeKeyFixer = (key) => {
  return key.replace('-', '_');
};

export const isLocale = (tested) => {
  return locales.some(locale => locale.code === tested)
};

export const getDefaultLocale = () => {
  const localSetting = localStorage.getItem('locale')
  const [browserSetting] = navigator.language.split('-')
  if (localSetting && isLocale(localSetting)) {
    return localSetting
  }
  if (isLocale(browserSetting)) {
    return browserSetting
  }
  return defaultLocale
};
