import App from 'next/app'
import Head from 'next/head'
import { Router } from 'next/router'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import withReduxStore from '@app/lib/with-redux-store'

import { ThemeProvider } from 'emotion-theming'
import theme from '@app/styles/theme'

import * as Sentry from '@sentry/browser'
import TagManager from 'react-gtm-module'

import "react-datepicker/dist/react-datepicker.css"
import "react-toggle-switch/dist/css/switch.min.css"
import 'rc-time-picker/assets/index.css'
import "react-image-crop/dist/ReactCrop.css"

import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import localeData from 'dayjs/plugin/localeData'
import * as de_DE from 'dayjs/locale/de'
import * as en_US from 'dayjs/locale/en'
import * as en_GB from 'dayjs/locale/en-gb'
import * as es_ES from 'dayjs/locale/es'
import * as fr_FR from 'dayjs/locale/fr'
import * as pt_BR from 'dayjs/locale/pt-br'
import * as tr_TR from 'dayjs/locale/tr'

Sentry.init({dsn: "https://99d4be23c14644d491b7e75a14196265@o260435.ingest.sentry.io/1726534"})

dayjs.extend(minMax)
dayjs.extend(localizedFormat)
dayjs.extend(localeData)

const tagManagerArgs = {
    gtmId: 'GTM-528GC3C'
}

class MyApp extends App {
  constructor(props) {
    super(props)
    this.persistor = persistStore(props.reduxStore)

    const ALTERNATIVE_FONTS = ', Helvetica, Open Sans, sans-serif'

    // custom theme
    const currentCity = props.reduxStore.getState().city
    const customTheme = { ...theme }
    customTheme.colors.accent = currentCity && currentCity.accent_colour ? currentCity.accent_colour : theme.colors.accent
    customTheme.colors.primary = currentCity && currentCity.primary_button_colour ? currentCity.primary_button_colour : theme.colors.primary
    customTheme.colors.secondary = currentCity && currentCity.secondary_button_colour ? currentCity.secondary_button_colour : theme.colors.secondary
    customTheme.fonts.body = currentCity && currentCity.font ? currentCity.font + ALTERNATIVE_FONTS : theme.fonts.body
    this.state = {
      theme: customTheme
    }
    props.reduxStore.subscribe(() => {
      const currState = props.reduxStore.getState()
      if (currState.user && currState.user.city) {
        const newTheme = { ...this.state.theme }
        if (currState.user.city.accent_colour !== this.state.theme.colors.accent) {
          newTheme.colors.accent = currState.user.city.accent_colour
          this.setState({
            theme: newTheme
          })
        }
        else if (currState.user.city.primary_button_colour !== this.state.theme.colors.primary) {
          newTheme.colors.primary = currState.user.city.primary_button_colour
          this.setState({
            theme: newTheme
          })
        }
        else if (currState.user.city.secondary_button_colour !== this.state.theme.colors.secondary) {
          newTheme.colors.secondary = currState.user.city.secondary_button_colour
          this.setState({
            theme: newTheme
          })
        }
        else if (currState.user.city.font !== this.state.theme.fonts.body.split(", ")[0]) {
          newTheme.fonts.body = currState.user.city.font + ALTERNATIVE_FONTS
          this.setState({
            theme: newTheme
          })
        }
      }
    })
  }

  componentDidMount() {
    Router.events.on('routeChangeComplete', () => {
      window.scroll(0, 0)
    })
    TagManager.initialize(tagManagerArgs)
  }

  render() {
    const { Component, pageProps, reduxStore } = this.props
    return (
      <Provider store={reduxStore}>
        <PersistGate
          loading={<Component {...pageProps} />}
          persistor={this.persistor}
        >
          <ThemeProvider theme={this.state.theme}>
            <Head>
              <meta key="viewport" name="viewport" content="initial-scale=1, minimum-scale=1, width=device-width" />
            </Head>
            <Component {...pageProps} />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    )
  }
}

export default withReduxStore(MyApp)
