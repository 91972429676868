export default {
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [
    12, 14, 16, 20, 24, 32, 48, 64
  ],
  colors: {
    accent: '#da5616',
    primary: '#009faf',
    secondary: '#99b0b2',
    blue: '#009faf',
    lightGray: '#aaa',
    gray: '#707070',
    darkGray: '#2d2d2d',
    neutral: '#faf4e5',
    white: '#fff',
    lightBlue: '#5893f5',
    darkBlue: '#0064c7',
    red: '#f00',
    green: '#29981f',
    auburn: '#9f2300',
    tangerine: '#e5af36',
    rustyOrange: '#da5616',
    ocean: '#4dd0e1',
    concrete: '#d7d7d7',
    offwhite: '#f6f6f6',
    defaultAccent: '#da5616',
    defaultPrimary: '#009faf',
    defaultSecondary: '#99b0b2',
  },
  space: [
    0, 4, 8, 16, 32, 64, 128, 256
  ],
  fonts: {
    body: 'Helvetica Neue, Helvetica, Open Sans, sans-serif',
    heading: 'inherit',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)'
  },
  variants: {
  },
  text: {
  },
  buttons: {
    cursor: 'pointer',
    primary: {
      color: 'white',
      bg: 'primary',
    },
    secondary: {
      color: 'white',
      bg: 'secondary',
    }
  }
}
