import React from 'react'
import axios from 'axios'
import { initializeStore, getCurrentCity } from '../store'

export const isServer = typeof window === 'undefined'
export const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__'

function getOrCreateStore(initialState) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(initialState)
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState)
  }
  return window[__NEXT_REDUX_STORE__]
}

export default App => {
  return class AppWithRedux extends React.Component {
    static async getInitialProps(appContext) {
      // set api endpoint on server
      if (process.env.endpoint) {
        axios.defaults.baseURL = process.env.endpoint
      }
      else {
        axios.defaults.baseURL = appContext.ctx.req ? 'https://' + appContext.ctx.req.headers.host :  ''
      }

      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const reduxStore = getOrCreateStore()

      // Provide the store to getInitialProps of pages
      appContext.ctx.reduxStore = reduxStore

      let appProps = {}
      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(appContext)
      }

      if(appContext.ctx.pathname === '/lookup') {
        return {
          ...appProps,
          initialReduxState: {
            ...reduxStore.getState()
          }
        }
      }

      try {
        const res = await getCurrentCity()
        return {
          ...appProps,
          initialReduxState: {
            ...reduxStore.getState(),
            city: res.data.city
          }
        }
      }
      catch(e) {
        console.log('error', e, appContext.ctx.pathname)
        if(e.response && e.response.status === 400 && appContext.ctx.pathname !== '/lookup'){
          appContext.ctx.res.writeHead(301, {
            Location: '/lookup',
            'Content-Type': 'text/html; charset=utf-8',
          })
          appContext.ctx.res.end()
        }
        return {
          ...appProps,
          initialReduxState: {
            ...reduxStore.getState()
          }
        }
      }
    }

    constructor(props) {
      super(props)
      this.reduxStore = getOrCreateStore(props.initialReduxState)
      if (!isServer) {
        // set api endpoint on client
        if (process.env.endpoint) {
          axios.defaults.baseURL = process.env.endpoint
        }
        else {
          axios.defaults.baseURL = 'https://' + window.location.host
        }
      }
    }

    render() {
      return <App {...this.props} reduxStore={this.reduxStore}/>
    }
  }
}
