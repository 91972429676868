import { translate } from '@app/locale/translate'
import { Text, Box, Flex, Heading } from 'rebass'
import dayjs from 'dayjs'

import { FaMapMarkerAlt } from 'react-icons/fa'

const GMAPS_API_KEY = process.env.googleMapAPI.key
const GMAPS_API_URL = process.env.googleMapAPI.url
import { fitBounds } from 'google-map-react/utils'
import LatLng from 'google-map-react/lib/utils/lib_geo/lat_lng.js'
import LatLngBounds from 'google-map-react/lib/utils/lib_geo/lat_lng_bounds.js'

import Theme from '@app/styles/theme'

export const URL_REGEX = /^(ftp|http|https):\/\/[^ "]+$/
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
export const POSTAL_REGEX = /^\d{5}$|^\d{5}-\d{4}$/
export const PHONE_REGEX = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/

export const URL_ERROR_MSG = 'Enter a valid URL (must start with http:// or https://)'

export const getStaticMap = (w, h, loc, zoom) => {
  const imageURL = GMAPS_API_URL + "/staticmap?center=" + loc.lat + ",%20" + loc.lng + "&zoom=" + zoom + "&size=" + w + "x" + h + "&key=" + GMAPS_API_KEY
  return <Box
    width="100%"
    height="100%"
    sx={{
      position: 'relative',
      background: "url(" + imageURL + ") center center",
      backgroundSize: 'cover'
    }}
  >
    <FaMapMarkerAlt
      fontSize={h > 128 ? 30 : 18}
      color={Theme.colors.accent}
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translateX(-50%) translateY(-100%)'
      }}
    />
  </Box>
}

export const getMapBounds = (array, geoService) => {
  const bounds = new LatLngBounds();
  array.forEach(one => {
    if (one.latitude && one.longitude) {
      bounds.extend(
        new LatLng(one.latitude, one.longitude)
      )
    }
  })
  return fitBounds(
    { nw: bounds.getNorthWest(), se: bounds.getSouthEast() },
    { width: geoService.getWidth(), height: geoService.getHeight() }
  )
}

export const getBubbleStyle = (direction = 'right', color = Theme.colors.primary) => {
  if (direction === 'left') {
    return {
      position: 'relative',
      padding: '12px',
      margin: '8px 0 8px 32px',
      border: '2px solid ' + color,
      borderRadius: '0 8px 8px 8px',
      ':before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        width: 0,
        borderStyle: 'solid',
        top: '-2px',
        bottom: 'auto',
        right: 'auto',
        left: '-32px',
        borderWidth: '0 32px 32px 0',
        borderColor: 'transparent ' + color
      },
      ':after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        width: 0,
        borderStyle: 'solid',
        top: '0px',
        bottom: 'auto',
        right: 'auto',
        left: '-27px',
        borderWidth: '0px 27px 27px 0',
        borderColor: 'transparent #fff'
      }
    }
  }
  // right
  return {
    position: 'relative',
    padding: '12px',
    margin: '8px 32px 8px 0',
    border: '2px solid ' + color,
    borderRadius: '8px 0 8px 8px',
    ':before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 0,
      borderStyle: 'solid',
      top: '-2px',
      bottom: 'auto',
      left: 'auto',
      right: '-32px',
      borderWidth: '0 0 32px 32px',
      borderColor: 'transparent ' + color
    },
    ':after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 0,
      borderStyle: 'solid',
      top: '0px',
      bottom: 'auto',
      left: 'auto',
      right: '-27px',
      borderWidth: '0px 0 27px 27px',
      borderColor: 'transparent #fff'
    }
  }
}

export const getColor = (s) => {
  // requested / accepted / confirmed
  if (s === 'requested') { // pending
    return 'lightBlue'
  }
  if (s === 'accepted') { // pending city approval
    return 'tangerine'
  }
  if (s === 'confirmed') { // confirmed
    return 'green'
  }
  if (s === 'cancelled') { // confirmed
    return 'gray'
  }
  return "tangerine"
}

export const getStatus = (s) => {
  let status = translate('pending')
  if (s === 'accepted') {
    status = translate('pending city approval')
  }
  if (s === 'confirmed') {
    status = translate('confirmed')
  }
  if (s === 'cancelled') {
    status = translate('cancelled')
  }
  return status
}

export const getEventBadge = (s) => {
  return (
    <Text
      fontSize="10px"
      px="2"
      py="2px"
      bg={getColor(s)}
      color="white"
      sx={{
        position: 'absolute',
        right: '16px',
        top: '0',
        textTransform: 'uppercase'
      }}
    >{getStatus(s)}</Text>
  )
}

export const selectTheme = (theme) => {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary: Theme.colors.primary,
      primary75: Theme.colors.neutral,
      primary50: Theme.colors.neutral,
      primary25: Theme.colors.neutral,
    }
  }
}

export const getPerformanceProfile = (p, onClick) => {
  return (
    <Box
      width='100%'
      pt='calc(100% + 72px)'
      bg={'white'}
      sx={{
        position: 'relative',
        border: '1px solid lightGray',
        boxShadow: '0 1.5px 3px 0px rgba(0, 0, 0, 0.16)'
      }}
    >
      <Box
        height='72px'
        p={[2, 3, 3]}
        sx={{
          position: 'absolute',
          top: 0
        }}
      >
        <Heading
          width='100%'
          fontSize={2}
          mb={2}
          sx={{
            textTransform: 'Capitalize'
          }}
        >
          {p.name}
        </Heading>
        <Text
          fontSize='12px'
          color='primary'
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
          onClick={onClick}
        >
          View Profile
        </Text>
      </Box>
      <Box
        bg='neutral'
        sx={{
          position: 'absolute',
          top: '72px',
          bottom: 0,
          left: 0,
          right: 0,
          borderTop: '1px solid lightGray',
          borderBottom: '1px solid lightGray'
        }}
      >
        {getProfileImage(p)}
      </Box>
    </Box>
  )
}

export const getEvent = (e, type, createdBy, url) => {
  return (
    <Box
      key={e.id}
      mb={2}
      height={'96px'}
      bg="white"
      as="a"
      href={url}
      sx={{
        display: 'block',
        cursor: url ? 'pointer' : 'auto',
        position: 'relative',
        paddingLeft: '96px',
        border: '1px solid lightGray',
        boxShadow: '0 1.5px 3px 0px rgba(0, 0, 0, 0.16)',
        ':hover': {
          color: 'inherit'
        }
      }}
    >
      <Box
        width='96px'
        height='100%'
        sx={{
          position: 'absolute',
          left: '0',
          top: '0',
          overflow: 'hidden'
        }}
      >
        {type === 'venue' ? getProfileImage(e.venue, 256, 256) : getProfileImage(e.artist, 256, 256)}
      </Box>
      <Flex
        height='100%'
        px={3}
        py={1}
        // justifyContent="space-between"
        alignItems="center"
      >
        <Box sx={{ flexBasis: '50%' }}>
          {e.artist && <Text fontSize={['14px', '15px', '16px']}
            style={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '2'
            }}
          >
            {e.artist.name}
          </Text>}
          {e.venue && <Text mt={1} fontSize={11}
            style={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '2'
            }}
          >
            @ {e.venue.name}
          </Text>}
          {createdBy && <Text mt={1} fontSize={11} color="darkGray" >
            {createdBy}
          </Text>}
        </Box>
        <Box ml={2} maxWidth='160px' sx={{ flexBasis: '50%' }}>
          <Text fontSize={['14px', '15px', '16px']} >
            {dayjs(e.start_time).format('LT')} - {dayjs(e.end_time).format('LT')}
          </Text>
        </Box>
        <Box />
      </Flex>
      {getEventBadge(e.status)}
    </Box>
  )
}

export const getSelectedOption = (array, value) => {
  let result = undefined
  if (array.length > 0) {

    // default
    let filtered = array.filter(o => o.value === value)
    if (filtered.length === 1) {
      return filtered[0]
    }
    // grouped
    filtered = array.reduce((ac, v) => {
      return ac.concat(v.options ? v.options : [])
    }, []).filter(o => o.value === value)
    if (filtered.length === 1) {
      return filtered[0]
    }
  }
  return result
}

export const getSearchFilterOptions = (options) => {
  return [
    { value: 'all', label: translate('All') },
    ...options
  ]
}

export const getNeighborhoodOptions = (districts) => {
  const result = []

  districts.sort((a, b) => {
    return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
  }).map(d => {
    const group = []
    d.neighbourhoods.sort((c, d) => {
      return c.name.localeCompare(d.name, 'en', { sensitivity: 'base' })
    }).map(n => {
      group.push({ value: d.id + ',' + n.id, label: n.name })
    })
    result.push({
      label: d.name,
      options: group
    })
  })

  return result
}

export const getNeighborhoodName = (districts, code) => {
  if (!code) return ''

  const idArr = code.split(',')
  const filterDs = districts.filter(d => d.id === idArr[0])
  if (filterDs.length === 1) {
    const filteredNs = filterDs[0].neighbourhoods.filter(n => n.id === idArr[1])
    if (filteredNs.length === 1) {
      return filteredNs[0].name
    }
  }
  return ''
}

export const getProfileList = (user, hasAdmin = false) => {
  const performers = user.artists.map(a => {
    return {
      ...a,
      type: 'artist'
    }
  }).sort((a, b) => { return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }) })

  const venues = user.venues.map(v => {
    return {
      ...v,
      type: 'venue'
    }
  }).sort((a, b) => { return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }) })

  let selfmatches = []
  if (user.selfmatches.length > 0) {
    user.selfmatches.map(s => {
      const vid = s.venue.id
      if (selfmatches.filter(r => r.id === vid).length === 0 && user.venues.filter(v => v.id === vid).length === 0) {
        selfmatches.push({
          ...s.venue,
          type: 'venue',
          is_self_match: true
        })
      }
    })
    selfmatches.sort((a, b) => { return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }) })
  }

  let admin = [];
  if(hasAdmin) {
    if( user.token.scope.includes('city') ){
      if(user.token.scope.includes('admin')){
        admin.push({
          id: 'admin',
          name: translate('Super Admin'),
          type: 'super'
        })
      }
      else{
        admin.push({
          id: 'admin',
          name: translate('City Admin'),
          type: 'admin'
        })
      }
    }
  }

  const profiles = [
    ...admin,
    ...performers,
    ...venues,
    ...selfmatches
  ]

  return profiles
}

export const getProfileImage = (p, w = 512, h = 512) => {
  let imagePath = w > 256 ? "/images/profile_live.png" : "/images/icon_live.png"
  if (p.virtual_option && p.virtual_option !== 'livestream_only') {
    if (p.latitude && p.longitude) {
      return (
        getStaticMap(w, h, {
          lat: p.latitude,
          lng: p.longitude
        }, 16)
      )
    }
    else {
      imagePath = w > 256 ? "/images/profile_artist.png" : "/images/icon_artist.png"
    }
  } else if ('image_url' in p) {
    if (p.image_url) {
      imagePath = p.image_url
    }
    else {
      imagePath = w > 256 ? "/images/profile_artist.png" : "/images/icon_artist.png"
    }
  }

  return (
    <Box
      width={'100%'}
      height={'100%'}
      sx={{
        background: 'url(' + imagePath + ') no-repeat center center',
        backgroundSize: 'cover',
      }}
    />
  )

}

export const checkOverlap = (obj, array) => {
  if (array.length === 0) return false
  const filteredArray = array.filter( e => e.status === 'confirmed' || e.status === 'accepted')
  for (let i = 0; i < filteredArray.length; i++) {
    const one = filteredArray[i]
    if (dayjs(obj.start_time).isBefore(one.start_time)) {
      if (dayjs(obj.end_time).isAfter(one.start_time)) {
        return true
      }
    }
    else if (dayjs(obj.start_time).isSame(one.start_time)) {
      return true
    }
    else if (dayjs(one.end_time).isAfter(obj.start_time)) {
      return true
    }
  }

  return false
}
