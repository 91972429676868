import { createStore, applyMiddleware } from "redux";
import { defaultLocale, getDefaultLocale } from "@app/locale/utils";
import { getProfileList } from "@app/components/utils";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";
import bota from "btoa";
import axios from "axios";
import cookie from "js-cookie";

const CLIENT_ID = process.env.clientID;
const CLIENT_SECRET = process.env.clientSecret;

const initialState = {
  user: null,
  // dictionary: dictionary,
  locale: typeof window === "undefined" ? defaultLocale : getDefaultLocale(),
};

export const actionTypes = {
  AUTHENTICATE: "AUTHENTICATE",
  DEAUTHENTICATE: "DEAUTHENTICATE",
  SETLOCALE: "SETLOCALE",
  UPDATEDICTIONARY: "UPDATEDICTIONARY",
  UPDATEUSER: "UPDATEUSER",
};

// REDUCERS
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATE:
      return {
        ...state,
        user: action.user,
      };
    case actionTypes.DEAUTHENTICATE:
      return {
        ...state,
        user: null,
      };
    case actionTypes.UPDATEUSER:
      return {
        ...state,
        user: action.user,
      };
    case actionTypes.SETLOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    case actionTypes.UPDATEDICTIONARY:
      return {
        ...state,
        dictionary: action.dictionary,
      };
    default:
      return state;
  }
};

// ACTIONS
// fetch endpoint url without user credential
export const fetchServer = (path, method, obj = {}) => {
  return axios({
    url: path,
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + bota(CLIENT_ID + ":" + CLIENT_SECRET),
    },
    data: obj,
  });
};

// fetch endpoint url with user credential
const fetchUser = (link, token, obj) => {
  return axios({
    url: link.href,
    method: link.method,
    headers: {
      "Content-Type": link["content-type"]
        ? link["content-type"]
        : "application/json",
      Authorization: token.token_type + " " + token.access_token,
    },
    data: obj,
  });
};

// fetch public url
export const fetchURL = (url, method = "GET", obj = null) => {
  return axios({
    url: url,
    method: method,
    baseURL: null,
    data: obj,
  });
};

export const authenticateAction = (user) => {
  return {
    type: actionTypes.AUTHENTICATE,
    user: user,
  };
};
export const deAuthenticateAction = () => {
  return {
    type: actionTypes.DEAUTHENTICATE,
  };
};

export const updateUserInfo = (user) => {
  return {
    type: actionTypes.UPDATEUSER,
    user: user,
  };
};

const saveToken = (token) => {
  cookie.set("token", token, {
    expires: token.expires_in / 86400,
    samesite: "strict",
  });
};

// save to js cookie
export const setCookie = (key, data) => {
  cookie.set(key, data);
};

// get from js cookie
export const getCookie = (key) => {
  return cookie.get(key);
};

const processUserData = (data, slug, successCallback, failedCallback, dp) => {
  // save locale
  dp(setLocale(data.user.locale));
  // save token
  saveToken(data.token);

  // pull full city info
  fetchUser(
    {
      href: "/api/cities/" + slug,
      method: "GET",
    },
    data.token
  ).then(
    (cityRes) => {
      // console.log('get city info result', cityRes)
      let userData = {
        ...data.user,
        token: {
          ...data.token,
          expires_at: Date.now() + data.token.expires_in * 1000,
        },
        city: cityRes.data.city,
      };
      const profiles = getProfileList(userData, true);
      userData.curr = profiles.length > 0 ? profiles[0].id : null;
      dp(authenticateAction(userData));
      if (successCallback) successCallback(userData);

      // pull full user account
      fetchUser(
        {
          href: "/api/users/self",
          method: "GET",
        },
        data.token
      ).then(
        (userRes) => {
          // console.log('get user info result', userRes)
          dp(
            updateUserInfo({
              ...userData,
              ...userRes.data.user,
            })
          );
        },
        (error) => {
          console.log(error.response);
        }
      );
    },
    (error) => {
      // console.log(error.response)
      if (failedCallback) failedCallback(error.response);
    }
  );
};

export const login = (
  email,
  password,
  slug,
  scope,
  successCallback,
  failedCallback
) => {
  return async (dispatch) => {
    fetchServer("/api/oauth/token", "POST", {
      grant_type: "password",
      username: email,
      password: password,
      scope: scope,
    }).then(
      (response) => {
        // console.log('login user data', response.data)
        processUserData(
          response.data,
          slug,
          successCallback,
          failedCallback,
          dispatch
        );
      },
      (error) => {
        // console.log(error)
        if (failedCallback) failedCallback(error.response);
      }
    );
  };
};

export const logout = () => {
  cookie.remove("token");
  return {
    type: actionTypes.DEAUTHENTICATE,
  };
};

export const createAccount = (data, slug, successCallback, failedCallback) => {
  return async (dispatch) => {
    fetchServer("/api/users/create", "POST", {
      grant_type: "password",
      has_accepted_terms: true,
      scope: "profile performer venue",
      locale: "en_US",
      ...data,
    }).then(
      (response) => {
        // console.log('create account api result', response)
        processUserData(
          response.data,
          slug,
          successCallback,
          failedCallback,
          dispatch
        );
      },
      (error) => {
        // console.log(error)
        if (failedCallback) failedCallback(error.response);
      }
    );
  };
};

export const editAccount = (user, data, successCallback, failedCallback) => {
  return async (dispatch) => {
    fetchUser(user._links.edit, user.token, data).then(
      (response) => {
        // console.log('edit account api result', response)
        let userData = {
          ...user,
          ...response.data.user,
        };
        if ("token" in response.data) {
          saveToken(response.data.token);
          userData = {
            ...userData,
            token: {
              ...response.data.token,
              expires_at: Date.now() + response.data.token.expires_in * 1000,
            },
          };
        }
        dispatch(updateUserInfo(userData));
        if (successCallback) successCallback(userData);
      },
      (error) => {
        // console.log(error.response)
        if (failedCallback) failedCallback(error.response);
      }
    );
  };
};

export const deleteAccount = (
  user,
  password,
  successCallback,
  failedCallback
) => {
  return async (dispatch) => {
    fetchUser(user._links.delete, user.token, {
      password: password,
    }).then(
      (response) => {
        // console.log('delete account api result', response)
        dispatch(deAuthenticateAction());
        if (successCallback) successCallback(response.data);
      },
      (error) => {
        // console.log(error.response)
        if (failedCallback) failedCallback(error.response);
      }
    );
  };
};

export const setLocale = (locale) => ({
  type: actionTypes.SETLOCALE,
  locale: locale,
});

export const updateDictionary = () => {
  return async (dispatch) => {
    fetchServer("/api/i18n", "GET").then(
      (response) => {
        return {
          type: actionTypes.UPDATEDICTIONARY,
          dictionary: response.data,
        };
      },
      (error) => {
        // console.log(error)
      }
    );
  };
};

export const getCurrentCity = () => {
  return axios({
    url: "/api/city/current",
  });
};

export const getCities = () => {
  return axios({
    url: "/api/cities",
  });
};

export const updateCityInfo = (
  link,
  user,
  data,
  successCallback,
  failedCallback
) => {
  return async (dispatch) => {
    fetchUser(link, user.token, data).then(
      (response) => {
        // console.log('get city info result', response)
        const cityData = { ...user.city, ...response.data.city };
        dispatch(updateUserInfo({ ...user, city: cityData }));
        if (successCallback) successCallback(response.data);
      },
      (error) => {
        // console.log(error.response)
        if (failedCallback) failedCallback(error.response);
      }
    );
  };
};

export const updateDistrictsInfo = (
  link,
  user,
  data,
  successCallback,
  failedCallback
) => {
  // console.log('update district', link, data)
  return async (dispatch) => {
    fetchUser(link, user.token, data).then(
      (response) => {
        let cityData;
        if (response.status === 204) {
          // delete success
          if (data.neighbourhood) {
            // filter out deleted neighbourhood
            cityData = {
              ...user.city,
              districts: [...user.city.districts].map((d) => {
                if (d.id === data.district_id) {
                  d.neighbourhoods = d.neighbourhoods.filter(
                    (n) => n.name !== data.neighbourhood
                  );
                }
                return d;
              }),
            };
          } else {
            // filter out deleted district
            const newDistricts = user.city.districts.filter(
              (item) => item.name !== data.district
            );
            cityData = { ...user.city, districts: newDistricts };
          }
        } else {
          cityData = { ...user.city, ...response.data.city };
        }
        // console.log('get city info result', response, cityData)
        dispatch(updateUserInfo({ ...user, city: cityData }));
        if (successCallback) successCallback(response.data);
      },
      (error) => {
        if (failedCallback) failedCallback(error.response);
        // console.log(error)
      }
    );
  };
};

export const updateVenuesInfo = (
  link,
  user,
  data,
  successCallback,
  failedCallback
) => {
  return async (dispatch) => {
    fetchUser(link, user.token, data).then(
      (response) => {
        let cityData;
        if (response.status === 204) {
          // delete success
          delete user.city.venue_types[data.venue_type];
          cityData = { ...user.city };
        } else {
          cityData = { ...user.city, ...response.data };
        }
        dispatch(updateUserInfo({ ...user, city: cityData }));
        if (successCallback) successCallback(response.data);
      },
      (error) => {
        if (failedCallback) failedCallback(error.response);
      }
    );
  };
};

export const updateGenresInfo = (
  link,
  user,
  data,
  successCallback,
  failedCallback
) => {
  return async (dispatch) => {
    fetchUser(link, user.token, data).then(
      (response) => {
        let cityData;
        if (response.status === 204) {
          // delete success
          delete user.city.genres[data.genre];
          cityData = { ...user.city };
        } else {
          cityData = { ...user.city, ...response.data };
        }
        dispatch(updateUserInfo({ ...user, city: cityData }));
        if (successCallback) successCallback(response.data);
      },
      (error) => {
        if (failedCallback) failedCallback(error.response);
      }
    );
  };
};

export const uploadArtistAvatar = (
  user,
  data,
  successCallback,
  failedCallback
) => {
  return async (dispatch) => {
    fetchUser(user._links["add-artist-avatar"], user.token, data).then(
      (response) => {
        if (successCallback) successCallback(response.data);
      },
      (error) => {
        if (failedCallback) failedCallback(error.response);
      }
    );
  };
};

export const fetchUserData = (
  user,
  link,
  data,
  successCallback,
  failedCallback,
  updateStore = false
) => {
  return async (dispatch) => {
    fetchUser(link, user.token, data).then(
      (response) => {
        // console.log('get user data', response)
        if (typeof updateStore === "function") {
          const updateData = updateStore(response.data);
          if (Object.keys(updateData).length !== 0) {
            const newUserData = {
              ...user,
              ...updateData,
            };
            dispatch(updateUserInfo(newUserData));
          }
        } else if (updateStore) {
          // refetch user account
          fetchUser(user._links.self, user.token, {}).then((fetchRes) => {
            dispatch(
              updateUserInfo({
                ...user,
                ...fetchRes.data.user,
              })
            );
          });
        }
        if (successCallback) successCallback(response.data);
      },
      (error) => {
        // console.log(error.response)
        if (failedCallback) failedCallback(error.response);
      }
    );
  };
};

// Initialize Store
const persistConfig = {
  key: "primary",
  storage,
  whitelist: ["locale", "user"],
};

const persistedReducer = persistReducer(persistConfig, reducer);
export const initializeStore = (preloadedState = initialState) => {
  return createStore(
    persistedReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  );
};
